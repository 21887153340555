<template>
  <el-main>
    <el-form
      class="projectFrom"
      :model="formData"
      ref="queryParams"
      label-width="100px"
      :rules="rules"
    >
      <el-form-item label="业务流程" prop="workflowId">
        <el-select
          class="childWidth"
          v-model="formData.workflowId"
          filterable
          clearable
          @visible-change="onWorkflowVisibleChange"
          @change="onWorkflowChange"
          placeholder="请选择业务流程"
          no-data-text="加载中···"
        >
          <el-option
            v-for="(item, index) in workflowList"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="业务进度表" prop="projectWorkflowId">
        <el-select
          class="childWidth"
          v-model="formData.projectWorkflowId"
          filterable
          placeholder="请选择业务进度表"
          @visible-change="findMyProjectWorkflowList($event)"
          @change="onMyProjectWorkflowListChange"
          clearable
          :no-data-text="
            myProjectWorkflowList == null ? '加载中···' : '暂无数据'
          "
        >
          <el-option
            v-for="item in myProjectWorkflowList"
            :key="item.id"
            :label="item.projectName + ' - ' + item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="任务负责人" prop="ownerId">
        <el-select
          class="childWidth"
          v-model="formData.ownerId"
          filterable
          @visible-change="findMyProjectWorkflowMembers($event)"
          @change="onMyProjectWorkflowMembersChange"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in myProjectWorkflowMembers"
            :key="item.uid"
            :label="item.real_name"
            :value="item.uid"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="任务状态" prop="displayStatusList">
        <el-select
          v-model="formData.displayStatusList"
          class="childWidth"
          clearable
          multiple
          @change="onDisplayStatusListChange"
        >
          <el-option
            v-for="item in taskStatusListOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span :style="item.color">{{ item.label }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="业务状态" prop="pjWorkflowCondition">
        <el-select
          v-model="formData.pjWorkflowCondition"
          class="childWidth"
          clearable
          @change="onPjWorkflowConditionChange"
        >
          <el-option
            v-for="item in projectStatusListOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <!-- <div class="sub-title">激活即列出输入建议</div> -->
      </el-form-item>

      <!-- <div class="el-form-item2">
          <el-row type="flex" justify="center" class="row-bg">
            <el-button @click="resetConditions">重置</el-button>
            <el-button
              type="primary"
              @click="queryMissionList()"
              v-permission="['admin:task:query_list']"
              >查询</el-button
            >
          </el-row>
        </div> -->
    </el-form>
    <el-divider content-position="center" v-show="workflowInfoTaskList">
      项目任务
    </el-divider>
    <div class="flexJA tips">
      <span> 小提示： </span>
      <span> 流程节点上的数字为 </span>
      <el-tag type="danger" effect="plain">进行中</el-tag>
      、
      <el-tag type="" effect="plain">待启动</el-tag>
      、
      <el-tag type="success" effect="plain">已完成</el-tag>
      <span> 项目的数量。</span>
    </div>

    <div class="workflowInfo" v-loading="loading">
      <div
        class="tableList"
        v-for="(item, index) in workflowInfoTaskList"
        :key="index"
      >
        <div class="workflowInfoTaskList">
          <div class="flexJfAc">
            <span>{{ index + 1 }}.</span>
            <h4>{{ item.name }}</h4>
            <span class="version">版本{{ item.version }}</span>
          </div>

          <div class="flexJfAc scrollbarX nodeList" ref="workflowInfo">
            <div
              class="nodeOne flexJA"
              v-for="(item2, index2) in item.nodeList"
              :key="index2"
            >
              <el-button
                :ref="item.id + item2.id"
                plain
                @click="
                  getTaskInfoList(item2.id, item.id, item.version),
                    temporaryFullScreen == true ? openFullScreen() : ''
                "
              >
                <span
                  :class="{ nodeSelect: nodeSelect == `${item.id + item2.id}` }"
                  >{{ item2.name }}</span
                >(
                <span
                  v-show="
                    formData.displayStatusList.indexOf(5) != '-1' ||
                    formData.displayStatusList.length == 0
                  "
                  class="runningProjectNum"
                >
                  <el-tag class="tagW" type="danger" effect="plain">{{
                    item2.runningProjectNum
                  }}</el-tag>
                </span>
                <span
                  v-show="
                    formData.displayStatusList.indexOf(4) != '-1' ||
                    formData.displayStatusList.length == 0
                  "
                  class="notStartedProjectNum"
                >
                  <el-tag class="tagW" type="" effect="plain">{{
                    item2.notStartedProjectNum
                  }}</el-tag>
                </span>

                <span
                  style=""
                  v-show="
                    formData.displayStatusList.indexOf(6) != '-1' ||
                    formData.displayStatusList.length == 0
                  "
                  class="finishedTaskNum"
                >
                  <el-tag class="tagW" type="success" effect="plain">{{
                    item2.finishedProjectNum
                  }}</el-tag>
                </span>
                )
              </el-button>
              <div class="arrowf">
                <div class="arrow"></div>
              </div>
            </div>
          </div>
          <div
            class=""
            v-if="
              taskInfo != null &&
              taskInfo.workflowId == item.id &&
              taskInfo.workflowVersion == item.version
            "
          >
            <missioTable
              :info="taskInfo"
              :workflowId="item.id"
              :workflowVersion="item.version"
              v-on:listenMissioTableB003="listenMissioTableB003"
              :fromJump="fromJump"
            ></missioTable>
          </div>
        </div>
      </div>
    </div>
    <el-divider content-position="center">非项目任务</el-divider>
    <el-collapse v-model="collapseActiveNames">
      <el-collapse-item title="非项目任务" name="1">
        <div class="" v-show="formData != null">
          <missioTableOther
            :info="formData"
            v-on:listenMissioTableB003="listenMissioTableB003"
          ></missioTableOther>
        </div>
      </el-collapse-item>
    </el-collapse>
  </el-main>
</template>

<script>
import {
  findMyProjectWorkflowList,
  findMyProjectWorkflowMembers,
  getWorkflowInfoTaskList,
  getWorkflowQueryList,
} from "@/api/myMission";
import { selectTypeProject } from "@/api/processLibrary.js";

import { formatDate } from "@/common/date";
import { getOptionUserInfo, getUseDeptList } from "../../api/calendar.js";
import missioTable from "@/components/myMission/missioTable";
import missioTableOther from "@/components/myMission/missioTableOther";
import { debounce } from "throttle-debounce";
import eventBus from "@/common/eventBus";

export default {
  name: "myMission2",
  data() {
    return {
      useDeptList: [],
      projectLibraryOptions: [],
      missioTableParmes: {},
      taskStatusListOptions: [
        {
          value: 5,
          label: "进行中",
          color: "color:#F56C6C",
        },
        {
          value: 4,
          label: "待启动",
          color: "color:#409EFF",
        },
        {
          value: 6,
          label: "已完成",
          color: "color:#67C23A",
        },
        // {
        //   value: -1,
        //   label: "全部",
        // },
      ],
      projectStatusListOptions: [
        {
          value: 1,
          label: "不包含已暂停/已终止项目",
        },
        {
          value: 2,
          label: "包含已暂停/已终止项目",
        },
        {
          value: 3,
          label: "仅限已暂停/已终止项目",
        },
      ],
      tableDataList: [],
      //1
      formData: {
        workflowId: "",
        displayStatusList: [5],
        ownerId: null,
        pjWorkflowCondition: 1,
        projectWorkflowId: "",
      },

      queryParams: {
        tableData: [],
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      itemIndex: null,

      rules: {
        displayStatusList: [
          { required: false, message: "请选择状态", trigger: "change" },
        ],
        ownerId: [
          { required: true, message: "请选择任务负责人", trigger: "change" },
        ],
      },
      workflowInfoTaskList: [],
      taskInfoList: [],
      hsUserId: false,
      tableLoading: false,
      tableLoading2: false,
      taskInfo: {
        nodeId: null,
      },
      chooseUserId: null,
      loading: true,
      taskQueryList1: [],
      myProjectWorkflowList: null,
      myProjectWorkflowMembers: [],
      findMyProjectWorkflowMembersDebounce: debounce(500, false, () => {
        this.findMyProjectWorkflowMembers(true, () => {
          this.getWorkflowInfoTaskList();
        });
      }),
      workflowList: [],
      collapseActiveNames: [],
      nodeSelect: null,
      temporaryFullScreen: true,
      fromJump:null,
    };
  },
  props: {
    index: Number,
    tabIndex: String,
    nodeInfo: Object,
    jumpType: String,
  },
  components: {
    missioTable,
    missioTableOther,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  activated() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.formData.workflowId = this.$route.query.workflowId;
    eventBus.$on("onSelectOverviewWorkflow", (workflowId) => {
      if (workflowId) {
        this.formData.workflowId = workflowId;
        this.getWorkflowInfoTaskList();
      }
    });
    let promise1 = new Promise((resolve) =>
      this.getTaskStatusPreference(() => {
        resolve();
      })
    );
    let promise2 = new Promise((resolve) =>
      this.getProjectWorkflowConditionPreference(() => {
        resolve();
      })
    );
    Promise.all([promise1, promise2]).then(() => {
      console.log("getWorkflowInfoTaskList");
      this.getWorkflowInfoTaskList();
    });
    this.$forceUpdate();
  },
  created() {},
  mounted() {
    this.formData.ownerId = window.localStorage.getItem("userId");
    this.formData.workflowId = this.$route.query.workflowId;
    this.onWorkflowVisibleChange(true);
    this.findMyProjectWorkflowMembers(true);

    eventBus.$on("onSelectOverviewWorkflow", (workflowId) => {
      if (workflowId) {
        this.formData.workflowId = workflowId;
        this.getWorkflowInfoTaskList();
      }
    });
    let promise1 = new Promise((resolve) =>
      this.getTaskStatusPreference(() => {
        resolve();
      })
    );
    let promise2 = new Promise((resolve) =>
      this.getProjectWorkflowConditionPreference(() => {
        resolve();
      })
    );
    Promise.all([promise1, promise2]).then(() => {
      console.log("getWorkflowInfoTaskList");
      this.getWorkflowInfoTaskList();
    });
  },
  beforeDestroy() {
    eventBus.$off("onSelectOverviewWorkflow");
  },
  watch: {
    tabIndex(newVal, old) {
      console.log("tabIndex" + newVal);
      if (!this.formData.ownerId || this.formData.ownerId.length === 0) {
        return;
      }
      // if (newVal == "1") {
      //   this.getWorkflowInfoTaskList();
      // }
    },
    nodeInfo: {
      handler(newVal, old) {
        let that = this;
        console.log(newVal);
        if (newVal) {
          that.formData.workflowId = newVal.workflowId;
          that.formData.displayStatusList = [newVal.status];
          if (newVal.workflowId) {
            let promise = new Promise((resolve) =>
              this.getWorkflowInfoTaskList(() => {
                resolve();
              })
            );
            Promise.all([promise]).then(() => {
              if (that.temporaryFullScreen) {
                that.openFullScreen();
              }
              that.getTaskInfoList(
                newVal.nodeId,
                newVal.workflowId,
                newVal.workflowVersion
              );
              let cid = newVal.workflowId + newVal.nodeId;
              that.nodeSelect = cid;
              // let uploadBtnRef = that.$refs[cid];
              // uploadBtnRef.$el.click();
            });
          }

          // that.workflowInfoTaskList.forEach(function (item, index) {
          //   if (item.id == newVal.workflowId && item.version == newVal.workflowVersion) {
          //     console.log(item.id);
          //     item.nodeList.forEach(function (item2, index2) {
          //       if (item2.id == newVal.nodeId) {
          //         let refName = item.id + item2.id;
          //         console.log(refName);
          //         that.$refs.nodeInfo[index2].$el.click();
          //       }
          //     });
          //   }
          // });
        }
      },
      immediate: true,
      deep: true,
    },
    formData: {
      handler(newVal, old) {
        if (newVal) {
          // this.getWorkflowInfoTaskList();
        }
      },
      immediate: true,
      deep: true,
    },
    jumpType: {
      handler(newVal, old) {
        if (newVal) {
           this.fromJump = newVal;
        }
      },
      immediate: true,
      deep: true,
    }, 
  },
  methods: {
    //条件查找流程信息下的任务数量列表
    getWorkflowInfoTaskList(callback) {
      this.loading = true;
      this.workflowInfoTaskList = null;

      console.log("ownerId", this.formData.ownerId);

      let formData = {
        ownerId: this.formData.ownerId,
        displayStatusList: this.formData.displayStatusList,
        pjWorkflowCondition: this.formData.pjWorkflowCondition,
        projectWorkflowId: this.formData.projectWorkflowId,
        workflowId: this.formData.workflowId,
      };
      getWorkflowInfoTaskList(formData).then((response) => {
        if (response.success) {
          this.workflowInfoTaskList = response.data;
          this.loading = false;
          callback && callback();
        }
      });
    },
    openFullScreen() {
      const loading = this.$loading({
        lock: false,
        text: "加载中···",
        spinner: "el-icon-loading",
        background: "hsla(0,0%,100%,.7)",
      });
      setTimeout(() => {
        loading.close();
        this.temporaryFullScreen = false;
      }, 2000);
    },
    getTaskInfoList(nodeId, workflowId, workflowVersion) {
      if (!this.formData.ownerId || this.formData.ownerId.length === 0) {
        return false;
      }
      console.log(nodeId);
      console.log(this.taskInfo.nodeId);
      if (nodeId != this.taskInfo.nodeId) {
        console.log("点击打开任务");

        this.tableLoading = true;
        let formData = {
          pjWorkflowCondition: this.formData.pjWorkflowCondition,
          displayStatusList: this.formData.displayStatusList,
          ownerId: this.formData.ownerId,
          nodeId: nodeId,
          workflowId: workflowId,
          workflowVersion: String(workflowVersion),
          projectWorkflowId: this.formData.projectWorkflowId,
        };
        this.nodeSelect = workflowId + nodeId;
        this.taskInfo = JSON.parse(JSON.stringify(formData));
      } else {
        console.log("关闭任务");

        let formData = {
          pjWorkflowCondition: null,
          displayStatusList: null,
          ownerId: null,
          nodeId: null,
          workflowId: null,
          workflowVersion: null,
          projectWorkflowId: null,
        };
        this.nodeSelect = workflowId + nodeId;
        this.taskInfo = formData;
        setTimeout(() => {
          this.temporaryFullScreen = true;
        }, 500);
      }
    },

    getUseDeptList(cb) {
      var that = this;
      console.log("that.hsUserId=" + that.hsUserId);

      // that.formData.ownerId = [];
      if (cb) {
        getUseDeptList({})
          .then((response) => {
            if (response.success) {
              let useDeptList = response.data;
              useDeptList.forEach(function (item, index) {
                item.uid = item.deptId;
                item.realName = item.deptName;
              });
              that.useDeptList = useDeptList;

              if (!that.hsUserId) {
                let userId = null;
                if (this.$route.query.workProgressUserId) {
                  userId = this.$route.query.workProgressUserId;
                } else {
                  userId = window.localStorage.getItem("userId");
                }
                // let userId = this.chooseUserId;
                console.log("start");
                // let useDeptList = JSON.parse(JSON.stringify(useDeptList));
                useDeptList.forEach(function (item, index) {
                  if (item.userList && item.userList.length > 0) {
                    let oneInfo = [];
                    item.userList.forEach(function (item2, index2) {
                      if (userId == item2.uid) {
                        console.log("123");
                        that.hsUserId = true;
                        oneInfo[0] = item.deptId;
                        oneInfo[1] = item2.uid;
                        that.formData.ownerId = oneInfo;
                        that.$forceUpdate();
                      }
                    });
                  }
                });
              }
              this.$forceUpdate();
            }
          })
          .catch((_) => {
            console.log(".catch((_) => {})");
          });
      } else {
      }
    },
    //
    selectTypeProject(cb) {
      let param = {};

      selectTypeProject(param).then((response) => {
        if (response.success) {
          this.projectLibraryOptions = response.data;
        }
      });
    },
    listenMissioTableB003(params) {
      console.log(params);
      const { type, isClose, info, projectId, noProjectTaskId } = params;
      if (type == "sure") {
        this.$emit("listenMissioTableB003", { ...params });
      } else if (type == "B006") {
        this.$emit("listenMissioTableB003", { ...params });
      } else if (type == "B006-2") {
        this.$emit("listenMissioTableB003", { ...params });
      }
    },
    resetConditions() {
      this.formData = {};
    },
    //查询
    queryMissionList() {
      if (!this.formData.ownerId || this.formData.ownerId.length === 0) {
        return;
      }

      var that = this;
      that.missioTableParmes = JSON.parse(JSON.stringify(that.formData));

      console.log(that.missioTableParmes);

      this.getWorkflowInfoTaskList();
      this.getTaskQueryList1(this.pageInfo.pageNum, this.pageInfo.pageSize);
    },

    getWorkflowQueryList(info) {
      var info = info;
      getWorkflowQueryList(info).then((res) => {
        if (res.success) {
          console.log(res.data);
          info.tableData = res.data;
          let tableOne = { ...info };
        }
      });
    },
    //查找我参与的项目
    findMyProjectWorkflowList(show) {
      if (!show) return;
      findMyProjectWorkflowList({
        originalWorkflowId: this.formData.workflowId,
      }).then((res) => {
        if (res.success) {
          if (res.data.length > 0) {
            this.myProjectWorkflowList = res.data;
          } else {
            this.myProjectWorkflowList = [];
          }
        }
      });
    },

    //查找我参与项目的成员，如果我是PM则可以看到所有成员，否则只能看到自己
    findMyProjectWorkflowMembers(show, callback) {
      if (!show) return;
      let param = {
        id: this.formData.projectWorkflowId,
      };
      findMyProjectWorkflowMembers(param).then((res) => {
        if (res.success) {
          this.myProjectWorkflowMembers = res.data;

          let currMember = this.myProjectWorkflowMembers.find(
            (x) => x.uid === this.formData.ownerId
          );

          console.log("currMember", JSON.stringify(currMember));

          if (!currMember) {
            //取第一个member
            this.formData.ownerId = null;
            if (this.myProjectWorkflowMembers.length > 0) {
              this.formData.ownerId = this.myProjectWorkflowMembers[0].uid;
              callback && callback();
            }
          } else {
            callback && callback();
          }
        }
      });
    },

    //业务进度表筛选项变化
    onMyProjectWorkflowListChange() {
      this.findMyProjectWorkflowMembersDebounce();
    },

    //责任人筛选项变化
    onMyProjectWorkflowMembersChange() {
      this.getWorkflowInfoTaskList();
    },

    //任务状态筛选项变化
    onDisplayStatusListChange() {
      this.getWorkflowInfoTaskList();
    },

    //业务状态筛选项变化
    onPjWorkflowConditionChange() {
      this.getWorkflowInfoTaskList();
    },

    getTaskStatusPreference(callback) {
      let optionName = "task:my_mission:preference:task_status";
      let item = window.localStorage.getItem(optionName);
      if (item) {
        this.formData.displayStatusList = JSON.parse(item) || [5];
      }

      let param = {
        name: optionName,
      };
      getOptionUserInfo(param).then((res) => {
        if (res.success) {
          this.formData.displayStatusList = JSON.parse(res.data) || [5];

          callback && callback();
        }
      });
    },
    getProjectWorkflowConditionPreference(callback) {
      let optionName = "task:my_mission:preference:project_workflow_condition";
      let item = window.localStorage.getItem(optionName);
      if (item) {
        this.formData.pjWorkflowCondition = item * 1 || 1;
      }

      let param = {
        name: optionName,
      };
      getOptionUserInfo(param).then((res) => {
        if (res.success) {
          this.formData.pjWorkflowCondition = res.data * 1 || 1;

          callback && callback();
        }
      });
    },
    onWorkflowVisibleChange(show) {
      if (!show) return;
      let param = {};
      selectTypeProject(param).then((res) => {
        if (res.success) {
          this.workflowList = res.data;
        }
      });
    },
    onWorkflowChange() {
      this.formData.projectWorkflowId = "";
      this.myProjectWorkflowList = null;
      this.findMyProjectWorkflowMembersDebounce();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-main {
  overflow-x: hidden;
}

.el-form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .el-form-item {
    width: 50% !important;

    .el-select {
      width: 100%;
    }

    .el-cascader {
      width: 100%;
    }
  }

  .el-form-item2 {
    width: 100% !important;

    .el-select {
      width: 100%;
    }

    .el-cascader {
      width: 100%;
    }
  }
}

.workflowInfo {
  overflow-y: hidden;
  min-height: 2rem;
}

.workflowInfoTaskList {
  margin: 10px 0;

  .version {
    color: #606266;
    font-size: 12px;
    margin-left: 5px;
    // align-self: flex-end;
  }

  .nodeList {
    overflow-x: auto;
    overflow-y: hidden;
    height: 4rem;
    width: 100%;

    .nodeOne {
      .el-button {
        margin: 5px;
        border-style: solid;
        border-bottom: 1px solid #dcdfe6;
        border-top: 1px solid #dcdfe6;
        border-left: 1px solid #dcdfe6;
        border-right: thick dotted #fff;
      }

      .nodeListIcon {
      }
    }

    :last-child {
      .nodeListIcon {
        display: none;
      }
    }
  }
}

.taskList {
  margin-top: 10px;

  .projectTitle {
    margin: 15px 0;
  }
}

.finishedTaskNum {
  // color: #5dc935 !important;
}

.runningProjectNum {
  // color: red !important;
}

.notStartedProjectNum {
  // color: #409eff !important;
}

.arrowf {
  transform: translateX(-23.5px);
}

.arrow {
  // width: 7px;
  // height: 7px;
  border-top: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6;
  transform: rotate(45deg);
  padding: 15.5px;
}

.arrow:after {
}

.sub-title {
  font-size: 14px;
  color: #909399;
}

.tagW {
  padding: 0 2px !important;
  height: 1.2rem !important;
  line-height: 1.1rem !important;
  font-size: 14px;
  margin: 0 1px;
}

.tips {
  font-size: 12px;
  color: #606266;

  .el-tag {
    padding: 0px 3px;
    height: 1.2rem !important;
    line-height: 1.1rem !important;
  }
}

.nodeSelect {
  color: #409eff;
}
</style>
