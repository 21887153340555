<template>
  <div>
    <el-main>
      <el-form
        class="projectFrom"
        :model="formData"
        ref="queryParams"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item label="项目名" prop="projectWorkflowId">
          <el-select
            v-model="formData.projectWorkflowId"
            class="childWidth"
            filterable
            placeholder="请选择项目"
            @visible-change="searchPorjectList($event)"
            @change="choosePorject($event)"
            clearable
          >
            <el-option
              v-show="item.id"
              v-for="item in porjectListOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="流程" prop="workflowId">
          <el-select
            v-model="formData.workflowId"
            filterable
            :reserve-keyword="false"
            placeholder="请输入关键词从节点中搜索"
            @visible-change="selectTypeProject($event)"
            @change="chooseWorkflowId($event)"
            clearable
            class="childWidth"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in projectLibraryOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left">{{ item.name }}</span>
              <span style="float: left; color: #8492a6; font-size: 13px">{{
                item.projectWorkflowName
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <el-table border row-key="id" style="width: 100%" :data="tableData">
        <!-- <el-table-column type="index" label="#" width="40"></el-table-column> -->

        <el-table-column
          label="业务流程"
          prop="projectWorkflowName"
        ></el-table-column>
        <el-table-column label="流程节点" prop="nodeName"></el-table-column>
        <el-table-column prop="taskList" align="center" label="任务">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="light"
              content="双击可编辑任务"
              placement="top"
              v-for="(item, index) in scope.row.taskList"
              :key="index"
            >
              <el-link
                :underline="false"
                style="cursor: pointer"
                v-permission="['admin:task:edit']"
                @dblclick.native="addTaskEdit('edit', item)"
              >
                <span>{{ index + 1 }}: {{ item.name }}<br /></span>
              </el-link>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作时间" prop="createDate" width="155">
          <template slot-scope="scope">
            <span>{{ scope.row.createDate | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="320">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              v-permission="['admin:task:add']"
              @click="addTaskEdit('standard', scope.row)"
              >添加标准任务</el-button
            >
            <el-button
              type="primary"
              size="mini"
              v-permission="['admin:task:add']"
              @click="addTaskEdit('other', scope.row)"
              >添加其他任务</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageInfo.pageNum"
          :page-sizes="[10, 20]"
          :page-size="this.pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-main>
    <!-- 添加任务 -->
    <addTaskEdit
      v-if="addTaskEditDalogVisible"
      :addTaskEditDalogVisible="addTaskEditDalogVisible"
      :info="nodeInfo"
      :type="addTaskEditType"
      v-on:listenAddTaskEditClose="listenAddTaskEditClose"
    ></addTaskEdit>

    <!--  -->
  </div>
</template>

<script>
// 此组件貌似没有任何地方引用了
import { selectListMeetingProject } from "@/api/minutesOfMeeting";
import { getWorkflowQueryList, getTaskQueryList } from "@/api/myMission";
import { selectTypeProject } from "@/api/processLibrary.js";

import { formatDate } from "@/common/date";
import addTaskEdit from "@/components/myMission/addTask/addTask";

export default {
  name: "addTask",
  data() {
    return {
      tableData: [],
      spanArr: [],
      formData: {},
      porjectListOptions: [],

      firstGet: true,
      total: 0,
      pageCount: 1,
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
      },
      addTaskEditDalogVisible: false, //添加任务编辑
      addTaskEditType: null,
      fillFeedbackDalogVisible: false, //B001

      nodeName: null,
      fileTitle: null,
      fileTitles: null,
      detailsId: null,
      nodeInfo: null, //任务信息
      taskOneInfo: null, //任务信息
      projectLibraryOptions: [],
      rules: {
        projectWorkflowId: [
          { required: false, message: "请选择项目", trigger: "change" },
        ],
        workflowId: [
          { required: false, message: "请选择流程", trigger: "change" },
        ],
      },
    };
  },
  props: {
    // type: String,
    // id: String,
    // info: {
    //   type: Object,
    //   default: () => {},
    // },
    // itemIndex: String,
  },
  components: {
    addTaskEdit,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  created() {
    this.searchPorjectList(true);

    // this.getTaskQueryList();
  },
  mounted() {},
  methods: {
    selectTypeProject(cb) {
      if (cb) {
        let param = {};
        param.projectId = this.formData.projectWorkflowId;
        selectTypeProject(param).then((response) => {
          if (response.success) {
            this.projectLibraryOptions = response.data;
          }
        });
      }
    },
    chooseWorkflowId(id) {
      this.getWorkflowQueryList();
    },
    taskOne(item) {
      console.log(item);
    },
    //1
    addTaskEdit(type, row) {
      this.addTaskEditType = type;
      this.nodeInfo = row;
      this.addTaskEditDalogVisible = true;
      if (type == "standard") {
      } else if (type == "other") {
      } else if (type == "edit") {
        this.taskOneInfo = row;
      }
    },

    //监听
    listenAddTaskEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.addTaskEditDalogVisible = !isClose;
      if (type == "sure") {
        this.getWorkflowQueryList();
      }
    },

    //0
    getWorkflowQueryList() {
      var one = {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        projectId: this.formData.projectWorkflowId,
        workflowId: this.formData.workflowId,
      };
      getWorkflowQueryList(one).then((res) => {
        if (res.success) {
          this.tableData = res.data;
          if (res.total != null) {
            this.total = res.total;
            this.pageCount = res.pageCount;
            this.pageInfo.pageSize = res.pageSize;
            this.pageInfo.pageNum = res.pageNum;
          } else {
            this.total = 0;
          }
        }
      });
    },
    resetConditions() {
      this.formData.projectWorkflowIdList = null;
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
    },
    //1
    choosePorject(id) {
      var that = this;
      console.log(id);
      this.getWorkflowQueryList();
      this.formData.workflowId = null;
    },
    //
    searchPorjectList(cb) {
      console.log(cb);
      if (cb) {
        selectListMeetingProject({}).then((response) => {
          if (response.success) {
            this.porjectListOptions = response.data;
            if (this.firstGet) {
              this.formData.projectWorkflowId = response.data[0].id;
              this.firstGet = false;
              //   this.queryMissionList();
              this.getWorkflowQueryList();
            }
          }
        });
      }
    },

    queryMissionList() {
      console.log("2");
      var that = this;
      var temporaryList = [];
      if (that.formData.projectWorkflowIdList.length > 0) {
        that.formData.projectWorkflowIdList.forEach(function (item, index) {
          that.porjectListOptions.forEach(function (item2, index2) {
            if (item == item2.id) {
              // let info = {};
              // info.projectId = item2.id;
              // info.projectName = item2.projectName;
              // info.projectWorkflowId = item2.projectWorkflowId;
              // console.log(info)
              // that.formData.projectWorkflowIdList[index] = info;
              temporaryList[index] = item2;
              console.log(temporaryList);
              that.tableDataList = { ...temporaryList };
              // that.tableDataList = temporaryList
              that.$forceUpdate();
            }
          });
        });
      } else {
        this.$message({ type: "warning", message: "请选择项目!" });
      }
    },

    handleExport(item) {
      //下载文件
      var elemIF = document.createElement("iframe");
      elemIF.src = item.url;
      elemIF.style.display = "none";
      document.body.appendChild(elemIF);
    },
    //this.tableData 指接口取到的数据
    // 2、 因为要合并的行数是不固定的，此函数是实现合并随意行数的功能
    getSpanArr(data) {
      this.spanArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          if (data[i].id === data[i - 1].id) {
            // 如果moduleName相等就累加，并且push 0  这里是根据一样的moduleName匹配
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 不相等push 1
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    //
    expandChange(row, expanded) {
      console.log(row);
      console.log(expanded);
    },
    getTaskQueryList() {
      console.log("start2222");
      var one = {
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        projectWorkflowId: this.formData.projectWorkflowId,
      };
      getTaskQueryList(one).then((res) => {
        if (res.success) {
          //   console.log(res.data);
          this.tableData = res.data;
          // this.getSpanArr(this.tableData);
          if (res.total != null) {
            this.total = res.total;
            this.pageCount = res.pageCount;
            this.pageInfo.pageSize = res.pageSize;
            this.pageInfo.pageNum = res.pageNum;
          } else {
            this.total = 0;
          }
        }
      });
    },
    handleSizeChange(val) {
      console.log(val);
      console.log("22222");
      this.pageInfo.pageSize = val;
      this.getWorkflowQueryList();
    },
    handleCurrentChange(val) {
      console.log(val);
      this.pageInfo.pageNum = val;
      if (val > this.pageCount) {
        val = this.pageCount;
      }

      this.getWorkflowQueryList();
    },
  },
  watch: {
    // id(val, old) {
    //   console.log(val);
    //   if (val != old) {
    //     this.getTaskQueryList();
    //   }
    // },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .el-form-item {
    /* width: 230px;
  flex-shrink: 0; */
    width: 45% !important;
    .el-select {
      width: 100%;
    }
    .el-cascader {
      width: 100%;
    }
  }
}
.title {
  text-align: left;
  font-size: 1.5rem;
}
a:hover {
  color: #409eff;
}
</style>
