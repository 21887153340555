<!--转派任务-->
<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="transferTaskDalogVisible"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
    :destroy-on-close="false"
    append-to-body
  >
    <div class="bar" v-if="formData != null">
      <li class="width50">
        <span>负责人：</span>
        <el-select
          v-model="formData.ownerId"
          @visible-change="projectTeam($event)"
          filterable
          placeholder="请选择"
          clearable
          class="childWidth"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in internalUseroptionsList"
            :key="item.uid"
            :label="item.realName"
            :value="item.uid"
            :disabled="item.canSelect === 0"
          >
          </el-option>
        </el-select>
      </li>
    </div>

    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
// import Vue from "vue";
//1

import {
  addTask,
  editTask,
  findDetailTask,
} from "@/api/myMission";
import {projectAssignUserList} from "@/api/projectManage";
import { formatDate } from "@/common/date";
export default {
  name: "addTaskEdit",
  data() {
    return {
      title: "转派",
      token: null,
      formData: {
        businessType: 1,
        businessId: null,
      },
      searchPorjectListLoading: false,
      tskTemplateListOptions: null,
      workflowListOptions: null,
      investorwListOptions: null,
      internalUseroptionsList: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      pageNum: 1,
      pageSize: 10,
      total: null,
      pageCount: null,
      loadingTable: false,
      statusList: [
        { key: "未开始", value: 0 },
        { key: "进行中", value: 1 },
        { key: "已完成", value: 2 },
      ],
      calendarScheduleSelectList: [],

      workflowId: '',
    };
  },
  props: {
    type: String,
    transferTaskDalogVisible: Boolean,
    id: String,
    businessType: Number,
    info: Object,
    taskOneInfo: Object,
  },
  components: {},
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  created() {
   if (this.type == "transfer") {
      this.title = "转派项目" + '——' + this.taskOneInfo.name;
      this.findDetailTask(this.taskOneInfo.id);
    }
  },
  methods: {
    findDetailTask(id) {
      let info = { id: id };
      findDetailTask(info).then((response) => {
        if (response.success) {
          this.formData = { ...response.data };
          for (const relation of this.formData.relationList) {
            if (relation.workflowId) {
              this.workflowId = relation.workflowId;
              break;
            }
          }
          this.projectTeam(true);
        }
      });
    },
    //
    projectTeam(cb) {
      if (cb) {
        projectAssignUserList(this.workflowId).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },


    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listenTransferTaskClose", { type: "cancel", isClose: true });
    },

    add() {
      addTask(this.formData).then((response) => {
        if (response.success) {
          console.log(response.data);
          this.$message({ type: "success", message: "添加成功!" });
          this.formData = {};
          this.$emit("listenTransferTaskClose", {
            type: "sure",
            isClose: true,
            info: response.data,
          });
        }
      });
    },
    edit() {
      editTask(this.formData).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "编辑成功!" });
          this.formData = {};
          this.$emit("listenTransferTaskClose", {
            type: "sure",
            isClose: true,
          });
        }
      });
    },
    //确定
    sure() {
      this.formData = {ownerId: this.formData.ownerId,id: this.formData.id};
      console.log(this.formData);
      if (this.type == "transfer"  ) {
        this.edit();
      } else {
        this.add();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}
.buttonGrop {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-right: 100px;
  }
}
.el-icon-iconName {
  margin: 0 5px;
}
</style>
