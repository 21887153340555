import Vue from 'vue'
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

export function copyId(id) {
    Vue.prototype.$copyText(id).then(
        function (e) {
            // that.$message({ type: "success", message: "项目码:" + e.text + " 复制成功!" });
            console.log(e); 
        },
        function (e) {
            // that.$message({ type: "success", message: "项目码复制失败!" });
            console.log(e);
        }
    )
}