<template>
  <div class>
    <el-row type="flex" class="row-bg" justify="end">
      <!-- <div class="importTemplateInput" @click="importTemplate">
        <el-button
          type="primary"
          style="margin-left: 0px"
          v-permission="['admin:evaluation:record:import']"
          >导入Excel</el-button
        >
        <input
          type="file"
          ref="uploadExcel"
          accept=".xls, .xlsx"
          class="outputlist_upload file"
          id="importExcel"
          @change="submitImport"
          v-show="submitImportShow"
        />
      </div>
      <el-link
        icon="el-icon-download"
        :underline="false"
        @click="downloadTemplate"
        >没模版？下载</el-link
      > -->
    </el-row>
    <div class="bar">
      <el-form
        :model="formData"
        ref="assessment"
        :rules="rules"
        class="addDailog"
        onsubmit="return false;"
        label-width="120px"
        label-position="right"
      >
        <el-form-item label="项目/投资机构" prop="projectId">
          <el-select
            v-model="formData.projectId"
            class="childWidth"
            filterable
            placeholder="请选择项目/投资机构"
            @visible-change="searchPorjectList($event)"
            @change="choosePorject($event)"
            clearable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in porjectListOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="流程"
          class="roleCodeList"
          prop="beforeProjectWorkflowId"
        >
          <el-select
            v-model="formData.beforeProjectWorkflowId"
            @visible-change="technologicalList($event)"
            @change="chooseTechnologica($event)"
            clearable
            placeholder="请选择"
            filterable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in technological"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务流程节点" prop="projectWorkflowDetailId">
          <el-select
            v-model="formData.projectWorkflowDetailId"
            filterable
            placeholder="请输入关键词从节点中搜索"
            @visible-change="searchWorkflowList($event)"
            @change="chooseWorkflow($event)"
            clearable
            class="childWidth"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in workflowListOptions"
              :key="item.id"
              :label="item.nodeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评估模版" prop="templateId">
          <el-select
            v-model="formData.templateId"
            class="childWidth"
            placeholder="请选择"
            clearable
            @visible-change="selectEvaluationTemplate($event)"
            @change="chooseTemplateId($event)"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in evaluationTemplateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务" prop="taskId">
          <div class="flexJsbAc">
            <el-select
              v-model="formData.taskId"
              @visible-change="getTaskSelectList($event)"
              @change="chooseTask($event)"
              filterable
              placeholder="请选择"
              clearable
              no-data-text="加载中···"
            >
              <el-option
                v-for="item in taskSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="记录人" prop="recorderId">
          <el-select
            v-model="formData.recorderId"
            filterable
            :reserve-keyword="false"
            placeholder="请输入关键词从中搜索"
            @visible-change="projectTeam($event)"
            @change="chooseInvestor($event)"
            clearable
            class="childWidth"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in internalUseroptionsList"
              :key="item.uid"
              :label="item.realName"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="评估人"
          :prop="formData.evaluatorType == 1 ? 'evaluatorId' : 'evaluatorName'"
        >
          <div style="display: flex">
            <el-select
              v-model="formData.evaluatorType"
              style="width: 150px !important"
              @change="onChooseEvaluatorType"
              no-data-text="加载中···"
            >
              <el-option
                v-for="(item, index) in evaluatorTypeOptions"
                :key="index"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>

            <!-- 内部人员选择框 -->
            <template v-if="formData.evaluatorType === 1">
              <el-select
                v-model="formData.evaluatorId"
                placeholder="请选择内部评估人"
                @visible-change="findMyProjectWorkflowMembers"
                @change="onChooseEvaluator"
                value-key="real_name"
                filterable
                clearable
                no-data-text="加载中···"
              >
                <el-option
                  v-for="(item, index) in myProjectWorkflowMembers"
                  :key="index"
                  :value="item.uid"
                  :label="item.real_name"
                ></el-option>
              </el-select>
            </template>

            <!-- 外部人员填写框 -->
            <template v-else>
              <el-input
                v-model="formData.evaluatorName"
                placeholder="请输入外部评估人"
                clearable
              ></el-input>
            </template>
          </div>
        </el-form-item>
        <el-form-item label="评估日期" prop="beginTime">
          <el-date-picker
            v-model="formData.beginTime"
            type="datetime"
            placeholder="选择日期时间"
            align="right"
            :picker-options="pickerOptions"
            style="width: 100% !important"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>

    <div style="text-align: center" class="flexJA">
      <el-button @click="resetConditions">重置</el-button>

      <el-button
        type="primary"
        @click="addAssessment()"
        v-permission="['admin:evaluation:record_start_time']"
        >开始评估</el-button
      >
      <div class="importTemplateInput" @click="importTemplate">
        <el-button
          style="margin-left: 10px"
          v-permission="['admin:evaluation:record:import']"
          >导入Excel</el-button
        >
        <input
          type="file"
          ref="uploadExcel"
          accept=".xls, .xlsx"
          class="outputlist_upload file"
          id="importExcel"
          @change="submitImport"
          v-show="submitImportShow"
        />
      </div>
      <el-link
        icon="el-icon-download"
        :underline="false"
        @click="downloadTemplate"
        >没模版？下载</el-link
      >
    </div>
    <!-- <div v-show="assessmentHistoryList.length > 0">
      <h4 style="text-align: left">评估历史：</h4>

      <div
        class="a"
        v-for="(item, index) in assessmentHistoryList"
        :key="index"
      >
        <p>
          流程{{ index + 1 }}：{{ item.name }}
          <span v-show="item.version" class="workflowVersion">
            版本{{ item.version }}</span
          >
        </p>
        <el-table
          border
          row-key="id"
          style="width: 100%; margin-top: 10px"
          :data="item.recordList"
          highlight-current-row
        >
          <el-table-column label="流程节点" prop="workflowNodeName">
            <template slot-scope="scope">
              <span>{{ scope.row.workflowNodeName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="模板" prop="templateName"></el-table-column>
          <el-table-column label="分数" prop="totalScore"></el-table-column>

          <el-table-column label="时间" prop="createDate">
            <template slot-scope="scope">
              <span>{{ scope.row.beginTime | formatDateFilter }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template>
              <el-button
                type="primary"
                size="mini"
                v-permission="['admin:task:add']"
                @click="openAssessmentHistory(scope.row)"
                >评估记录</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div> -->
    <div v-if="evaluateRecordTableData != null">
      <h4 style="text-align: left; margin-top: 20px">评估记录：</h4>
      <el-table
        v-loading="tableLoading"
        ref="multipleTable"
        width="100%"
        border
        :span-method="objectSpanMethod"
        :data="evaluateRecordTableData"
        style="width: 100%; margin-top: 10px"
      >
        <el-table-column label="模块" align="center" fixed="left">
          <template slot-scope="scope" width="160">
            <p>
              {{ scope.row.module }}
            </p>
            <!-- <div>{{ scope.row.subModule }}</div> -->
          </template>
        </el-table-column>
        <el-table-column
          label="评估要素"
          width="180"
          align="center"
          fixed="left"
        >
          <template slot-scope="scope">
            <div style="color: #ccc">
              {{ scope.row.subModule
              }}<span v-show="scope.row.subModule">:</span>
            </div>

            <p>{{ scope.row.question }}</p>
          </template>
        </el-table-column>
        <el-table-column label="评估得分" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.score }}</p>
          </template>
        </el-table-column>
        <el-table-column label="模块评估得分" align="center">
          <template slot-scope="scope">
            <p>{{ scope.row.moduleScore }}</p>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 下载 -->
    <downloadTemplate
      v-if="downloadTemplateDialog"
      v-on:listendownloadTemplateClose="listendownloadTemplateClose"
      :downloadTemplateDialog="downloadTemplateDialog"
    >
    </downloadTemplate>

    <el-dialog
      title
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <answer
        ref="answer"
        :questionList="questionList"
        :titleTi="titleTi"
        :isAbleSubmit="!isAbleSubmit"
        :timu="timu"
        :explainList="explainList"
        :templateInfo="templateInfo"
      ></answer>
      <span center slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          icon="el-icon-arrow-left"
          :disabled="titleTi == 0 ? true : false"
          @click="prev()"
          >上一模块</el-button
        >
        <el-button :disabled="isAbleSubmit" @click="addFrom()">提交</el-button>
        <el-button type="primary" :disabled="isAbleNext" @click="next()">
          下一模块
          <i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </span>
    </el-dialog>
    <evaluateRecordTable
      v-if="evaluateRecordTableDialog"
      v-on:listenEvaluateRecordTableClose="listenEvaluateRecordTableClose"
      :evaluateRecordTableDialog="evaluateRecordTableDialog"
      :id="evaluationRecordId"
    >
    </evaluateRecordTable>
  </div>
</template>

<script>
import answer from "@/components/assessment/answer";
import downloadTemplate from "@/components/myMission/downloadTemplate";
import evaluateRecordTable from "@/components/myMission/evaluationList/evaluateRecordTable";

import {
  assessmentProject,
  findQuestionList,
  submitQuestionSl,
  newSubmitQuestion,
  evaluationRecord,
  findTypeProject,
  projectTeam,
  selectEvaluationTemplate,
  selectTypeProject,
  assessmentHistory,
  getEvaluationRecordTable,
  getEvaluationRecordDetail,
} from "@/api/projectManage";

import {
  selectListMeetingProject,
  selectListWorkflow,
  selectListInvestor,
} from "@/api/minutesOfMeeting";
import { importRecord } from "@/api/home";
import { formatDate } from "@/common/date";
import {
  findMyProjectWorkflowMembers,
  getTaskSelectList,
} from "@/api/myMission";
import dynamicTable from "@/components/myMission/evaluationList/dynamicTable";
import eventBus from "@/common/eventBus";

//
import XLSX from "xlsx";
export default {
  name: "assessment",
  data() {
    return {
      templateInfo: null,
      outputs: [],
      formData: {
        projectWorkflowId: null,
        projectId: null,
        noteTaker: window.localStorage.getItem("realName"),
        recorderId: null,
        beginTime: new Date().getTime(),
        beforeProjectWorkflowId: null,
        templateId: null,
        projectWorkflowDetailId: null,
        taskId: null,
        evaluatorId: null,
        evaluatorName: null,
        evaluatorType: 1,
      },
      searchPorjectListLoading: false,
      searchWorkflowListLoading: false,
      tableLoading: false,
      porjectListOptions: null,
      workflowListOptions: null,
      internalUseroptionsList: null,
      evaluationTemplateList: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      limit: 1, // 上传excell时，同时允许上传的最大数
      fileList: [], // excel文件列表
      isShowFile: false, //文件列表是否显示 默认不显示
      submitImportShow: false,
      formData1: new FormData(),
      downloadTemplateDialog: false,
      //before
      isAbleSubmit: true,
      isAbleNext: false,
      dialogVisible: false,
      options: [],
      timu: [],
      tableData: [],
      recordList: null,
      headerList: null,
      evaluationRecordId: null,
      questionParameter: {
        pageNum: 1,
      },
      titleTi: 0,
      value: "",
      questionList: [], //问题列表
      question: [[]],
      textArr: [],
      chnNumChar: ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"],
      chnUnitSection: ["", "万", "亿", "万亿", "亿亿"],
      chnUnitChar: ["", "十", "百", "千"],
      indexOf: [0],
      evaluationTimes: 0,
      investorwListOptions: [],
      technological: [],
      rules: {
        beforeProjectWorkflowId: [
          { required: true, message: "请选择流程", trigger: "change" },
        ],
        templateId: [
          { required: true, message: "请选择模版", trigger: "change" },
        ],
        projectId: [
          { required: true, message: "请选择项目/投资机构", trigger: "change" },
        ],
        projectWorkflowDetailId: [
          { required: true, message: "请选择业务流程节点", trigger: "change" },
        ],
        recorderId: [
          { required: true, message: "请选择记录人", trigger: "change" },
        ],
        evaluatorName: [
          { required: true, message: "请输入外部评估人", trigger: "blur" },
        ],
        evaluatorId: [
          { required: true, message: "请选择内部评估人", trigger: "change" },
        ],
        beginTime: [
          { required: true, message: "请选择评估时间", trigger: "change" },
        ],
        taskId: [{ required: true, message: "请选择任务", trigger: "change" }],
      },
      assessmentHistoryList: [],
      taskSelectList: [],
      moduleScoreHeaderName: null,
      evaluateRecordTableData: null,
      explainList: [],
      evaluateRecordTableDialog: false,
      needMergeArr: ["moduleName", "subModuleName"], // 有合并项的列
      rowMergeArrs: {}, // 包含需要一个或多个合并项信息的对象
      evaluateRecordId: null,
      evaluatorTypeOptions: [
        { value: 1, label: "内部人员" },
        { value: 2, label: "外部人员" },
      ],
      myProjectWorkflowMembers: null,
    };
  },
  props: {
    projectTaskInfo: Object,
    project: Object,
    jumpBack: String,
  },
  components: {
    answer,
    downloadTemplate,
    dynamicTable,
    evaluateRecordTable,
  },
  computed: {},
  watch: {
    project: {
      handler(newName, oldName) {
        console.log("obj.a changed1");
        if (this.project) {
          // this.formData = this.project
          this.formData.projectId = this.project.id;
          this.formData.projectName = this.project.projectName;
          // this.formData.projectWorkflowId = this.project.projectWorkflowId;
          this.formData.beforeProjectWorkflowId =
            this.project.projectWorkflowId;
          this.formData.projectWorkflowDetailId =
            this.project.projectWorkflowDetailId;
          this.formData.taskId = this.project.taskId;
          this.formData.taskName = this.projectTaskInfo.name;

          this.formData.templateId = this.project.templateId;
          if (this.formData.projectId) {
            console.log("this.formData.projectId");
            this.searchPorjectList(true);
            this.searchWorkflowList(true);
            // this.assessmentHistory();
            this.technologicalList(true);
            this.getTaskSelectList(true);
            this.selectEvaluationTemplate(true);
          }
          if (this.formData.beforeProjectWorkflowId) {
          }
          this.$forceUpdate();
        }
        // if (this.projectTaskInfo) {
        //   this.formData.workflowNodeName = this.projectTaskInfo.nodeName;
        //   this.formData.projectWorkflowDetailId =
        //     this.projectTaskInfo.projectWorkflowDetailId;
        // }
      },
      immediate: true,
      deep: true,
    },
    formData: {
      handler(newValue, old) {
        this.$refs["assessment"].validate((valid) => {
          console.log(valid);
          if (valid) {
            this.submitImportShow = true;
          }
        });
      },
      // immediate: true,
      // deep: true,
    },
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  created() {
    this.projectTeam(true);
  },
  mounted() {
    console.log(
      "this.$store.state.assessmentProjectId----" +
        this.$store.state.assessmentProjectId
    );
    var that = this;

    console.log(this.project);
    // if (this.project) {
    //   this.searchPorjectList(true);
    //   this.searchWorkflowList(true);
    //   // this.formData = {};
    //   this.formData.projectId = this.project.id;
    //   this.formData.projectName = this.project.projectName;
    //   this.formData.projectWorkflowId = this.project.projectWorkflowId;
    //   this.formData.projectWorkflowDetailId = this.projectTaskInfo.projectWorkflowDetailId;
    //   this.$forceUpdate();
    // }
    // this.typeList();
  },
  methods: {
    listenEvaluateRecordTableClose(params) {
      const { type, isClose } = params;
      this.evaluateRecordTableDialog = !isClose;
      if (type == "sure") {
      }
    },
    getEvaluationRecordDetail(data) {
      this.evaluationRecordId = data.id;
      this.evaluateRecordTableDialog = true;

      // getEvaluationRecordDetail(info).then((res) => {
      //   if (res.success) {
      //     let info = res.data;
      //     this.evaluateRecordTableData = info.detailList;
      //     this.moduleScoreHeaderName =
      //       "V" +
      //       info.workflowVersion +
      //       info.projectWorkflowName +
      //       "_" +
      //       info.workflowNodeName +
      //       "_" +
      //       info.taskName;
      //     this.getSpanArr(this.evaluateRecordTableData);
      //     // this.contentDialog = true;
      //     this.tableLoading = false;
      //     this.$forceUpdate();
      //   }
      // });
    },
    resetConditions() {
      this.$refs["assessment"].resetFields(); //重置form
    },
    getTaskSelectList(cb) {
      if (cb) {
        this.taskSelectList = [];
        if (!this.formData.projectWorkflowDetailId) {
          this.$message({ type: "warning", message: "请先选择业务流程节点!" });
          return false;
        }
        let info = {
          relationType: 1,
          relationBizId: this.formData.projectWorkflowDetailId,
        };
        getTaskSelectList(info).then((response) => {
          if (response.success) {
            this.taskSelectList = response.data;
          }
        });
      }
    },
    chooseTask(id) {
      var that = this;
      console.log(id);
      that.taskSelectList.forEach(function (item, index) {
        if (id == item.id) {
          that.formData.taskId = item.id;
          that.formData.taskName = item.name;
          that.$forceUpdate();
        }
      });
    },
    chooseTemplateId(id) {
      console.log(id);
      this.formData.templateId = id;
      this.$forceUpdate();
    },
    openAssessmentHistory(row) {
      console.log(row);
      let info = {};
      info.projectId = row.projectId;
      info.projectWorkflowId = row.projectWorkflowId;
      info.templateId = row.templateId;
      this.recordPg(info);
    },
    assessmentHistory() {
      assessmentHistory({ projectId: this.formData.projectId }).then(
        (response) => {
          if (response.success) {
            this.assessmentHistoryList = response.data;
          }
        }
      );
    },
    listendownloadTemplateClose(params) {
      const { type, isClose } = params;
      this.downloadTemplateDialog = !isClose;
      if (type == "sure") {
      }
    },
    //下载模版
    downloadTemplate() {
      this.downloadTemplateDialog = true;
    },
    handleExport(item) {
      //下载文件
      var elemIF = document.createElement("iframe");
      elemIF.src = item.url;
      elemIF.style.display = "none";
      document.body.appendChild(elemIF);
    },
    //判断对象中属性是否全部有值
    paramsValidate(params) {
      let flag = true;
      for (var key in params) {
        if (params[key] != "0" && !params[key]) {
          this.$message({ type: "warning", message: "请完善数据!" });
          return false; // 终止程序
        }
      }
      return flag;
    },
    warninMessage() {
      this.$message({ type: "warning", message: "请完善数据!" });
    },
    //导入模版
    importTemplate() {
      console.log(this.formData);
      this.$refs["assessment"].validate((valid) => {
        if (valid) {
          console.log(this.formData);
          if (this.submitImportShow == false) {
            this.submitImportShow = true;
            this.$refs.uploadExcel.dispatchEvent(new MouseEvent("click"));
          }
          let formData = new FormData();
          formData.append("beginTime", this.formData.beginTime);
          formData.append("projectId", this.formData.projectId);
          formData.append(
            "projectWorkflowDetailId",
            this.formData.projectWorkflowDetailId
          );

          if (this.formData.evaluatorType === 1) {
            formData.append("evaluatorId", this.formData.evaluatorId);
          }
          formData.append("evaluatorName", this.formData.evaluatorName);
          formData.append("workflowNodeName", this.formData.workflowNodeName);
          formData.append("templateId", this.formData.templateId);
          formData.append("projectWorkflowId", this.formData.projectWorkflowId);
          //projectWorkflowId
          this.formData1 = formData;
        }
      });
    },

    submitImport(e) {
      var that = this;

      //声明一个FormDate对象
      // let formData = new FormData();
      this.formData1.append("file", e.target.files[0]);
      // formData.append("file", e.target.files[0]);
      console.log(e.target);

      let file = e.target.files[0];
      let name = e.target.files[0].name;

      importRecord(this.formData1).then((response) => {
        if (response.success) {
          console.log(response);
          this.$message({ type: "success", message: "导入成功!" });

          this.formData1 = new FormData();
        }
      });
    },
    //
    selectEvaluationTemplate(cb) {
      var that = this;
      if (cb) {
        selectEvaluationTemplate({
          projectId: this.formData.projectId,
          workflowId: this.formData.beforeProjectWorkflowId,
        }).then((response) => {
          if (response.success) {
            that.evaluationTemplateList = response.data;
            if (that.evaluationTemplateList.length == 1) {
              console.log("03");
              console.log(that.evaluationTemplateList[0].id);

              that.formData.templateId = that.evaluationTemplateList[0].id;
              that.$forceUpdate();
            } else {
              that.formData.templateId = null;
            }
          }
        });
      }
    },
    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
            if (this.formData.recorderId == null) {
              this.formData.recorderId = window.localStorage.getItem("userId");
              this.formData.recorderName =
                window.localStorage.getItem("realName");

              this.$forceUpdate();
            }
          }
        });
      }
    },
    chooseInvestor(id) {
      var that = this;
      console.log(id);
      that.internalUseroptionsList.forEach(function (item, index) {
        if (id == item.uid) {
          that.formData.recorderId = item.uid;
          that.formData.recorderName = item.realName;
          this.$forceUpdate();
        }
      });
    },
    //
    choosePorject(id) {
      var that = this;
      console.log(id);
      that.formData.templateId = null;
      that.formData.beforeProjectWorkflowId = null;
      that.formData.projectWorkflowDetailId = null;
      that.formData.taskId = null;
      that.tableData = null;
      that.porjectListOptions.forEach(function (item, index) {
        if (id == item.id) {
          that.formData.projectId = item.id;
          that.formData.projectName = item.projectName;
          // that.formData.projectWorkflowId = item.projectWorkflowId;
          // that.selectEvaluationTemplate(true);
          // that.formData.templateId = item.evaluationTemplateId;
          that.formData.beginTime = new Date().getTime();
          that.technologicalList(true);
          // that.assessmentHistory();
        }
      });
    },
    chooseWorkflow(id) {
      var that = this;
      that.formData.taskId = null;
      that.workflowListOptions.forEach(function (item, index) {
        if (id == item.id) {
          that.formData.projectWorkflowDetailId = item.id;
          that.formData.workflowNodeName = item.nodeName;
          that.formData.projectWorkflowId = item.projectWorkflowId;
          that.formData.beginTime = new Date().getTime();
        }
      });
    },
    chooseTechnologica(id) {
      this.selectEvaluationTemplate(true);
      this.formData.projectWorkflowDetailId = null;
      this.formData.taskId = null;
      this.formData.evaluatorId = null;
      this.formData.evaluatorName = null;
      this.$forceUpdate();
    },
    //
    technologicalList(cb) {
      console.log("此时");
      var that = this;
      that.technological = [];
      if (cb) {
        //流程列表
        let param = {};
        param.projectId = that.formData.projectId;
        selectTypeProject(param).then((res) => {
          if (res.success) {
            let technological = res.data;
            that.technological = technological;
            if (technological.length == 0 || !technological) {
              that.$message({ type: "warning", message: "请先创建业务流程!" });
            } else if (technological.length == 1) {
              that.formData.beforeProjectWorkflowId = technological[0].id;
              that.selectEvaluationTemplate(true);
              that.$forceUpdate();
            } else {
              // that.formData.beforeProjectWorkflowId = null;
            }
          }
        });
      }
    },
    //
    searchPorjectList(cb) {
      var that = this;
      if (cb) {
        selectListMeetingProject({}).then((response) => {
          if (response.success) {
            this.porjectListOptions = response.data;
          }
        });
      }
    },
    selectListInvestor(cb) {
      let name = null;
      if (cb) {
        selectListInvestor(name).then((response) => {
          if (response.success) {
            this.investorwListOptions = response.data;
          }
        });
      }
    },

    //
    searchWorkflowList(cb) {
      this.workflowListOptions = [];
      var that = this;
      let param = {};
      param.nodeName = null;
      // param.projectWorkflowId = this.formData.projectWorkflowId;
      param.workflowId = that.formData.beforeProjectWorkflowId;
      param.projectId = that.formData.projectId;
      // param.workflowId = this.formData.projectName;
      if (cb) {
        selectListWorkflow(param).then((response) => {
          if (response.success) {
            that.workflowListOptions = response.data;
            that.workflowListOptions.forEach(function (item, index) {
              if (that.formData.projectWorkflowDetailId == item.id) {
                that.formData.projectWorkflowDetailId = item.id;
                that.formData.workflowNodeName = item.nodeName;
                that.formData.projectWorkflowId = item.projectWorkflowId;
                that.formData.beginTime = new Date().getTime();
              }
            });
          }
        });
      }
    },
    backBtn() {
      this.$router.push({
        path: "/project",
      });
    },
    SectionToChinese(section) {
      var strIns = "",
        chnStr = "";
      var unitPos = 0;
      var zero = true;
      while (section > 0) {
        var v = section % 10;
        if (v === 0) {
          if (!zero) {
            zero = true;
            chnStr = this.chnNumChar[v] + chnStr;
          }
        } else {
          zero = false;
          strIns = this.chnNumChar[v];
          strIns += this.chnUnitChar[unitPos];
          chnStr = strIns + chnStr;
        }
        unitPos++;
        section = Math.floor(section / 10);
      }
      return chnStr;
    },

    NumberToChinese(num) {
      var unitPos = 0;
      var strIns = "",
        chnStr = "";
      var needZero = false;

      if (num === 0) {
        return this.chnNumChar[0];
      }

      while (num > 0) {
        var section = num % 10000;
        if (needZero) {
          chnStr = this.chnNumChar[0] + chnStr;
        }
        strIns = this.SectionToChinese(section);
        strIns +=
          section !== 0 ? this.chnUnitSection[unitPos] : this.chnUnitSection[0];
        chnStr = strIns + chnStr;
        needZero = section < 1000 && section > 0;
        num = Math.floor(num / 10000);
        unitPos++;
      }

      return chnStr;
    },
    addAssessment() {
      //开始测评
      this.$refs["assessment"].validate((valid) => {
        if (valid) {
          this.questionParameter.type = this.formData.type;
          this.questionParameter.module = this.titleTi;
          this.questionParameter.pageNum = this.titleTi + 1;
          this.questionParameter.templateId = this.formData.templateId;
          // this.questionParameter.pageNum = this.pageNum;
          this.pageSum(this.questionParameter, "start");
          // assessmentProject(this.formData).then((res) => {
          //   //开始时间
          //   if (res.success) {
          //     this.$message({ type: "success", message: "开始评估!" });
          //     this.questionParameter.type = this.formData.type;
          //     this.questionParameter.module = this.titleTi;
          //     this.questionParameter.pageNum = this.titleTi + 1;
          //     this.questionParameter.templateId = this.formData.templateId;
          //     // this.questionParameter.pageNum = this.pageNum;
          //     this.pageSum(this.questionParameter, "start");
          //     this.dialogVisible = true;
          //     this.evaluationRecordId = res.data;
          //   }
          // });
        }
      });
    },
    next() {
      //下一模块
      console.log(this.titleTi, this.timu.length - 1);
      if (this.titleTi < this.timu.length - 1) {
        ++this.titleTi;
        this.isAblePrev = false;
      } else {
        this.titleTi = this.timu.length - 1;
        this.isAbleNext = true;
        this.isAbleSubmit = false;
      }
      this.questionParameter.module = this.titleTi;
      this.questionParameter.pageNum = this.titleTi + 1;
      this.questionParameter.templateId = this.formData.templateId;

      this.pageSum(this.questionParameter);
      // let re = this.question[this.titleTi].some((i) => {
      //   return i.result == "";
      // });
      // if (re) {
      //   this.$message.success("您还有题没答完");
      // } else {

      // }
    },
    prev() {
      //上一模块
      if (0 < this.titleTi) {
        --this.titleTi;
      } else {
        this.titleTi = 0;
      }
      this.isAbleNext = false;
      this.isAbleSubmit = true;
      this.questionParameter.module = this.titleTi;
      this.questionParameter.pageNum = this.titleTi + 1;
      this.questionParameter.templateId = this.formData.templateId;

      this.pageSum(this.questionParameter);
    },
    pageSum(val, type) {
      findQuestionList(val).then((res) => {
        console.log(res.data);
        //题目列表
        if (res.success) {
          console.log(this.question[this.titleTi]);
          if (this.question.length == 1) {
            for (var i = 1; i < res.pageCount; i++) {
              this.question[i] = [];
            }
          }

          if (this.question[this.titleTi][0] == undefined) {
            this.question[this.titleTi] = res.data.questions.map((i, index) => {
              return {
                templateItemId: i.id,
                moduleName: i.moduleName,
                subModuleName: i.subModuleName,
                subModuleId: i.subModuleId,
                moduleId: i.moduleId,
                // module: this.timu[this.titleTi + 1],
                score: "",
              };
            });
          }
          this.questionList = res.data.questions;
          this.explainList = res.data.explainList;
          this.timu = res.data.modules;
          this.templateInfo = {
            templateName: res.data.templateName,
            templateId: res.data.templateId,
            templateDescription: res.data.templateDescription,
          };
          this.dialogVisible = true;

          // this.questionParameter.pageNum++;
        }
      });
    },
    addFrom() {
      //提交按钮
      let form = this.$refs.answer.froms;
      // this.titleTi = form.titleTi;
      var subButton = {
        ...this.formData,
      };

      // subButton.beginTime = this.timestamp;
      // subButton.beginTime = this.formData.beginTime;
      // subButton.templateId = this.formData.templateId;
      // subButton.projectId = this.formData.projectId;
      // subButton.type = this.formData.type;
      subButton.comment = form.desc;
      // this.dialogVisible = false;
      // console.log(this.question);
      let questionList = JSON.parse(JSON.stringify(this.question));
      subButton.recordItemList = questionList.reduce(function (a, b) {
        return a.concat(b);
      });
      // subButton.id = this.evaluationRecordId.id;

      this.subBtn(subButton);
    },
    subBtn(subButton) {
      newSubmitQuestion(subButton).then((res) => {
        //提交接口
        if (res.success) {
          this.$message.success("项目评估已成功提交!");

          this.$refs.answer.froms.desc = null;
          this.questionList = [];
          this.question = [[]];
          this.timu = [];

          this.titleTi = 0;
          this.isAbleSubmit = true;
          this.isAbleNext = false;
          this.dialogVisible = false;
          console.log(res.data);
          this.getEvaluationRecordDetail(res.data);
          if (this.jumpBack == "assessment") {
            this.$emit("listenJumpBack", {
              jumpBack: "assessment",
            });
            // eventBus.$emit("assessment");
            this.$emit("update:jumpBack", null);
          }
          this.$forceUpdate();

          // this.$refs["assessment"].resetFields();
        }
      });
    },
    getEvaluationRecordTable() {
      this.tableLoading = false;
      let info = this.evaluationRecordId;
      getEvaluationRecordTable(info).then((response) => {
        if (response.success) {
          let tableData = response.data;
          this.tableData = response.data;
          this.recordList = tableData.recordList;
          this.headerList = tableData.headerList;
          this.tableLoading = true;
        }
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          // this.typeList();
          // this.pageSum(this.titleTi);
          this.questionList = [];
          this.question = [[]];
          this.formData.type = "";
          this.titleTi = 0;
          this.timu = [];
          this.isAbleSubmit = true;
          this.isAbleNext = false;
          this.dialogVisible = false;
          this.$forceUpdate();
          done();
        })
        .catch((_) => {});
    },
    //this.tableData 指接口取到的数据
    // 2、 因为要合并的行数是不固定的，此函数是实现合并随意行数的功能
    getSpanArr(data) {
      this.spanArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          if (data[i].module === data[i - 1].module) {
            // 如果module相等就累加，并且push 0  这里是根据一样的module匹配
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 不相等push 1
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
      this.tableLoading = false;
    },
    // 3、合并行数
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      for (let i = 0; i < this.indexOf.length; i++) {
        if (columnIndex === this.indexOf[i]) {
          const _row = this.spanArr[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col,
          };
        }
      }
    },
    recordPg(info) {
      this.tableLoading = true;
      //评估查询
      this.tableData = [];
      this.textArr = [];
      var addRess = this.$route.query.rows;
      evaluationRecord(info).then((res) => {
        if (res.success) {
          for (var i = 0; i < res.data.evaluationTimes; i++) {
            this.textArr.push(i + 1);
          }
          this.tableData = res.data.evaluationList;
          for (let i = 3; i < res.data.evaluationTimes * 2 + 3; i++) {
            if (i % 2 == 1) {
              this.indexOf.push(i);
            }
          }
          this.getSpanArr(this.tableData);
          this.$forceUpdate();
        }
      });
    },
    typeList() {
      var addRess = this.$route.query.rows;
      console.log(addRess);
      findTypeProject(addRess).then((res) => {
        //类型列表
        if (res.success) {
          this.options = res.data;
          console.log(this.options);
          if (this.options.length == "1") {
            this.formData.type = this.options[0].type;
          }
        }
      });
    },
    findMyProjectWorkflowMembers() {
      let param = {
        id: this.formData.beforeProjectWorkflowId,
      };
      findMyProjectWorkflowMembers(param).then((res) => {
        if (res.success) {
          this.myProjectWorkflowMembers = res.data;
        }
      });
    },
    onChooseEvaluatorType() {
      this.formData.evaluatorId = null;
      this.formData.evaluatorName = null;
    },
    onChooseEvaluator(uid) {
      let member = this.myProjectWorkflowMembers.find((x) => x.uid === uid);
      if (member) {
        this.formData.evaluatorName = member.real_name;
      }
    },
  },

  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }
  .el-form-item {
    text-align: left;

    // display: flex;
    width: 50% !important;
    // float: left;
    min-height: 1rem;
    .el-select,
    .el-autocomplete {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
    .el-date-picker {
      width: 100% !important;
    }
  }
}
.importTemplateInput {
  position: relative;
  margin-right: 20px;

  // display: none;
  // width: 50px;
}
.outputlist_upload {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  opacity: 0;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
  i {
    color: red;
  }
}
.workflowVersion {
  color: #ccc;
  font-size: 12px;
}
</style>
