<template>
  <el-dialog
    title="上传文件"
    :visible="addFileiDalogVisible"
    class="edit"
    center
    @close="dialogClose"
    width="400px"
    :close-on-click-modal="false"
    :destroy-on-close="false"
  >
    <div class="one" v-for="(item, index) in fileNameArr" :key="index">
      <div class="oneAdd" v-show="item">
        <p style="line-height: 30px">{{ item }}:</p>
      </div>
      <el-upload
        class="upload-demo"
        action
        :http-request="upload1"
        :on-change="handleChange"
        :file-list="fileList"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
      >
        <el-button
          size="small"
          style="height: 40px"
          @click="choseOne(item)"
          type="primary"
          >点击上传</el-button
        >
      </el-upload>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogClose">取 消</el-button>
      <el-button :disabled="upLoadButton" type="primary" @click="addFileDialog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  authorizationAdd,
  upLoad,
  handleListArr,
  findListArr,
} from "@/api/processProgress";
import { handleProjectWorkflow } from "@/api/myMission";
export default {
  name: "addFile",
  // props: ["relevantFile", "nodeName"],
  props: {
    relevantFile: String,
    nodeName: String,
    addFileiDalogVisible: Boolean,
    info: Object,
  },
  data() {
    return {
      fileNameArr: [],
      fileList: [],
      upLoadImgUrlBefore: "",
      fileUrl: "",
      files: [],
      objFileName: [],
      arr: [],
      originalFileName:null,
      upLoadButton:true,
    };
  },
  methods: {
    //弹窗关闭
    dialogClose() {
      //   this.formData = {};
      this.$emit("listenAddFileClose", { type: "cancel", isClose: true });
    },
    handleRemove(file, fileList) {
      // console.log(file, fisleList);
      this.arr.forEach((res, index) => {
        if (res == file.name) {
          this.arr.splice(index, 1);
          this.files.splice(index, 1);
        }
      });

      this.$emit("deleteFile", this.files);
    },
    beforeRemove(file, fileList) {
      console.log(file, fileList);

      return this.$confirm(`确定移除 ${file.name}？`);
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    choseOne(item) {
      this.upLoadButton = true;
      //要上传的相关文件
      this.objFileName = item;
      console.log(this.objFileName);
    },
    addFile(val) {
      let data = {};
      data.directory = "project";
      authorizationAdd(data).then((res) => {
        //获取文件上传凭证
        if (res.success) {
          var uuid = this.uuidv4();
          let formData = new FormData();
          let filename = `${uuid}.${val.file.name.split(".")[val.file.name.split(".").length -1]}`;
          console.log(val.file.name);//文件原本的名字
          this.originalFileName = val.file.name
          this.arr.push(val.file.name);
          // 文件名字，可设置路径
          formData.append("key", `${res.data.directory}${filename}`);
          // policy规定了请求的表单域的合法性
          formData.append("policy", res.data.policy);
          // Bucket 拥有者的Access Key Id
          formData.append("OSSAccessKeyId", res.data.accessId);
          // 让服务端返回200,不然，默认会返回204
          formData.append("success_action_status", "200");
          // 根据Access Key Secret和policy计算的签名信息，OSS验证该签名信息从而验证该Post请求的合法性
          // formData.append('callback', res.data.callback)
          formData.append("signature", res.data.signature);
          formData.append("name", filename);

          //Content-Disposition生效必须放在formData.append("file", val.file, filename);前
          formData.append(
            "Content-Disposition",
            "inline; filename=" + val.file.name
          );
          // 需要上传的文件filer
          formData.append("file", val.file, filename);

          let host = res.data.host;
          this.upLoad(host, formData);
          this.upLoadImgUrlBefore = host + "/" + res.data.directory + filename;
        }
      });
    },
    upLoad(host, data) {
      upLoad(host, data).then((res) => {
        if (res.status == 200) {
          this.fileUrl = this.upLoadImgUrlBefore;
          var objFile = {};
          objFile.name = this.objFileName;
          objFile.url = this.fileUrl;
          objFile.originalFileName = this.originalFileName;

          this.files.push(objFile);
          console.log(this.files);
          this.$emit("addFile", this.files);
          this.$message({
            message: "文件上传成功",
            type: "success",
          });
          this.upLoadButton = false;
        } else {
          this.$message({
            message: "文件上传失败",
            type: "waring",
          });
        }
      });
    },
    upload1(content) {
      console.log(content);
      this.addFile(content);
    },
    handleChange(file, fileList) {
      // this.fileList = fileList.slice(-3);
      // console.log(file)
    },

    addFileDialog() {
      //点击确定上传文件
      console.log(this.arr, this.detailsId);
      if (this.arr.length > 0) {
        handleProjectWorkflow({
          id: this.info.projectWorkflowDetailId,
          taskId: this.info.id,
          operationCode: this.info.operationCode,
          files: this.files,
        }).then((res) => {
          console.log(res);
          if (res.success) {
            this.$message({ type: "success", message: "上传文件成功!" });
            this.$forceUpdate();
            this.arr = [];
            this.$emit("listenAddFileClose", { type: "sure", isClose: true });
          }
        });
      } else {
        this.$message({ type: "success", message: "请选择文件!" });
      }
    },
  },
  computed: {},
  watch: {},
  created() {
    console.log(this.relevantFile);
    if (this.info.relevantFile.indexOf(",") != "-1") {
      this.fileNameArr = this.info.relevantFile.split(",");
    } else if (this.info.relevantFile.indexOf("，") != "-1") {
      this.fileNameArr = this.info.relevantFile.split("，");
    } else {
      this.fileNameArr = new Array(this.info.relevantFile);
    }
    this.fileNameArr.forEach((res, index) => {
      if (res == "《项目结项表》") {
        this.fileNameArr.splice(index, 1);
      }
      if (res == "《项目保密协议》") {
        // this.fileNameArr = [""];
      }
    });
    if (this.nodeName) {
      this.fileNameArr.forEach((res, index) => {
        if (res == "《项目评估表》") {
          this.fileNameArr.splice(index, 1);
        }
      });
    }
    // if(this.info.operationId == "WFOP100000002"){

    // }
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  components: {},
};
</script>

<style scoped>
.one {
  text-align: center !important;
}
</style>
