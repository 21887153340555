<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="projectApprovalDalogVisible"
    center
    @close="dialogClose"
    width="500px"
    :close-on-click-modal="false"
    :destroy-on-close="false"
  >
   <div class="bar">
      <li class="width100">
        <span>投融资类型:</span>
       <el-select v-model="value" @change="finishType()" placeholder="请选择">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
    </el-select>
      </li>
    </div>
   

    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>



<script>
import {
  findListArr,
  authorizationAdd,
  upLoad,
  handleListArr
} from "@/api/processProgress";
export default {
  name: "projectApproval",
  props: {
      info:Object,
      projectApprovalDalogVisible:Boolean
  },
  data() {
    return {
      title:'立项',
        formData:{},
      options: [
        {
          value: "1",
          label: "直投/VC"
        },
        {
          value: "2",
          label: "独家FA"
        },
        {
          value: "3",
          label: "非独家FA"
        }
      ],
      value: "",
      isAble: false,
      fileNameArr: [],
      fileList: [],
      upLoadImgUrlBefore: "",
      fileUrl: "",
      files: [],
      objFileName: null,
      arrVal:null
    };
  },
  methods: {
        //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listenProjectApprovalClose", {
        type: "cancel",
        isClose: true,
      });
    },
     finishType() {
      //投融资类型
      let arr = {};
      this.options.map(res => {
        if (res.value == this.value) {
          arr.topicCode = res.value;
          arr.topic = res.label;
          this.arrVal=res.value
        //   this.$emit("finishType", arr);
          this.formData = {...arr};
        }
      });
    },
    //确定
    sure() {  
         this.formData = {...this.formData,id: this.info.projectWorkflowDetailId,taskId: this.info.id,};
       //确定立项
      handleListArr(this.formData).then(res => {
        console.log(res);
        if (res.success) {
          this.$message({ type: "success", message: "立项成功!" });
          this.choosingInvestorsIdList = null;
          this.$emit("listenProjectApprovalClose", {
            type: "sure",
            isClose: true,
          });
        }
      });
       
    },


    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
   
    choseOne(item) {
      //要上传的相关文件
      console.log(item);
      // console.log(index);
      this.objFileName = item;
    },
    async addFileDialog(val) {
      let data = {};
      data.directory = "project";
      authorizationAdd(data).then(res => {
        //获取文件上传凭证
        if (res.success) {
          var uuid = this.uuidv4();
          let formData = new FormData();
          let filename = `${uuid}.${val.file.name.split(".")[1]}`;
          // 文件名字，可设置路径
          formData.append("key", `${res.data.directory}${filename}`);
          // policy规定了请求的表单域的合法性
          formData.append("policy", res.data.policy);
          // Bucket 拥有者的Access Key Id
          formData.append("OSSAccessKeyId", res.data.accessId);
          // 让服务端返回200,不然，默认会返回204
          formData.append("success_action_status", "200");
          // 根据Access Key Secret和policy计算的签名信息，OSS验证该签名信息从而验证该Post请求的合法性
          // formData.append('callback', res.data.callback)
          formData.append("signature", res.data.signature);
          formData.append("name", filename);
          // 需要上传的文件filer
          formData.append("file", val.file, filename);
          formData.append(
            "Content-Disposition",
            "attachment; filename=" + val.file.name    
          );
          let host = res.data.host;
          this.upLoad(host, formData);
          this.upLoadImgUrlBefore = host + "/" + res.data.directory + filename;
        }
      });
    },
    upLoad(host, data) {
      upLoad(host, data).then(res => {
        if (res.status == 200) {
          this.fileUrl = this.upLoadImgUrlBefore;
          var objFile = {};
          objFile.name = this.objFileName;
          objFile.url = this.fileUrl;
          this.files.push(objFile);
        //   this.$emit("addFinish", this.files);
          this.formData.files = this.files;
          this.$message({
            message: "文件上传成功",
            type: "success"
          });
        } else {
          this.$message({
            message: "文件上传失败",
            type: "waring"
          });
        }
      });
    },
    upload1(content) {
      this.addFileDialog(content);
    },
    handleChange(file, fileList) {}
  },
  computed: {},
  watch: {},
  created() {
    // if (this.fileTitle.indexOf(",") != "-1") {
    //   this.fileNameArr = this.fileTitle.split(",");
    // } else if (this.fileTitle.indexOf("，") != "-1") {
    //   this.fileNameArr = this.fileTitle.split("，");
    // }
  },
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  components: {}
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.bar {
  overflow: hidden;
  padding: 10px;
  text-align: center !important;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}
.buttonGrop {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    // margin-right: 100px;
  }
}
.el-icon-iconName {
  margin: 0 5px;
}
</style>
  