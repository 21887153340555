<template>
  <el-dialog
    :title="title"
    width="900px"
    :visible="associatedScheduleDialog"
    @close="dialogClose"
    center
  >
    <el-main>
      <el-row type="flex" class="row-bg" justify="end">
        <el-button round @click="newAssociatedSchedule">{{
          associatedScheduleText
        }}</el-button>
      </el-row>
      <el-form
        class="addDailog"
        :model="formData"
        ref="queryParams"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item
          v-if="associatedScheduleShow"
          class="roleCodeList"
          label="日程"
          prop="scheduleIdList"
        >
          <el-select
            v-model="formData.scheduleIdList"
            @visible-change="getCalendarScheduleSelectList($event)"
            filterable
            placeholder="请选择"
            clearable
            multiple
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in calendarScheduleSelectList"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form
        class="addDailog other"
        :model="calendarForm"
        ref="otherParams"
        label-width="100px"
        :rules="calendarFormRules"
        v-if="!associatedScheduleShow"
      >
        <el-form-item label="标题" prop="title">
          <el-input type="text" v-model="calendarForm.title"></el-input>
        </el-form-item>
        <el-form-item label="日程类别" prop="type">
          <el-select
            v-model="calendarForm.type"
            @visible-change="selectListDict($event, 'schedule_type', 1)"
            filterable
            placeholder="请选择"
            clearable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in dictList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="相关人员" class="roleCodeList" prop="userIdList">
          <el-select
            v-model="calendarForm.userIdList"
            filterable
            placeholder="请输入关键词从中搜索"
            @visible-change="projectTeam($event)"
            @change="chooseInvestor($event)"
            clearable
            multiple
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in internalUseroptionsList"
              :key="item.uid"
              :label="item.realName"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="时间" class="roleCodeList" prop="startAendTime">
          <el-date-picker
            style="width: 100%"
            v-model="calendarForm.startAendTime"
            type="datetimerange"
            placeholder="结束日期"
            value-format="timestamp"
            format="yyyy-MM-dd HH:mm"
            align="right"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['9:00:00', '18:00:00']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="办公形式" prop="officeMode">
          <el-select
            v-model="calendarForm.officeMode"
            @visible-change="selectListDict($event, 'office_mode', 2)"
            filterable
            placeholder="请选择"
            clearable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in dictList2"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="地点" prop="place">
          <el-input type="text" v-model="calendarForm.place"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="note">
          <el-input type="text" v-model="calendarForm.note"></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="primary" @click="dialogClose">取消</el-button>
        <el-button type="primary" @click="sure">确认</el-button>
      </div>
    </el-main>
  </el-dialog>
</template>

<script>
import {
  getCalendarScheduleSelectList,
  addCalendarThing,
} from "@/api/calendar.js";
import { formatDate } from "@/common/date";

import { projectTeam } from "@/api/projectManage";

import { selectListDict } from "@/api/dataManagement";

import { getTaskSelectList, editTask, findDetailTask } from "@/api/myMission";
export default {
  name: "associatedSchedule",
  data() {
    return {
      title: "",
      calendarScheduleSelectList: null,
      formData: {
        scheduleIdList:null,
      },
      calendarForm: {
        userIdList: [],
        taskIdList: [],
      },
      associatedScheduleText: "新建日程",
      associatedScheduleShow: true,

      begintime: "",
      pickerStartOptionsend: {
        disabledDate: (time) => {
          return time.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000;
        },
      },

      pickerOptionsend: {
        disabledDate: (time) => {
          return time.getTime() < this.calendarForm.beginTime - 8.64e7;
        },
      },
      dictList: [],
      dictList2: [],
      rules: {
        scheduleIdList: [
          { required: true, message: "请选择日程", trigger: "change" },
        ],
      },
      calendarFormRules: {
        // projectName: [{ validator: checkProjectName, trigger: "blur" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],

        type: [
          { required: true, message: "请选择日程类别", trigger: "change" },
        ],
        officeMode: [
          { required: true, message: "请选择办公形式", trigger: "change" },
        ],
        taskIdList: [
          { required: true, message: "请选择关联任务", trigger: "change" },
        ],
        startAendTime: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        userIdList:[
          { required: true, message: "请选择相关人员", trigger: "change" },
        ],
      },
      taskSelectList: [],
      selectUserList: [],
      addTaskEditDalogVisible: false,
      addTaskEditType: "other2",
      internalUseroptionsList: null,
    };
  },
  props: {
    type: String,
    associatedScheduleDialog: Boolean,
    id: String,
    info: Object,
  },
  components: {},
  filters: {},
  watch: {
    info: {
      handler(newName, oldName) {
        if (this.info) {
          this.title = this.info.name + "———关联日程";
          this.findDetailTask(this.info.id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    findDetailTask(id) {
      let info = { id: id };
      findDetailTask(info).then((response) => {
        if (response.success) {
          this.formData.scheduleIdList = response.data.scheduleIdList;
          this.getCalendarScheduleSelectList(true);
        }
      });
    },
    newAssociatedSchedule() {
      if (this.associatedScheduleText == "新建日程") {
        this.associatedScheduleText = "选择日程";
      } else if (this.associatedScheduleText == "选择日程") {
        this.associatedScheduleText = "新建日程";
      }
      this.associatedScheduleShow = !this.associatedScheduleShow;
    },
    getCalendarScheduleSelectList(cb) {
      if (cb) {
        let info = { mode: 2 };
        getCalendarScheduleSelectList(info).then((response) => {
          if (response.success) {
            let list = response.data;

            list.map(function (item, index) {
              item.title =
                item.title +
                " " +
                formatDate(new Date(item.beginTime), "yyyy-MM-dd");
            });
            this.calendarScheduleSelectList = list;
          }
        });
      }
    },
    sure() {
      if (this.associatedScheduleShow) {
        this.editTask();
      } else {
        this.addCalendarThing();
      }
    },
    editTask() {
      this.$refs["queryParams"].validate((valid) => {
        if (valid) {
          let formData = { ...this.formData, id: this.info.id };
          editTask(formData).then((response) => {
            if (response.success) {
              this.$message({ type: "success", message: "编辑成功!" });
              this.formData = {};
              this.$emit("listenAssociatedScheduleClose", {
                type: "sure",
                isClose: true,
              });
            }
          });
        }
      });
    },
    //新增事件
    addCalendarThing() {
      this.$refs["otherParams"].validate((valid) => {
        if (valid) {
          let taskIdList = [];
          taskIdList.push(this.info.id);
          let calendarForm = { ...this.calendarForm, taskIdList: taskIdList };
          calendarForm.beginTime = this.calendarForm.startAendTime[0];
          calendarForm.endTime = this.calendarForm.startAendTime[1];
          calendarForm.title = this.calendarForm.title.replace(/\s*/g, "");
          addCalendarThing(calendarForm).then((response) => {
            this.$message.success("添加成功");
            this.$emit("listenAssociatedScheduleClose", {
              type: "sure",
              isClose: true,
            });
          });
        }
      });
    },
    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    chooseInvestor(id) {
      var that = this;
      console.log(id);
    },
    //监听
    listenAddTaskEditClose(params) {
      console.log(params);
      const { type, isClose, info } = params;
      this.addTaskEditDalogVisible = !isClose;
      if (type == "sure") {
        // this.getWorkflowQueryList();
        this.getTaskSelectList(true);
        this.calendarForm.taskIdList.push(info);
      }
    },
    addNewSchedule() {
      console.log("11");
      this.addTaskEditDalogVisible = true;
    },
    getTaskSelectList(cb) {
      if (cb) {
        let info = { mode: 2 };

        getTaskSelectList(info).then((response) => {
          if (response.success) {
            let list = response.data;

            list.map(function (item, index) {
              item.name = item.name + "——" + item.projectName;
            });
            this.taskSelectList = list;
          }
        });
      }
    },
    selectListDict(cb, type, listType) {
      let info = { group: type };
      selectListDict(info).then((response) => {
        if (response.success) {
          if (listType == 1) {
            this.dictList = response.data;
          } else if (listType == 2) {
            this.dictList2 = response.data;
          }
        }
      });
    },

    //弹窗关闭
    dialogClose() {
      this.$emit("listenAssociatedScheduleClose", {
        type: "cancel",
        isClose: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dialog {
  overflow-x: hidden;
}
.row-bg {
  margin: 0 0 2rem 0;
}
.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;

  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }
  .el-form-item {
    text-align: left;
    width: 50%;
    min-height: 1rem;

    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
    .el-date-picker {
      width: 100% !important;
    }
  }
}
</style>