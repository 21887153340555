<template>
  <div>
    <el-main>
      <el-row type="flex" class="title">
        <el-col justify="start"> </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        border
        style="width: 100%"
        :data="tableData"
        v-loading="tableLoading"
      >
         <el-table-column
            label="任务"
            prop="name"
            width="200"
          >
           <template slot-scope="scope">
              <span @dblclick="copyId(scope.row.id)">{{
                scope.row.name
              }}</span>
           </template>
          </el-table-column>
        <el-table-column label="任务状态" prop="status" width="85">
          <template slot-scope="scope">
              <span :class="[scope.row.status == 1 ? 'status1' : scope.row.status == 2 ? 'status2' :scope.row.status == 3 ? 'status3' : '']">{{
                scope.row.status | businessStatus
              }}</span>
          </template>
        </el-table-column>
<!--         <el-table-column prop="fileList"
            width="180"  label="文件">
            <template slot-scope="scope">
              <a
                href="#"
                style="cursor: pointer"
                v-for="(item, index) in scope.row.fileList"
                :key="index"
                @click="handleExport(item)"
              >
                <p v-if="item.originalFileName">
                  {{ index + 1 }}.{{ item.originalFileName }}
                </p>
                <p v-else>{{ index + 1 }}.{{ item.name }}</p>
              </a>
            </template>
          </el-table-column>-->
         <el-table-column
            prop="fileList"
            width="280"
            align="left"
            label="产出成果"
          >
            <template slot-scope="scope">

              <!-- 项目评估 -->
              <template v-if="scope.row.operationCode === 'B004'">
                <div v-for="(item, index) in scope.row.evaluateRecordList" :key="index" class="evaluateList">
                  <el-link
                    icon="el-icon-link"
                    @click="openEvaluateRecordTable(item.id)"
                  >
                    <p>{{item.workflowNodeName}}</p>
                  </el-link>
                </div>
                <!-- 有项目评估时隐藏项目评估按钮 -->
                <el-button
                  v-permission="['admin:project:workflow:detail:handle']"
                  v-if="!scope.row.evaluateRecordList || scope.row.evaluateRecordList.length === 0"
                  size="mini"
                  round
                  @click="taskOperation(scope.row, scope.row.operationCode)"
                >{{ scope.row.operationName }}
                </el-button>
              </template>

              <!-- 会议纪要 -->
              <template v-else-if="scope.row.operationCode === 'B006'">
                <div v-for="(item, index) in scope.row.meetingList" :key="index" class="meetingList">
                  <el-link
                    icon="el-icon-link"
                    @click="openMeetingDetailsDialog(item.id)"
                  >
                    <p>{{item.subject}}</p>
                  </el-link>
                </div>
                <!-- 有会议纪要时隐藏会议纪要按钮 -->
                <el-button
                  v-permission="['admin:project:workflow:detail:handle']"
                  v-if="!scope.row.meetingList || scope.row.meetingList.length === 0"
                  size="mini"
                  round
                  @click="taskOperation(scope.row, scope.row.operationCode)"
                >{{ scope.row.operationName }}
                </el-button>
              </template>

              <!-- 其他 -->
              <template v-else-if="scope.row.operationCode">
                <div v-for="(item, index) in scope.row.fileList" :key="index" class="fileList">
                  <el-link
                    icon="el-icon-document"
                    @click="handleExport(2, item)"
                  >
                    <p v-if="item.originalFileName">
                      {{ index + 1 }}.{{ item.originalFileName }}
                    </p>
                    <p v-else>{{ index + 1 }}.{{ item.name }}</p>
                  </el-link>
                  <i class="el-icon-close" @click="handleDeleteFile(item, scope.row)"/>
                </div>
                <!-- 其他按钮始终显示 -->
                <el-button
                  v-permission="['admin:project:workflow:detail:handle']"
                  size="mini"
                  round
                  @click="taskOperation(scope.row, scope.row.operationCode)"
                >{{ scope.row.operationName }}
                </el-button>
              </template>

            </template>
          </el-table-column>
        <el-table-column label="指派人" prop="designatorName"></el-table-column>
        <el-table-column label="任务描述" prop="description">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              title="任务描述"
              width="200"
              trigger="hover"
              :content="scope.row.description"
            >
              <span slot="reference" class="beyondDisplay1">{{
                scope.row.description
              }}</span>
            </el-popover>
          </template>
        </el-table-column>
        <!-- <el-table-column
        v-if="info.displayStatusList != 5"
        label="实际完成日期"
        prop="actualFinishTime"
        width="155"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.actualFinishTime | formatDateFilter }}</span>
        </template>
      </el-table-column> -->
        <!-- <el-table-column label="风险及应对" prop="remark"></el-table-column> -->
        <el-table-column label="计划开始日期" prop="createDate" width="165">
          <template slot-scope="scope">
            <span>{{ scope.row.planBeginTime | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="计划结束日期" prop="createDate" width="165">
          <template slot-scope="scope">
            <span>{{ scope.row.planEndTime | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" :width="operationWidth">
          <!-- <el-table-column label="操作" fixed="right"> -->
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 1"
              @click="taskStatusOperation(scope.row, '启动')"
              type="primary"
              size="mini"
              >启动</el-button
            >
            <el-button
              v-if="scope.row.status == 2"
              @click="taskStatusOperation(scope.row, '完成')"
              type="primary"
              size="mini"
              >完成</el-button
            >

             <el-popover
                trigger="hover"
                placement="top"
                width="120"
                :ref="scope.$index"
              >
                <el-button
                  v-if="
                    scope.row.status == 2 ||
                    scope.row.status == 1 ||
                    scope.row.status == 5
                  "
                  v-permission="['admin:task:cancel']"
                  @click="taskStatusOperation(scope.row, '取消')"
                  type="primary"
                  size="mini"
                  :disabled="
                    scope.row.projectWorkflowStatus == 4 ||
                    scope.row.projectWorkflowStatus == 5
                  "
                  >取消</el-button
                >

                <el-button
                  v-permission="['admin:task:delete']"
                  @click="deleteTask(scope.row, '删除')"
                  type="primary"
                  size="mini"
                  :disabled="
                    scope.row.projectWorkflowStatus == 4 ||
                    scope.row.projectWorkflowStatus == 5
                  "
                  >删除</el-button
                >
                <el-button
                  icon="el-icon-view"
                  slot="reference"
                  style="margin: 0 0 0 10px; font-size: 18px"
                  size="small"
                  type="text"
                ></el-button>
              </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageInfo.pageNum"
          :page-sizes="[10, 20]"
          :page-size="this.pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.pageInfo.total"
        ></el-pagination>
      </div>
    </el-main>

    <!-- 上传文件 -->
    <addFile
      v-if="addFileiDalogVisible"
      :addFileiDalogVisible="addFileiDalogVisible"
      :nodeName="nodeName"
      :relevantFile="fileTitle"
      :info="taskInfo"
      v-on:listenAddFileClose="listenAddFileClose"
    ></addFile>

    <!-- B002 选择投资人、填写反馈 -->
    <fillFeedback
      v-if="fillFeedbackDalogVisible"
      :fillFeedbackDalogVisible="fillFeedbackDalogVisible"
      :info="taskInfo"
      v-on:listenFillFeedbackDalogClose="listenFillFeedbackDalogClose"
    ></fillFeedback>

    <!-- B003 选择投资人 -->
    <choosingInvestors
      v-if="choosingInvestorsDalogVisible"
      :choosingInvestorsDalogVisible="choosingInvestorsDalogVisible"
      :info="taskInfo"
      v-on:listenChoosingInvestorsClose="listenChoosingInvestorsClose"
    ></choosingInvestors>
    <!-- 立项 -->
    <projectApproval
      v-if="projectApprovalDalogVisible"
      :projectApprovalDalogVisible="projectApprovalDalogVisible"
      :info="taskInfo"
      v-on:listenProjectApprovalClose="listenProjectApprovalClose"
    ></projectApproval>
    <!-- 更新任务 -->
    <addTaskEdit
      v-if="addTaskEditDalogVisible"
      :addTaskEditDalogVisible="addTaskEditDalogVisible"
      :taskOneInfo="taskInfo"
      :type="updateTaskType"
      :info="nodeInfo"
      v-on:listenAddTaskEditClose="listenAddTaskEditClose"
    ></addTaskEdit>
    <!-- 转派任务 -->
    <transferTask
      v-if="transferTaskDalogVisible"
      :transferTaskDalogVisible="transferTaskDalogVisible"
      :taskOneInfo="taskInfo"
      :info="nodeInfo"
      :type="transferTaskType"
      v-on:listenTransferTaskClose="listenTransferTaskClose"
    ></transferTask>
    <associatedSchedule
      v-if="associatedScheduleDialog"
      :associatedScheduleDialog="associatedScheduleDialog"
      :info="taskInfo"
      v-on:listenAssociatedScheduleClose="listenAssociatedScheduleClose"
    ></associatedSchedule>
    <addTask1
      v-if="add1TaskDalog"
      :add1TaskDalog="add1TaskDalog"
      :type="add1TaskType"
      :relationList="relationList"
      v-on:listenAdd1TaskClose="listenAdd1TaskClose"
    ></addTask1>

    <!-- B004 项目评估弹窗 -->
    <evaluateRecordTable
      v-if="evaluateRecordTableDialog"
      :evaluateRecordTableDialog="evaluateRecordTableDialog"
      v-on:listenEvaluateRecordTableClose="listenEvaluateRecordTableClose"
      :id="evaluateRecordId">
    </evaluateRecordTable>

    <!-- B006 会议纪要弹窗 -->
    <meetingDetails
      v-if="meetingDetailsDialog"
      :detailsDialog="meetingDetailsDialog"
      v-on:listenDetailsClose="listenMeetingDetailsClose"
      :id="meetingInfoId">
    </meetingDetails>

    <!-- 文件预览 -->
    <el-dialog
      title="文件预览"
      center
      :visible.sync="previewDialogVisible"
      @close="closePreviewDialog"
      :close-on-click-modal="false"
      style="margin-top: -5vh"
    >
      <div class="pdfUrlF scrollbar" v-if="previewPdfUrl"></div>
      <div class="imgUrl" v-if="previewImgUrl">
        <el-image ref="imgUrl" :src="previewImgUrl">
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closePreviewDialog">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
//1
import {
  cancelTask,
  pauseTask,
  deleteTask,
  getTaskInfoList,
  taskStatusEdit,
  getTaskQueryList1, handleProjectWorkflow,
} from "@/api/myMission";
import { generateTypeProject, handleListArr } from "@/api/processProgress";
import { formatDate } from "@/common/date";
import addFile from "@/components/myMission/taskOperation/addFile";
import fillFeedback from "@/components/myMission/taskOperation/fillFeedback";
import choosingInvestors from "@/components/myMission/taskOperation/choosingInvestors";
import projectApproval from "@/components/myMission/taskOperation/projectApproval";
import addTaskEdit from "@/components/myMission/addTask/addTask";
import transferTask from "@/components/myMission/myMission/addTask";
import associatedSchedule from "@/components/myMission/associatedSchedule";
import addTask1 from "@/components/myMission/addTask/addTask1";
import {copyId } from "@/common/copyId.js"
import meetingDetails from "@/components/minutesOfMeeting/meetingDetails";
import evaluateRecordTable from "@/components/myMission/evaluationList/evaluateRecordTable";
import eventBus from "@/common/eventBus";

export default {
  name: "missioTableOther",
  data() {
    return {
      taskInfoList: [],
      tableData: [],
      spanArr: [],
      formData: {},
      porjectListOptions: [],
      firstGet: true,
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      addFileiDalogVisible: false, //上传文件
      fillFeedbackDalogVisible: false, //B002
      choosingInvestorsDalogVisible: false, //B003
      projectApprovalDalogVisible: false, //B005
      addTaskEditDalogVisible: false, //更新任务
      transferTaskDalogVisible: false, //转派
      associatedScheduleDialog: false, //关联日程
      tableLoading: false,
      nodeName: null,
      fileTitle: null,
      fileTitles: null,
      detailsId: null,
      taskInfo: null, //任务信息
      updateTaskType: null,
      transferTaskType: null,
      nodeInfo: null,
      operationWidth: 270,
      commonOperations: false,
      taskId: null,
      add1TaskDalog: false,
      add1TaskType: null,
      relationList: null,
      meetingDetailsDialog: false,
      meetingInfoId: null,
      evaluateRecordTableDialog: false,
      evaluateRecordId: null,
      previewDialogVisible: false,
      previewPdfUrl: null,
      previewImgUrl: null,
    };
  },
  props: {
    type: String,
    info: {
      type: Object,
      default: () => {},
    },
    itemIndex: String,
    param: Object,
  },

  components: {
     addFile,
    fillFeedback,
    choosingInvestors,
    projectApproval,
    addTaskEdit,
    transferTask,
    associatedSchedule,
    addTask1,
    meetingDetails,
    evaluateRecordTable,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
     businessStatus(value) {
      switch (value) {
        case 0:
          return "未知";
        case 1:
          return "待启动";
        case 2:
          return "进行中";
        case 3:
          return "已完成";
        case 4:
          return "已取消";
        case 5:
          return "已暂停";
      }
    },
  },
  created() {},
  mounted() {
    eventBus.$on(['editMeetingDetail', 'deleteMeetingDetail', 'editEvaluateDetail', 'deleteEvaluateDetail'], () => {
      this.getTaskQueryList1(this.pageInfo.pageNum, this.pageInfo.pageSize);
    })
  },
  beforeDestroy() {
    eventBus.$off(['editMeetingDetail', 'deleteMeetingDetail', 'editEvaluateDetail', 'deleteEvaluateDetail']);
  },
  watch: {
    info: {
      handler(newVal, oldVal) {
        // console.log(
        //   `new: ${JSON.stringify(newVal)}, old: ${JSON.stringify(oldVal)}`
        // );
        if (newVal) {
          this.getTaskQueryList1(this.pageInfo.pageNum, this.pageInfo.pageSize);
        }
      },
      deep: true,
    },
  },
  methods: {
     copyId(id){
      copyId(id)
    },
    getTaskQueryList1(pageNum, pageSize) {

      if (!this.info.ownerId || this.info.ownerId.length === 0) {
        return;
      }

      // console.log("getTaskQueryList1, info:" + JSON.stringify(this.info));

      this.tableLoading = true;

      var one = {
        pageNum: pageNum || this.pageInfo.pageNum,
        pageSize: pageSize || this.pageInfo.pageSize,
        displayStatusList: this.info.displayStatusList,
        // pjWorkflowCondition: this.info.pjWorkflowCondition,
        ownerId: this.info.ownerId,
        taskCreateBySelf: this.info.taskCreateBySelf
      };
      getTaskQueryList1(one).then((res) => {
        if (res.success) {
          //   console.log(res.data);
          this.tableData = res.data;
          // this.getSpanArr(this.tableData);
          if (res.total != null) {
            this.pageInfo.total = res.total;
            this.pageInfo.pageCount = res.pageCount;
            this.pageInfo.pageSize = res.pageSize;
            this.pageInfo.pageNum = res.pageNum;
            this.tableLoading = false;
          } else {
            this.total = 0;
          }
        }
      });
    },
    // 关联日程
    associatedSchedule(row) {
      console.log(row);
      this.taskInfo = row;
      this.associatedScheduleDialog = true;
    },
    listenAssociatedScheduleClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.associatedScheduleDialog = !isClose;
      if (type == "sure") {
        this.getTaskQueryList1(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    showCommonOperations() {
      this.operationWidth = 380;
      this.commonOperations = !this.commonOperations;
    },
    closeCommonOperations() {
      this.operationWidth = 250;

      this.commonOperations = false;
    },
    getTaskInfoList(info) {
      this.tableLoading = true;


      getTaskInfoList(this.info).then((response) => {
        if (response.success) {
          this.taskInfoList = response.data;
          this.tableLoading = false;
        }
      });
    },
    deleteTask(row, title) {
      let that = this;
      let taskInfo = { id: row.id };

      that
        .$confirm("是否" + title + "任务[" + row.name + "]?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          console.log(taskInfo);
          deleteTask(taskInfo).then((res) => {
            if (res.success) {
              that.getTaskQueryList1(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
              that.$forceUpdate();
              that.$message({ type: "success", message: title + "成功!" });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //任务状态操作
    taskStatusOperation(row, title) {
      let that = this;
      let taskInfo = { id: row.id };
      console.log(taskInfo);
      if (title == "完成") {
        if (row.operationCode == "B001" && row.fileList == null) {
          that.$message({ type: "warning", message: "请先完成产出成果!" });
          return false;
        }
      }
      that
        .$confirm("是否" + title + "任务[" + row.name + "]?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          console.log(taskInfo);

          if (title == "暂停" || title == "恢复") {
            pauseTask(taskInfo).then((res) => {
              if (res.success) {
                that.getTaskQueryList1(
                  this.pageInfo.pageNum,
                  this.pageInfo.pageSize
                );
                that.$forceUpdate();
                that.$message({ type: "success", message: title + "成功!" });
              }
            });
          } else if (title == "取消") {
            cancelTask(taskInfo).then((res) => {
              if (res.success) {
                that.getTaskQueryList1(
                  this.pageInfo.pageNum,
                  this.pageInfo.pageSize
                );
                that.$forceUpdate();
                that.$message({ type: "success", message: title + "成功!" });
              }
            });
          } else if (title == "启动" || title == "完成") {
            let taskStatus = null;
            if (title == "启动") {
              taskStatus = 2;
            } else if (title == "完成") {
              taskStatus = 3;
            }
            let info = {
              id: row.id,
              status: taskStatus,
            };
            taskStatusEdit(info).then((res) => {
              if (res.success) {
                that.getTaskQueryList1(
                  this.pageInfo.pageNum,
                  this.pageInfo.pageSize
                );
                that.$forceUpdate();
                that.$message({ type: "success", message: title + "成功!" });
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //任务文件操作
    taskOperation(row, operationCode) {
      this.taskInfo = row;
      switch (operationCode) {
        case "B001":
          this.addFileBtn(row);
          break;
        case "B002":
          this.taskOperationB002(row);
          break;
        case "B003":
          this.taskOperationB003(row);
          break;
        case "B004":
          this.taskOperationB004(row);
          break;
        case "B005":
          this.taskOperationB005(row);
          break;
        case "B006":
          this.taskOperationB006(row);
          break;
        case "B008":
          this.taskOperationB008(row);
          break;
        case "B009":
          this.taskOperationB009(row);
          break;
      }
    },
    //B001 打开文件上传
    addFileBtn(row) {
      console.log("B001 打开文件上传");
      this.addFileiDalogVisible = true;

      //B002
      //   this.fillFeedbackDalogVisible = true;

      //B003
    },
    //监听上传文件弹窗关闭
    listenAddFileClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.addFileiDalogVisible = !isClose;
      if (type == "sure") {
        this.getTaskQueryList1(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    //B002 选择投资人、填写反馈、
    taskOperationB002(row) {
      console.log("B002 选择投资人、填写反馈、生成《投资人反馈表》");
      // this.taskInfo = row;
      this.fillFeedbackDalogVisible = true;
    },
    //监听B002
    listenFillFeedbackDalogClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.fillFeedbackDalogVisible = !isClose;
      if (type == "sure") {
        this.getTaskQueryList1(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },

    //B003 选择投资人、生成《投资人清单》
    taskOperationB003(row) {
      console.log("B002 选择B003 选择投资人、生成《投资人清单》");
      this.choosingInvestorsDalogVisible = true;
    },
    //监听B003
    listenChoosingInvestorsClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.choosingInvestorsDalogVisible = !isClose;
      if (type == "sure") {
        this.getTaskQueryList1(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },

    //B004 项目评估、生成《项目评估表》
    //通过组件传值给父组件，跳转到“项目评估”Tab，并赋给值
    taskOperationB004(row) {
      console.log("B004 项目评估、生成《项目评估表》");
      this.$emit("listenMissioTableB003", {
        type: "sure",
        isClose: true,
        info: row,
        project: this.info,
      });
    },

    //B005 项目立项、生成《项目立项表》
    taskOperationB005(row) {
      console.log("B005 项目立项、生成《项目立项表》");
      this.projectApprovalDalogVisible = true;
    },

    //
    listenProjectApprovalClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.projectApprovalDalogVisible = !isClose;
      if (type == "sure") {
        this.getTaskQueryList1(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    //B006 记录会议纪要
    taskOperationB006(row) {
      console.log("B006 记录会议纪要");
      console.log(row);
      this.$store.commit("setNoProjectTaskId", row.id);
      // this.projectApprovalDalogVisible = true;
      this.$emit("listenMissioTableB003", {
        type: "B006-2",
        isClose: true,
        noProjectTask: row.id,
      });
    },
    //B008 生成《项目保密协议》
    taskOperationB008(row) {
      console.log("B008 生成《项目保密协议》");
      this.generateProtocol(row.projectWorkflowDetailId, row.id, "保密协议");
    },

    //B009 生成《项目结项表》
    taskOperationB009(row) {
      console.log("B009 生成《项目结项表》");
      this.nextProject(row.projectWorkflowDetailId, row.id, "结项表");
    },
    //后台文档生成 结项表
    nextProject(id, taskId, title) {
      //下一步
      this.$confirm("是否生成" + title + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          generateTypeProject({ id: id, taskId: taskId }).then((res) => {
            if (res.success) {
              this.$message({ type: "success", message: "成功!" });
              this.getTaskQueryList1(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //后台文档生成协议
    generateProtocol(id, taskId, title) {
      //下一步
      this.$confirm("是否生成" + title + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          handleListArr({ id: id, taskId: taskId }).then((res) => {
            if (res.success) {
              this.$message({ type: "success", message: "成功!" });
              this.getTaskQueryList1(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    //转派任务
    transferTask(type, row) {
      console.log(row);
      this.taskInfo = row;
      this.transferTaskType = type;
      this.transferTaskDalogVisible = true;
    },
    listenTransferTaskClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.transferTaskDalogVisible = !isClose;
      if (type == "sure") {
        this.getTaskQueryList1(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    listenAdd1TaskClose(params) {
      console.log(params);
      const { type, isClose, info } = params;
      this.add1TaskDalog = !isClose;
      if (type == "sure") {
        this.getTaskQueryList1(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    //更新任务
    updateTask(type, row) {
      console.log(row);
      this.nodeInfo = { id: row.businessId };
      this.taskInfo = row;

      if (type == "update") {
        this.updateTaskType = type;
        this.addTaskEditDalogVisible = true;
      } else if (type == "other") {
        this.relationList = [
          {
            type: 1,
            targetId: row.projectWorkflowDetailId,
          },
          {
            type: 2,
            targetId: row.projectWorkflowId,
          },
          {
            type: 3,
            targetId: row.projectId,
          },
        ];
        this.add1TaskType = "add";
        this.add1TaskDalog = true;
      }
    },
    //监听任务
    listenAddTaskEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.addTaskEditDalogVisible = !isClose;
      if (type == "sure") {
        this.getTaskQueryList1(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    handleExport(type, item) {
      if (type === 1) {
        //下载文件
        var elemIF = document.createElement("iframe");
        elemIF.src = item.url;
        elemIF.href = "javascript:void(0)";
        elemIF.style.display = "none";
        document.body.appendChild(elemIF);
      } else if (type === 2) {
        let fileType = item.url.split(".")[item.url.split(".").length - 1];
        console.log('fileType: ', fileType);
        console.log('fileItem: ', item);
         this.$router.push({
          name: "officeOnLine",
          params: { file: item, url: item.url,fileType:fileType },
        });
        return false;
        if (
          fileType == "docx" ||
          fileType == "doc" ||
          fileType == "xlsx" ||
          fileType == "xls" ||
          fileType == "ppt" ||
          fileType == "pptx"
        ) {
          console.log(item.url);
          let url = encodeURIComponent(item.url);
          window.open(
            "https://view.officeapps.live.com/op/view.aspx?src=" + url
          );
        } else if (fileType == "pdf") {
          console.log(item.url);
          this.imgUrl = null;
          let origin = window.location.origin;
          let url = origin + "/pdf/web/viewer.html?file=" + item.url;
          window.open(url);
        } else if (
          fileType == "jpg" ||
          fileType == "jpeg" ||
          fileType == "png" ||
          fileType == "gif"
        ) {
          this.previewPdfUrl = null;
          this.previewImgUrl = item.url;
          this.previewDialogVisible = true;
        } else {
          this.$message({ type: "warning", message: "请下载后查看!" });
          window.open(item.url);
        }
      }
    },
    //this.tableData 指接口取到的数据
    // 2、 因为要合并的行数是不固定的，此函数是实现合并随意行数的功能
    getSpanArr(data) {
      this.spanArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          if (
            data[i].projectWorkflowDetailId ===
            data[i - 1].projectWorkflowDetailId
          ) {
            // 如果moduleName相等就累加，并且push 0  这里是根据一样的moduleName匹配
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 不相等push 1
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col,
        };
      }
    },
    //
    expandChange(row, expanded) {
      console.log(row);
      console.log(expanded);
    },

    handleSizeChange(val) {
      this.getTaskQueryList1(1, val);
    },
    handleCurrentChange(val) {
      console.log(val);
      this.pageInfo.pageNum = val;
      if (val > this.pageCount) {
        val = this.pageCount;
      }
      this.getTaskQueryList1(val, this.pageInfo.pageSize);
    },
    //打开项目评估详情弹窗
    openEvaluateRecordTable(id) {
      console.log('evaluate record id: ', id);
      this.evaluateRecordId = String(id);
      this.evaluateRecordTableDialog = true;
    },

    //监听项目评估详情弹窗关闭
    listenEvaluateRecordTableClose(params) {
      const { isClose } = params;
      this.evaluateRecordTableDialog = !isClose;
    },

    //打开会议纪要详情弹窗
    openMeetingDetailsDialog(id) {
      console.log('meeting detail id: ', id);
      this.meetingInfoId = String(id);
      this.meetingDetailsDialog = true;
    },

    //监听会议纪要详情弹窗关闭
    listenMeetingDetailsClose(params) {
      const {isClose} = params;
      this.meetingDetailsDialog = !isClose;
    },

    //删除文件
    handleDeleteFile(item, taskInfo) {
      console.log(item);
      let fileName = item.originalFileName || item.name;
      this.$confirm("确定要删除" + fileName + ", 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: false,
      }).then(() => {
        handleProjectWorkflow({
          id: taskInfo.projectWorkflowDetailId,
          taskId: taskInfo.id,
          operationCode: taskInfo.operationCode,
          deleteFiles: item
        }).then((res) => {
          console.log(res);
          if (res.success) {
            this.$message({ type: "success", message: "删除文件成功!" });
            this.getTaskQueryList1(this.pageInfo.pageNum, this.pageInfo.pageSize);
          } else {
            this.$message.error(res.message || '删除文件失败!');
          }
        });
      });
    },
    closePreviewDialog() {
      this.previewPdfUrl = null;
      this.previewImgUrl = null;
      this.numPages = 1;
      this.previewDialogVisible = false;
    },
    clear() {
       this.tableData = [];
    }
  },
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.projectFrom .el-form-item {
  /* width: 230px; */
  flex-shrink: 0;
}
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
.title {
  text-align: left;
  font-size: 1.5rem;
}

.taskList {
  margin-top: 10px;
  .projectTitle {
    margin: 5px 0;
  }
}
.fileList, .evaluateList, .meetingList {
  display: flex;
  align-items: center;

  &:last-of-type {
    margin-bottom: 3px;
  }

  &:hover {
    > i {
      display: inline-block;
    }
  }

  > i {
    display: none;
    margin-left: 3px;

    &:hover {
      cursor: pointer;
    }
  }
}
.otherButton {
  margin-left: 10px;
}
.status1{
  color:#409EFF;
}
.status2{
  color:#F56C6C;
}
.status3{
  color:#67C23A;
}
.status4{

}
.status5{

}
.pdfUrlF {
  text-align: center;
  // height: 20%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  .pdfUrl {
    width: 100%;
    text-align: center;
    // display: table-cell;
    background: transparent;
  }
}
.imgUrl {
  width: 100%;
  text-align: center;
}
</style>
