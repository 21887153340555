<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>项目转移</span>
        <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
      </div>
      <el-form
        class="projectFrom"
        :model="formData"
        ref="queryParams"
        label-width="100px"
      >
        <el-form-item label="项目名" prop="id">
          <el-select
            v-model="formData.id"
            class="childWidth"
            filterable
            placeholder="请输入关键词从项目中搜索"
            @visible-change="searchPorjectList($event)"
            clearable
            style="width: 400px"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in porjectListOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择BD" prop="belonger">
          <el-select
            v-model="formData.bdId"
            @visible-change="projectTeam($event)"
            filterable
            placeholder="请选择BD"
            clearable
            class="childWidth"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in internalUseroptionsList"
              :key="item.uid"
              :label="item.realName"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="buttonGrop">
        <el-button type="primary" @click="sure">确认</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { transferProject, projectTeam } from "@/api/projectManage";
import { queryAccountList } from "@/api/accountManage";
import { selectListMeetingProject } from "@/api/minutesOfMeeting";
export default {
  name: "transferProject",
  data() {
    return {
      formData: {},
      porjectListOptions: [],
      internalUseroptionsList: [],
    };
  },
  props: {},
  components: {},
  created() {},
  mounted() {},
  methods: {
    sure() { 
      transferProject(this.formData).then((res) => { 
        if (res.success) { 
          this.$message.success("转移成功");
          this.formData = {};
        }
      });
    },
    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },

    //
    searchPorjectList(cb) { 
      if (cb) {
        selectListMeetingProject({}).then((response) => {
          if (response.success) {
            this.porjectListOptions = response.data;
          }
        });
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.el-form{
  width: 90%;
  .el-form-item { 
  width: 100%!important;
  .el-select{
  width: 100%!important;

  }
}
}

.box-card {
  width: 48%;
}
.buttonGrop {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin: 0 20px;
    // padding: 0 10px;
  }
}
</style>
