<template>
  <el-dialog
    :title="title"
    :visible="businessProcessDetailDialog"
    width="70%"
    class="editDialogs"
    :close-on-click-modal="false"
    @close="dialogClose"
    center
    :modal-append-to-body="false"
  >
    <div v-loading="loading">
      <el-form>
        <el-row type="flex" justify="end">
          <el-form-item v-if="detail.navButton != null">
            <el-button-group class="pr-5">
              <el-button
                v-if="detail.navButton != null"
                type="primary"
                icon="el-icon-arrow-left"
                @click="changeProject('previous')"
                :disabled="detail.navButton == null"
                >上一项目</el-button
              >
              <el-button
                v-if="detail.navButton != null"
                type="primary"
                @click="changeProject('next')"
                :disabled="detail.navButton.nextId == null"
                >下一项目<i class="el-icon-arrow-right el-icon--right"></i
              ></el-button>
            </el-button-group>
            <el-button
              :type="detail.isFavorite == 1 ? '' : 'primary'"
              @click="followProject"
              >{{ detail.isFavorite == 1 ? "取消关注" : "关注" }}</el-button
            >
          </el-form-item>
        </el-row>
      </el-form>
      <el-tabs type="border-card" @tab-click="tabHandleClick">
        <el-tab-pane label="基本资料">
          <template v-if="dataAuth.baseInfo">
            <ul class="bar" style="list-style-type: none">
              <li>
                <b>
                  {{
                    detail.virtualType == "organization" ? "投资机构" : "项目名"
                  }}
                  ：</b
                >
                <span>{{ detail.projectName }}</span>
              </li>
              <li>
                <b>公司：</b>
                <span>{{ detail.principalName }}</span>
              </li>
              <li>
                <b>业务流程：</b>
                <span>{{ detail.name }}</span
                ><span v-show="detail.workflowVersion" class="version"
                  >版本{{ detail.workflowVersion }}</span
                >
              </li>
              <li>
                <b>任务推进节点：</b>
                <span class="status2">{{
                  detail.currentWorkflowDetailName
                }}</span>
              </li>

              <li v-show="detail.evaluationTplList">
                <b>评估模版：</b>
                <div
                  style="padding: 0 2px"
                  v-for="(item, index) in detail.evaluationTplList"
                  :key="index"
                >
                  <div v-if="detail.evaluationTplList.length == 1">
                    {{ item.name }}
                  </div>
                  <div v-else>{{ index + 1 }}:{{ item.name }}</div>
                </div>
              </li>
              <li>
                <b>业务负责人：</b>
                <span>{{ detail.pmName }}</span>
              </li>
              <li v-if="detail.memberList">
                <b>业务团队成员：</b>
                <div>
                  <el-row
                    style="padding: 0 2px"
                    v-for="(item, index) in detail.memberList"
                    :key="index"
                  >
                    <el-col>
                      <span>{{
                        detail.memberList.length > 1
                          ? index + 1 + ":" + item.realName
                          : item.realName
                      }}</span>
                      <el-tag
                        v-for="(item2, index2) in item.roleNameList"
                        :key="index2"
                        style="width: auto; margin-right: 2px"
                        size="mini"
                      >
                        {{ item2 }}
                      </el-tag>
                    </el-col>
                  </el-row>
                </div>
              </li>
              <li>
                <b>创建时间：</b>
                <span>{{ detail.createDate | formatDateFilter }}</span>
              </li>
              <li>
                <b>状态：</b>
                <span
                  :class="[
                    detail.status == 1
                      ? 'status1'
                      : detail.status == 2
                      ? 'status2'
                      : detail.status == 3
                      ? 'status3'
                      : detail.status == 4
                      ? 'status4'
                      : detail.status == 5
                      ? 'status5'
                      : '',
                  ]"
                  >{{ detail.status | businessStatus }}</span
                >
              </li>
              <li class="width100">
                <b>投融资需求：</b>
                <span>{{ detail.investDemand }}</span>
              </li>
              <li class="width100">
                <b>投融资进展：</b>
                <span>{{ detail.investProgress }}</span>
              </li>
              <li class="width100">
                <b>进展：</b>
                <div class="">
                  <el-progress
                    :percentage="detail.propelRate"
                    :color="customColorMethod"
                  ></el-progress>
                </div>
              </li>
            </ul>
          </template>
          <template v-else-if="dataAuth.baseInfo === false">
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply
                  name="daa-base-info"
                  :info="dataAuthInfo.baseInfo"
                />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
        <el-tab-pane label="企业工商信息">
          <template v-if="dataAuth.principalRegInfo">
            <div accordion v-show="detail.principalRegInfo != null">
              <template slot="title">
                企业工商信息<i class="header-icon el-icon-info"></i>
              </template>
              <!-- <div class="flexJfAc" v-if="detail.principalRegInfo">
                <h5>去企查查看更多信息？：</h5>
                <el-link :href="principalRegInfoNameLink" target="_blank">{{
                  detail.principalRegInfo.name
                }}</el-link>
              </div> -->
              <ul
                v-if="detail.principalRegInfo != null"
                class="bar"
                style="list-style-type: none"
              >
                <li class="width100">
                  <b>公司名：</b>
                  <span class="flexJfAc">
                    {{ detail.principalRegInfo.name }}
                    <el-link class="link" type="primary" :href="principalRegInfoNameLink" target="_blank"
                      >去企查查看更多信息？</el-link
                    >
                  </span>
                </li>
                <!-- <li>
        <b>公司ID:</b>
        {{detail.companyId}}
      </li>-->
                <li>
                  <b>法人：</b>
                  <span> {{ detail.principalRegInfo.legalPersonName }}</span>
                </li>
                <li>
                  <b>公司logo:</b>
                  <img
                    :src="detail.principalRegInfo.logo"
                    width="80px"
                    height="80px"
                    alt
                  />
                </li>

                <li>
                  <b>注册资本：</b>
                  <span>{{ detail.principalRegInfo.regCapital }}</span>
                </li>
                <li>
                  <b>注册时间：</b>
                  <span>
                    {{
                      detail.principalRegInfo.fromTime | formatDateFilter
                    }}</span
                  >

                  <!-- {{detail.fromTime}} -->
                </li>
                <li>
                  <b>统一信用代码:</b>
                  <span>{{ detail.principalRegInfo.creditCode }}</span>
                </li>
                <li>
                  <b>企业类型:</b>
                  <span> {{ detail.principalRegInfo.companyOrgType }}</span>
                </li>

                <li>
                  <b>工商注册号:</b>
                  <span> {{ detail.principalRegInfo.regNumber }}</span>
                </li>
                <li>
                  <b>组织机构代码:</b>
                  <span>{{ detail.principalRegInfo.orgNumber }}</span>
                </li>
                <li>
                  <b>营业期限:</b>
                  <span>
                    {{
                      detail.principalRegInfo.toTime | formatDateFilter
                    }}</span
                  >
                </li>
                <li>
                  <b>行业:</b>
                  <span> {{ detail.principalRegInfo.industry }}</span>
                </li>
                <li class="width100">
                  <b>经营范围:</b>
                  <span>{{ detail.principalRegInfo.businessScope }}</span>
                </li>
                <li>
                  <b>注册地址:</b>
                  <span>{{ detail.principalRegInfo.regLocation }}</span>
                </li>
                <li>
                  <b>联系电话:</b>
                  <span>{{ detail.principalRegInfo.phoneNumber }}</span>
                </li>
                <li>
                  <b>联系电话:</b>
                  <span>{{ detail.principalRegInfo.phoneNumber }}</span>
                </li>
                <li>
                  <b>轮次:</b>
                  <span> {{ detail.rounds }}</span>
                </li>
                <li>
                  <b>经营状态:</b>
                  <span>{{ detail.principalRegInfo.regStatus }}</span>
                </li>
              </ul>

              <div v-show="detail.principalOrgInfoList != null">
                <template slot="title">
                  企业工商信息<i class="header-icon el-icon-info"></i>
                </template>
                <div
                  class=""
                  v-for="(item, index) in detail.principalOrgInfoList"
                  :key="index"
                >
                  <h4>{{ index + 1 }}.{{ item.name }}</h4>
                  <ul class="bar" style="list-style-type: none">
                    <li>
                      <b>公司名：</b>
                      <span>{{ item.name }}</span>
                    </li>
                    <!-- <li>
        <b>公司ID:</b>
        {{detail.companyId}}
      </li>-->
                    <li>
                      <b>法人：</b>
                      <span> {{ item.legalPersonName }}</span>
                    </li>
                    <li>
                      <b>公司logo:</b>
                      <img :src="item.logo" width="80px" height="80px" alt />
                    </li>

                    <li>
                      <b>注册资本：</b>
                      <span>{{ item.regCapital }}</span>
                    </li>
                    <li>
                      <b>注册时间：</b>
                      <span> {{ item.fromTime | formatDateFilter }}</span>

                      <!-- {{detail.fromTime}} -->
                    </li>
                    <li>
                      <b>统一信用代码:</b>
                      <span>{{ item.creditCode }}</span>
                    </li>
                    <li>
                      <b>企业类型:</b>
                      <span> {{ item.companyOrgType }}</span>
                    </li>

                    <li>
                      <b>工商注册号:</b>
                      <span> {{ item.regNumber }}</span>
                    </li>
                    <li>
                      <b>组织机构代码:</b>
                      <span>{{ item.orgNumber }}</span>
                    </li>
                    <li>
                      <b>营业期限:</b>
                      <span> {{ item.toTime | formatDateFilter }}</span>
                    </li>
                    <li>
                      <b>行业:</b>
                      <span> {{ item.industry }}</span>
                    </li>
                    <li class="width100">
                      <b>经营范围:</b>
                      <span>{{ item.businessScope }}</span>
                    </li>
                    <li>
                      <b>注册地址:</b>
                      <span>{{ item.regLocation }}</span>
                    </li>
                    <li>
                      <b>联系电话:</b>
                      <span>{{ item.phoneNumber }}</span>
                    </li>
                    <li>
                      <b>联系电话:</b>
                      <span>{{ item.phoneNumber }}</span>
                    </li>
                    <li>
                      <b>轮次:</b>
                      <span> {{ detail.rounds }}</span>
                    </li>
                    <li>
                      <b>经营状态:</b>
                      <span>{{ item.regStatus }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="dataAuth.principalRegInfo === false">
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply
                  name="daa-principal-reg-info"
                  :info="dataAuthInfo.principalRegInfo"
                />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
        <el-tab-pane label="项目文件" @change="collapseChange">
          <template v-if="dataAuth.documentFile || dataAuth.dingTalkFile">
            <template slot="title">
              项目文件<i class="header-icon el-icon-document"></i>
            </template>
            <documentList
              v-if="documentListShow"
              :type="2"
              :projectInfo="detail2"
              :dataAuth="dataAuth"
              :dataAuthInfo="dataAuthInfo"
            ></documentList>
          </template>
          <template
            v-else-if="
              dataAuth.documentFile === false && dataAuth.dingTalkFile === false
            "
          >
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply
                  v-if="dataAuth.documentFile === false"
                  title="暂无权限查看项目文件"
                  :info="dataAuthInfo.documentFile"
                />
                <data-auth-apply
                  v-if="dataAuth.dingTalkFile === false"
                  title="暂无权限查看网盘文件"
                  :info="dataAuthInfo.dingTalkFile"
                />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
        <el-tab-pane label="任务">
          <template v-if="dataAuth.taskInfo">
            <el-table
              border
              row-key="id"
              style="width: 100%"
              :data="detail.taskList"
              @current-change="handleCurrentChange"
              :header-cell-style="{ 'text-align': 'center' }"
              :cell-style="{ 'text-align': 'center' }"
            >
              <!-- <el-table-column
          label="流程版本"
          prop="workflowVersion"
        ></el-table-column> -->
              <el-table-column
                label="流程节点"
                prop="nodeName"
              ></el-table-column>
              <el-table-column
                label="任务"
                prop="name"
                width=""
              ></el-table-column>
              <el-table-column
                label="任务负责人"
                prop="ownerName"
                width="93"
              ></el-table-column>

              <el-table-column label="任务状态" prop="status" width="78">
                <template slot-scope="scope">
                  <span
                    :class="[
                      scope.row.status == 1
                        ? 'status1'
                        : scope.row.status == 2
                        ? 'status2'
                        : scope.row.status == 3
                        ? 'status3'
                        : scope.row.status == 4
                        ? 'status4'
                        : scope.row.status == 5
                        ? 'status5'
                        : '',
                    ]"
                    >{{ scope.row.status | businessStatus }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="产出" prop="meetingList" width="260">
                <template slot-scope="scope">
                  <div class="flexFcJf">
                    <div class="flexJfAc" v-if="scope.row.evaluateRecordList">
                      <h5 class="meetingLeft">评估：</h5>
                      <div>
                        <p
                          v-for="(item, index) in scope.row.evaluateRecordList"
                          :key="index"
                        >
                          <el-link
                            type="primary"
                            @click="openContent('项目评估', item, '1')"
                            slot="reference"
                            size="mini"
                            class=""
                            >评估{{ index + 1 }}</el-link
                          >
                        </p>
                      </div>
                    </div>
                    <div class="flexJfAc" v-if="scope.row.meetingList">
                      <h5 class="meetingLeft">项目会议：</h5>
                      <div>
                        <p
                          v-for="(item, index) in scope.row.meetingList"
                          :key="index"
                        >
                          <el-link
                            type="primary"
                            @click="openContent('项目会议', item, '2')"
                            slot="reference"
                            size="mini"
                            >{{ index + 1 }}.{{ item.subject }}</el-link
                          >
                        </p>
                      </div>
                    </div>
                    <div class="flexJfAc" v-if="scope.row.fileList">
                      <h5 class="meetingLeft">文件：</h5>
                      <div>
                        <a
                          href="#"
                          style="cursor: pointer"
                          v-for="(item, index) in scope.row.fileList"
                          :key="index"
                          @click="handleExport(item)"
                        >
                          <p v-if="item.originalFileName">
                            {{ index + 1 }}.{{ item.originalFileName }}
                          </p>
                          <p v-else>{{ index + 1 }}.{{ item.name }}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <!-- <el-table-column label="项目会议" prop="progress">
            <template slot-scope="scope">
              <p v-for="(item, index) in scope.row.meetingList" :key="index">
                <el-link
                  type="primary"
                  @click="openContent('项目会议', item, '2')"
                  slot="reference"
                  size="mini"
                  >{{ item.subject }}</el-link
                >
              </p>
            </template>
          </el-table-column>
          <el-table-column label="文件" prop="fileList">
            <template slot-scope="scope">
              <a
                href="#"
                style="cursor: pointer"
                v-for="(item, index) in scope.row.fileList"
                :key="index"
                @click="handleExport(item)"
              >
                <p v-if="item.originalFileName">
                  {{ index + 1 }}.{{ item.originalFileName }}
                </p>
                <p v-else>{{ index + 1 }}.{{ item.name }}</p>
              </a>
            </template>
          </el-table-column> -->
              <el-table-column label="操作" fixed="right" width="325">
                <template slot-scope="scope">
                  <el-button
                    v-permission="['admin:task:add_instruction']"
                    type="primary"
                    size="mini"
                    @click="addTask(scope.row)"
                    class="taskButton"
                    >添加批示任务
                  </el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    class="taskButton"
                    @click="openTaskCommen(scope.row)"
                    >评论
                  </el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-connection"
                    class="taskButton"
                    @click="opeMyMission2(scope.row)"
                    >我的任务
                  </el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-connection"
                    class="taskButton"
                    @click="openTaskManagement(scope.row)"
                    >任务管理
                  </el-button>
                  <!-- <el-popover
                trigger="hover"
                placement="top"
                width="280"
                :ref="scope.$index"
              >
              
                <el-button
                  icon="el-icon-view"
                  slot="reference"
                  style="margin: 0 0 0 10px; font-size: 18px"
                  size="small"
                  type="text"
                ></el-button>
              </el-popover> -->
                </template>
              </el-table-column>
              <!-- <el-table-column label="风险及应对" prop="remark"></el-table-column>
        <el-table-column label="计划开始日期" prop="createDate" width="155">
          <template slot-scope="scope">
            <span>{{ scope.row.planBeginTime | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="计划结束日期" prop="createDate" width="155">
          <template slot-scope="scope">
            <span>{{ scope.row.planEndTime | formatDateFilter }}</span>
          </template>
        </el-table-column> -->
            </el-table>
          </template>
          <template v-else-if="dataAuth.taskInfo === false">
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply
                  name="daa-task-info"
                  :info="dataAuthInfo.taskInfo"
                />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
        <el-tab-pane label="评论">
          <div class="comment" v-if="id">
            <comment :subjectType="subjectType" :subjectId="id"></comment>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="dialogClose">确 定</el-button>
    </span>

    <!-- 评估 -->
    <el-dialog
      :title="contentDialogTitle"
      :visible.sync="contentDialog"
      width="60%"
      :before-close="handleContentClose"
      append-to-body
      center
    >
      <div class="content" v-show="contentDialogType == 2">
        <span v-html="content">{{ content }}</span>
        <!-- 评论 -->
        <el-divider content-position="center">评论</el-divider>
        <div class="comment" v-if="contentDialog">
          <comment
            :subjectType="commentMeetingType"
            :subjectId="commentMeetingId"
          ></comment>
        </div>
      </div>

      <div v-show="contentDialogType == 1">
        <div class="bar" style="text-align: left; margin-top: 20px">
          <li>
            <b>项目：</b>
            <span>{{ evaluateRecordIfno.projectName }}</span>
          </li>
          <li>
            <b>评估时点：</b>
            <span>{{ moduleScoreHeaderName }}</span>
          </li>
          <li>
            <b>流程：</b>
            <span>{{ evaluateRecordIfno.projectWorkflowName }}</span>
          </li>
          <li>
            <b>评估人：</b>
            <span>{{ evaluateRecordIfno.evaluatorName }}</span>
          </li>
          <li>
            <b>记录人：</b>
            <span>{{ evaluateRecordIfno.recorderName }}</span>
          </li>
          <li>
            <b>时间：</b>
            <span>{{ evaluateRecordIfno.beginTime | formatDateFilter }}</span>
          </li>
        </div>
        <!-- <h4 v-show="evaluateRecordIfno.projectName">
          {{ evaluateRecordIfno.projectName }}：{{
            evaluateRecordIfno.projectWorkflowName
          }}-{{ evaluateRecordIfno.templateName }}
        </h4>
        <h4 style="text-align: left; margin-top: 20px">
          {{ moduleScoreHeaderName }}
        </h4>
        <h4 style="text-align: left; margin-top: 20px">
          时间：{{ evaluateRecordIfno.beginTime | formatDateFilter }}
        </h4> -->

        <h4 style="text-align: left; margin-top: 20px">评估记录：</h4>
        <el-table
          v-loading="tableLoading"
          ref="multipleTable"
          width="100%"
          border
          :span-method="arraySpanMethod"
          :data="evaluateRecordTableData"
          style="width: 100%; margin-top: 10px"
        >
          <el-table-column label="模块" align="center" fixed="left">
            <template slot-scope="scope" width="160">
              {{ scope.row.moduleName }}
              <br />
              <span class="explain">
                {{ scope.row.moduleExplain }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="细分模块" align="center" fixed="left">
            <template slot-scope="scope" width="160">
              {{ scope.row.subModuleName }}
              <br />
              <span class="explain">
                {{ scope.row.subModuleExplain }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="评估要素"
            width="180"
            align="center"
            fixed="left"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.question }}</p>
            </template>
          </el-table-column>
          <el-table-column label="评估得分" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.score }}</p>
            </template>
          </el-table-column>
          <el-table-column label="模块评估得分" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.moduleScore }}</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="meetingContentDialog = false">取 消</el-button> -->
        <el-button type="primary" @click="contentDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <addTask1
      v-if="add1TaskDalog"
      :add1TaskDalog="add1TaskDalog"
      :type="add1TaskType"
      :relationList="relationList"
      v-on:listenAdd1TaskClose="listenAdd1TaskClose"
    ></addTask1>
    <el-dialog
      title="预览"
      append-to-body
      center
      :visible.sync="dialogVisible"
      width="70%"
    >
      <div class="pdfUrlF" v-if="pdfUrl"></div>
      <div class="imgUrl" v-if="imgUrl">
        <el-image ref="imgUrl" :src="imgUrl">
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <evaluateRecordTable
      v-if="evaluateRecordTableDialog"
      v-on:listenEvaluateRecordTableClose="listenEvaluateRecordTableClose"
      :evaluateRecordTableDialog="evaluateRecordTableDialog"
      :id="evaluateRecordId"
    >
    </evaluateRecordTable>

    <!-- 任务评论 -->
    <el-dialog
      title="任务评论"
      :visible.sync="taskCommentDialog"
      width="60%"
      :before-close="taskContentClose"
      append-to-body
    >
      <!-- 评论 -->
      <!-- <el-divider content-position="center">评论</el-divider> -->
      <div class="comment" v-if="taskCommentDialog">
        <comment :subjectType="taskSubjectType" :subjectId="taskId"></comment>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="taskCommentDialog = false">取 消</el-button> -->
        <el-button type="primary" @click="taskCommentDialog = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { formatDate } from "@/common/date";
import {
  projecTrackAllDetail,
  evaluationRecord,
  getEvaluationRecordDetail,
  projectAssignUserList,
  followProject,
  unFollowProject,
} from "@/api/projectManage";

import { editTask, taskStatusEdit } from "@/api/myMission";
import addTask1 from "@/components/myMission/addTask/addTask1";
import evaluateRecordTable from "@/components/myMission/evaluationList/evaluateRecordTable";
import documentList from "@/components/myMission/projectDocuments/documentList.vue";
import comment from "@/components/comment/comment.vue";
import DataAuthApply from "@/components/dataAuth/dataAuthApply";

export default {
  name: "detailsDialog",
  props: {
    type: String,
    businessProcessDetailDialog: Boolean,
    id: String,
    projectId: String,
    queryParams: Object,
    // projectWorkflowId:String,
  },
  data() {
    return {
      pwfId: null,
      evaluateRecordTableDialog: false,
      evaluateRecordId: null,
      detail: {},
      detail2: {},
      activeName: "first",
      loading: false,
      title: null,
      contentDialog: false,
      contentDialogTitle: null,
      content: null,
      evaluateRecordTableData: [],
      needMergeArr: ["moduleName", "subModuleName"], // 有合并项的列
      rowMergeArrs: {}, // 包含需要一个或多个合并项信息的对象
      textArr: [],
      spanArr: [],
      tableLoading: false,
      indexOf: [0],
      // pos:0,
      chnNumChar: ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"],
      chnUnitSection: ["", "万", "亿", "万亿", "亿亿"],
      chnUnitChar: ["", "十", "百", "千"],

      evaluationTimes: 0,
      contentDialogType: null,
      evaluateRecordIfno: {
        projectName: null,
      },
      scoreHeaderName: null,
      moduleScoreHeaderName: null,
      add1TaskDalog: false,
      add1TaskType: null,
      relationList: null,
      dialogVisible: false,
      pdfUrl: null,
      imgUrl: null,
      scale: 100,
      internalUseroptionsList: [],
      userId: null,
      taskStatusOptions: [
        { value: 1, label: "待启动", color: "color:#409EFF" },
        { value: 2, label: "进行中", color: "color:#F56C6C" },
        { value: 3, label: "已完成", color: "color:#67C23A" },
        { value: 4, label: "终止", color: "color:#909399" },
        { value: 5, label: "暂停", color: "color:#E6A23C" },
      ],
      documentListShow: false,
      statusSelectShow: false,
      currentRow: null,
      dataAuth: {},
      subjectType: "BUSINESS_PROCESS",
      dataAuthInfo: {
        baseInfo: {
          event: "base_info",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        principalRegInfo: {
          event: "principal_reg_info",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        documentFile: {
          event: "document_file",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        dingTalkFile: {
          event: "ding_file",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
        taskInfo: {
          event: "task_info",
          target: "t_project",
          targetId: this.projectId,
          ability: 10,
        },
      },
      commentMeetingType: "MEETING_DETAIL",
      commentMeetingId: null,
      taskId: null,
      taskSubjectType: "TASK_DETAIL",
      taskCommentDialog: false,
      principalRegInfoNameLink: null,
    };
  },
  components: {
    DataAuthApply,
    addTask1,
    evaluateRecordTable,
    documentList,
    comment,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      if (time) return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    businessStatus(value) {
      switch (value) {
        case 0:
          return "未知";
        case 1:
          return "待启动";
        case 2:
          return "进行中";
        case 3:
          return "已完成";
        case 4:
          return "终止";
        case 5:
          return "暂停";
      }
    },
  },
  computed: {},
  watch: {
    id: {
      handler(newVal, old) {
        if (newVal) {
          this.pwfId = JSON.parse(JSON.stringify(newVal));
          this.userId = window.localStorage.getItem("userId");
          this.projecTrackAllDetail();
          this.projectTeam(true);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    tabHandleClick(tab, event) {
      console.log(tab.index);
      let index = tab.index;
      if (index == 0) {
      } else if (index == 1) {
      } else if (index == 2) {
        this.documentListShow = true;
      }
    },
    changeProject(type) {
      if (type == "previous") {
        if (this.detail.navButton.prevId != null) {
          this.id = JSON.parse(JSON.stringify(this.detail.navButton.prevId));
          this.projecTrackAllDetail();
        } else {
          this.$message({ type: "info", message: ",没有上一个!" });
        }
      } else {
        if (this.detail.navButton.nextId != null) {
          this.id = JSON.parse(JSON.stringify(this.detail.navButton.nextId));
          this.projecTrackAllDetail();
        } else {
          this.$message({ type: "info", message: ",没有下一个!" });
        }
      }
    },
    followProject() {
      let that = this;
      let title = that.detail.isFavorite == 1 ? "取消关注" : "关注";
      let info = {
        refType: "project_workflow",
        refId: that.detail.id,
      };
      this.$confirm(
        "确认" + title + "业务流程 [" + that.title + "] 吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (that.detail.isFavorite == "0") {
            followProject(info).then((response) => {
              if (response.success) {
                that.$message({ type: "success", message: "关注成功!" });
                that.projecTrackAllDetail();
              } else {
                that.$message({
                  type: "info",
                  message: response.message,
                });
              }
            });
          } else if (that.detail.isFavorite == "1") {
            unFollowProject(info).then((response) => {
              if (response.success) {
                that.$message({ type: "success", message: "取消关注成功!" });
                that.projecTrackAllDetail();
              } else {
                this.$message({
                  type: "info",
                  message: response.message,
                });
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    opeMyMission2(row) {
      console.log(row);
      let status =
        row.status == 1
          ? 4
          : row.status == 2
          ? 5
          : row.status == 3
          ? 6
          : row.status == 4
          ? 6
          : row.status == 5
          ? 5
          : "";
      this.$emit("listenBusinessProcesDetailClose", {
        type: "myMission",
        isClose: true,
        info: {
          taskId: row.id,
          projectId: row.projectId,
          nodeId: row.nodeId,
          workflowId: row.workflowId,
          workflowVersion: this.detail.workflowVersion,
          projectWorkflowId: row.projectWorkflowId,
          projectWorkflowDetailId: row.projectWorkflowDetailId,
          status: status,
        },
      });
    },
    openTaskManagement(row) {
      this.$emit("listenBusinessProcesDetailClose", {
        type: "taskManagement",
        isClose: true,
        info: {
          taskId: row.id,
          projectId: row.projectId,
          projectWorkflowId: row.projectWorkflowId,
          projectWorkflowDetailId: row.projectWorkflowDetailId,
          ownerId: row.ownerId,
          status: row.status, // 0未知,1待启动,2进行中,3已完成,4终止,5暂停
        },
      });
    },
    openTaskCommen(row) {
      console.log(row);
      this.taskId = row.id;
      this.taskCommentDialog = true;
    },
    taskContentClose() {
      this.taskCommentDialog = false;
    },
    handleCurrentChange(val) {
      this.currentRow = val;
      this.statusSelectShow = false;
    },
    statusSelectChange() {
      this.statusSelectShow = !this.statusSelectShow;
    },
    customColorMethod(percentage) {
      if (percentage < 25) {
        return "#F56C6C";
      } else if (percentage < 50) {
        return "#e6a23c";
      } else if (percentage < 75) {
        return "#409EFF";
      } else {
        return "#67c23a";
      }
    },
    collapseChange(array) {
      if (array) {
        this.documentListShow = true;
      }
    },
    displayStatusListChange(value, id) {
      console.log(value);
      let info = {
        id: id,
        status: value,
      };
      taskStatusEdit(info).then((res) => {
        if (res.success) {
          this.projecTrackAllDetail();
          this.statusSelectShow = !this.statusSelectShow;
          this.$message({ type: "success", message: "任务状态编辑成功!" });
        }
      });
    },
    projectTeam(cb) {
      if (cb) {
        projectAssignUserList(this.id).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    chooseInvestor(ownerId, id) {
      var that = this;
      console.log(ownerId);
      console.log(id);
      let info = {
        id: id,
        ownerId: ownerId,
      };
      editTask(info).then((response) => {
        if (response.success) {
          this.projecTrackAllDetail();
          this.$message({ type: "success", message: "修改任务负责人成功!" });
        }
      });
    },
    listenEvaluateRecordTableClose(params) {
      const { type, isClose } = params;
      this.evaluateRecordTableDialog = !isClose;
      if (type == "sure") {
      }
    },
    addTask(row) {
      console.log(row);
      this.relationList = [
        {
          type: 1,
          targetId: row.projectWorkflowDetailId,
        },
        {
          type: 2,
          targetId: row.projectWorkflowId,
        },
        {
          type: 3,
          targetId: row.projectId,
        },
      ];
      this.add1TaskType = "add";
      this.add1TaskDalog = true;
    },
    listenAdd1TaskClose(params) {
      console.log(params);
      const { type, isClose, info } = params;
      this.add1TaskDalog = !isClose;
      if (type == "sure") {
        this.projecTrackAllDetail();
      }
    },
    openContent(title, val, type) {
      this.contentDialogTitle = title;
      this.contentDialogType = type;
      console.log(val);
      if (type == "1") {
        this.evaluateRecordIfno = val;
        this.evaluateRecordId = String(val.id);
        this.evaluateRecordTableDialog = true;
        // this.moduleScoreHeaderName =
        //   "V" +
        //   val.workflowVersion +
        //   val.projectWorkflowName +
        //   "_" +
        //   val.workflowNodeName +
        //   "_" +
        //   val.taskName;
        // let info = {};
        // // info.projectId = val.projectId;
        // // // info.projectWorkflowId = val.projectWorkflowId;
        // // info.taskId = val.taskId;
        // // info.templateId = val.templateId;
        // info.id = val.id;
        // this.getEvaluationRecordDetail(info);
      } else {
        this.content = val.content;
        this.commentMeetingId = val.id;
        this.contentDialog = true;
      }
    },
    getEvaluationRecordDetail(info) {
      console.log("data1");
      var that = this;
      that.tableLoading = true;
      that.evaluateRecordTableData = [];

      getEvaluationRecordDetail(info).then((res) => {
        if (res.success) {
          let info = res.data;
          that.evaluateRecordTableData = info.recordItemList;
          that.rowMergeArrs = that.rowMergeHandle(
            that.needMergeArr,
            that.evaluateRecordTableData
          ); // 处理数据
          that.tableLoading = false;
          that.contentDialog = true;
        }
      });
    },

    /**
     * @description 实现合并行或列
     * @param row:Object 需要合并的列name 如:'name' 'id'
     * @param column:Object 当前行的行数，由合并函数传入
     * @param rowIndex:Number 当前列的数据，由合并函数传入
     * @param columnIndex:Number 当前列的数据，由合并函数传入
     *
     * @return 函数可以返回一个包含两个元素的数组，第一个元素代表rowspan，第二个元素代表colspan。 也可以返回一个键名为rowspan和colspan的对象
     * 参考地址：https://element.eleme.cn/#/zh-CN/component/table#table-biao-ge
     */
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // console.log(column)
      // 没办法循环判断具体是那一列 所以就只好写了多个if
      if (column.label === "模块")
        return this.mergeAction("moduleName", rowIndex, column);
      if (column.label === "细分模块")
        return this.mergeAction("subModuleName", rowIndex, column);
    },
    /**
     * @description 根据数组来确定单元格是否需要合并
     * @param val:String 需要合并的列name 如:'name' 'id'
     * @param rowIndex:Number 当前行的行数，由合并函数传入
     * @param colData:Object 当前列的数据，由合并函数传入
     *
     * @return 返回值为一个数组表示该单元格是否需要合并; 说明: [0,0]表示改行被合并了 [n+,1]n为1时表示该单元格不动,n大于1时表示合并了N-1个单元格
     */
    mergeAction(val, rowIndex, colData) {
      let _row = this.rowMergeArrs[val].rowArr[rowIndex];
      let _col = _row > 0 ? 1 : 0;
      return [_row, _col];
    },
    /**
     * @description 根据数组将指定对象转化为相应的数组
     * @param arr:Array[String] 必填 必须是字符串形式的数组
     * @param data:Array 必填 需要转化的对象
     *
     * @return 返回一个对象
     * 例：rowMerge(['name','value'], [{value:'1', name:'小明'}, {value:'2', name:'小明'}, {value:'3', name:'小明'}, {value:'1', name:'小明'}, {value:'1', name:'小明'}])
     * 返回值: {
     *          name:{
     *            rowArr: [5, 0, 0, 0, 0]
     *            rowMergeNum: 0,
     *          },
     *          value: {
     *            rowArr: [1, 1, 1, 2, 0],
     *            rowMergeNum: 3
     *          }
     *        }
     */
    rowMergeHandle(arr, data) {
      if (!Array.isArray(arr) && !arr.length) return false;
      if (!Array.isArray(data) && !data.length) return false;
      let needMerge = {};
      arr.forEach((i) => {
        needMerge[i] = {
          rowArr: [],
          rowMergeNum: 0,
        };
        data.forEach((item, index) => {
          if (index === 0) {
            needMerge[i].rowArr.push(1);
            needMerge[i].rowMergeNum = 0;
          } else {
            if (item[i] === data[index - 1][i]) {
              needMerge[i].rowArr[needMerge[i].rowMergeNum] += 1;
              needMerge[i].rowArr.push(0);
            } else {
              needMerge[i].rowArr.push(1);
              needMerge[i].rowMergeNum = index;
            }
          }
        });
      });
      return needMerge;
    },
    evaluationRecord(info) {
      this.tableLoading = true;
      //评估查询
      this.evaluateRecordTableData = [];
      // this.textArr = [];
      evaluationRecord(info).then((res) => {
        if (res.success) {
          // for (var i = 0; i < res.data.evaluationTimes; i++) {
          //   this.textArr.push(i + 1);
          // }
          this.evaluateRecordTableData = res.data.recordItemList;
          // for (let i = 3; i < res.data.evaluationTimes * 2 + 3; i++) {
          //   if (i % 2 == 1) {
          //     this.indexOf.push(i);
          //   }
          // }
          // this.getSpanArr(this.evaluateRecordTableData);
          this.rowMergeArrs = this.rowMergeHandle(
            this.needMergeArr,
            this.evaluateRecordTableData
          ); // 处理数据
          this.$forceUpdate();
        }
      });
    },
    // 2、 因为要合并的行数是不固定的，此函数是实现合并随意行数的功能
    getSpanArr(data) {
      console.log("data");
      this.spanArr = [];
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          if (data[i].moduleName === data[i - 1].moduleName) {
            // 如果moduleName相等就累加，并且push 0  这里是根据一样的moduleName匹配
            console.log(data[i].moduleName);
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            // 不相等push 1
            this.spanArr.push(1);
            this.pos = i;
          }
        }
      }
      this.contentDialog = true;
      this.tableLoading = false;

      this.$forceUpdate();
    },
    // 3、合并行数
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      for (let i = 0; i < this.indexOf.length; i++) {
        if (columnIndex === this.indexOf[i]) {
          const _row = this.spanArr[rowIndex];
          const _col = _row > 0 ? 1 : 0;
          return {
            rowspan: _row,
            colspan: _col,
          };
        }
      }
    },
    handleContentClose() {
      this.contentDialog = false;
    },
    dialogClose() {
      this.$emit("listenBusinessProcesDetailClose", {
        type: "cancel",
        isClose: true,
      });
    },
    handleExport(item) {
      // let userId = window.localStorage.getItem("userId");
      // let memberList = JSON.parse(JSON.stringify(this.detail.memberList));
      // let download = memberList.some((value, index) => value.userId == userId);
      // if (download) {
      //   //下载文件
      //   var elemIF = document.createElement("iframe");
      //   elemIF.src = item.url;
      //   elemIF.style.display = "none";
      //   document.body.appendChild(elemIF);
      // } else {
      //   this.$message({ type: "warning", message: "您没有下载权限!" });
      // }
      let fileType = item.url.split(".").pop();
      console.log(fileType);
      this.$router.push({
        name: "officeOnLine",
        params: { file: item, url: item.url,fileType:fileType },
      });
      return false;

      if (
        fileType == "docx" ||
        fileType == "doc" ||
        fileType == "xlsx" ||
        fileType == "xls" ||
        fileType == "ppt" ||
        fileType == "pptx"
      ) {
        console.log(item.url);
        let url = encodeURIComponent(item.url);
        window.open("https://view.officeapps.live.com/op/view.aspx?src=" + url);
      } else if (fileType == "pdf") {
        // console.log(item.url);
        this.imgUrl = null;
        let origin = window.location.origin;
        let url = origin + "/pdf/web/viewer.html?file=" + item.url;
        window.open(url);
      } else if (
        fileType == "jpg" ||
        fileType == "jpeg" ||
        fileType == "png" ||
        fileType == "gif"
      ) {
        this.pdfUrl = null;
        this.imgUrl = item.url;
        this.dialogVisible = true;
      } else {
        this.$message({ type: "warning", message: "请下载后查看!" });
        window.open(item.url);
      }
    },
    projecTrackAllDetail() {
      this.loading = true;
      var that = this;
      let info = {
        id: this.pwfId,
        ...that.queryParams,
      };
      projecTrackAllDetail(info).then((res) => {
        if (res.success) {
          // console.log(res.data);
          this.detail = res.data;
          this.detail2 = {
            projectId: res.data.projectId,
            projectWorkflowId: res.data.id,
            projectType: res.data.projectType,
          };
          this.title =
            this.detail.projectName +
            "——" +
            this.detail.name +
            "(版本" +
            this.detail.workflowVersion +
            ")";
          this.loading = false;
          if (that.detail.principalRegInfo) {
            that.principalRegInfoNameLink =
              "https://www.qcc.com/web/search?key=" +
              that.detail.principalRegInfo.name;
          }
          this.dataAuth = res.data.projectAuthResult;
        }
      });
    },
  },
  created() {},

  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.version {
  color: #606266;
  font-size: 12px;
  margin-left: 5px;
  // align-self: flex-end;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    width: 49%;
    // min-height: 30px;
    // line-height: 50px;
    padding: 5px 0;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    b {
      min-width: 130px !important;
      text-align: right;
      align-self: baseline;
      float: left;
    }
    span {
      width: calc(100% - 130px);
      // display: inline-block;
      // width: 120px;
      // margin-left: 100px;
      text-align: left;
    }
    .childWidth {
      width: calc(100% - 130px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 130px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
    div {
      width: calc(100% - 130px);
      // float: right;
    }
  }
}
.statusSelect {
  cursor: pointer;
}
.meetingLeft {
  align-self: flex-start;
  min-width: 60px;
  text-align: right;
}
.taskButton {
  padding: 5px 5px !important;
}
.title {
  text-align: center;
  margin-bottom: 10px;
}
.name{
  margin-right: 10px;
}
.link{
  margin-left: 10px;
}
</style>
