<template>
  <div>
    <!-- 项目转移 -->
    <!-- <transferProject
      v-permission="['admin:project:transfer_project']"
    ></transferProject> -->
  </div>
</template>

<script>
import transferProject from "@/components/myMission/other/transferProject";
export default {
  name: "other",
  data() {
    return {};
  },
  props: {},
  components: {
    transferProject,
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>

<style scoped>
</style>
