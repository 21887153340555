<template>
  <div>
    <el-main>
      <el-form
        class="projectFrom"
        :model="queryParams"
        ref="queryParams"
        label-width="120px"
      >
        <el-form-item label="业务负责人" prop="pmId">
          <el-select
            v-model="queryParams.pmId"
            @visible-change="findMyProjectWorkflowMembers($event)"
            filterable
            placeholder="请选择"
            clearable
            class="childWidth"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in myProjectWorkflowMembers"
              :key="item.uid"
              :label="item.real_name"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务状态" prop="statusList">
          <el-select
            v-model="queryParams.statusList"
            class="childWidth"
            clearable
            multiple
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in projectStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <span :style="item.color">{{ item.label }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目/投资机构" prop="projectName">
          <el-input
            placeholder="请输入项目/投资机构"
            v-model="queryParams.projectName"
            class="childWidth"
            @keyup.enter.native="onProjectNamePressEnter"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="业务流程"
          class="roleCodeList"
          prop="originalWorkflowId"
        >
          <el-select
            v-model="queryParams.originalWorkflowId"
            @visible-change="technologicalList($event)"
            clearable
            placeholder="请选择"
            filterable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in technological"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="任务推进节点" prop="nodeId">
          <el-select
            v-model="queryParams.nodeId"
            filterable
            placeholder="请输入关键词从节点中搜索"
            @visible-change="findProjectLibrary($event)"
            clearable
            class="childWidth"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in projectLibraryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="isFavorite" style="width: 20% !important">
          <el-checkbox
            v-model="queryParams.isFavorite"
            :true-label="1"
            :false-label="null"
            @change="isFavoriteChange"
            >仅我关注的项目</el-checkbox
          >
        </el-form-item>
        <div style="margin-left: 80px; float: right">
          <el-button @click="resetConditions">重置</el-button>
          <el-button type="primary" @click="projecWorkflowList(1)"
            >查询</el-button
          >
          <el-button type="primary" @click="openDialog('', 'add')"
            >新增</el-button
          >
        </div>
      </el-form>

      <!-- 表格 -->
      <div class="tableF" v-if="tableData.length > 0" v-loading="loading">
        <el-table
          border
          show-overflow-tooltip="true"
          style="width: 100%"
          ref="table"
          :data="tableData"
          @sort-change="sortthiscolumn"
          :default-sort="defaultSort"
        >
          <el-table-column
            label="项目名称"
            :sortable="orderFields.hasOwnProperty('projectName') ? true : false"
            prop="projectName"
          >
            <template slot-scope="scope">
              <span @dblclick="copyPwfId(scope.row.id)">{{
                scope.row.projectName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="公司全称"
            prop="principalName"
            :sortable="
              orderFields.hasOwnProperty('principalName') ? true : false
            "
          ></el-table-column>
          <el-table-column
            label="业务流程"
            width=""
            :sortable="orderFields.hasOwnProperty('name') ? true : false"
            prop="name"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
              <span v-show="scope.row.workflowVersion" class="workflowVersion">
                版本{{ scope.row.workflowVersion }}</span
              >
            </template>
          </el-table-column>

          <el-table-column
            prop="currentWorkflowDetailName"
            label="任务推进节点"
            width=""
            :sortable="
              orderFields.hasOwnProperty('currentWorkflowDetailName')
                ? true
                : false
            "
          ></el-table-column>
          <el-table-column
            label="业务负责人"
            :sortable="orderFields.hasOwnProperty('pmName') ? true : false"
            prop="pmName"
          ></el-table-column>
          <el-table-column
            label="平均评估分"
            prop="evaluationAverageScore"
            :sortable="
              orderFields.hasOwnProperty('evaluationAverageScore')
                ? true
                : false
            "
          ></el-table-column>
          <el-table-column
            label="业务状态"
            :sortable="orderFields.hasOwnProperty('status') ? true : false"
            prop="status"
          >
            <template slot-scope="scope">
              <span
                :class="[
                  scope.row.status == 2
                    ? 'status2'
                    : scope.row.status == 3
                    ? 'status3'
                    : scope.row.status == 4
                    ? 'status4'
                    : scope.row.status == 5
                    ? 'status5'
                    : '',
                ]"
                >{{ scope.row.status | businessStatus }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="业务进度"
            prop="propelRate"
            :sortable="orderFields.hasOwnProperty('propelRate') ? true : false"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.propelRate }}%</span>
            </template>
          </el-table-column>
          <el-table-column
            label="业务暂停/终止原因"
            prop="statusChangeReason"
            align=""
            :sortable="
              orderFields.hasOwnProperty('statusChangeReason') ? true : false
            "
            width="163"
          >
            <template slot-scope="scope">
              <span v-show="scope.row.status == 5 || scope.row.status == 4">{{
                scope.row.statusChangeReason
              }}</span>
            </template>
          </el-table-column>

          <!-- <el-table-column label="项目批准（立项）时间" prop="approvalTime">
          <template slot-scope="scope">
            <span>{{ scope.row.approvalTime | formatDateFilter }}</span>
          </template>
        </el-table-column> -->
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="mini"
                @click="openDialog(scope.row, 'detail')"
                >详情</el-button
              >

              <el-popover trigger="hover" placement="top" :ref="scope.$index">
                <div style="text-align: right; margin: 0">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="openDialog(scope.row, 'edit')"
                    >编辑</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    v-if="scope.row.status == 2"
                    @click="businessProcessChange('1', scope.row, 'edit')"
                    >暂停</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    v-if="scope.row.status == 5"
                    @click="businessProcessChange('2', scope.row, 'edit')"
                    >恢复</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    v-if="scope.row.status == 2"
                    @click="businessProcessChange('3', scope.row, 'edit')"
                    >终止</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="deleteBusinessProcess(scope.row)"
                    >流程解绑</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="coypProjectCode(scope.row)"
                    class="tag-read"
                    >复制项目码</el-button
                  >
                  <el-button
                    :type="scope.row.isFavorite == 1 ? '' : 'primary'"
                    size="mini"
                    @click="followProject(scope.row)"
                    >{{
                      scope.row.isFavorite == 1 ? "取消关注" : "关注"
                    }}</el-button
                  >
                </div>
                <el-button
                  icon="el-icon-view"
                  slot="reference"
                  style="margin: 0 0 0 10px; font-size: 18px"
                  size="small"
                  type="text"
                ></el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <div class="page-info-class">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="this.pageInfo.pageNum"
            :page-sizes="[10, 20]"
            :page-size="this.pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.pageInfo.total"
          ></el-pagination>
        </div>
      </div>
      <div class="noTableData" v-else v-loading="loading"></div>

      <newWorkflow
        v-if="addBusinessProcessDialog"
        :type="businessProcessType"
        :addBusinessProcessDialog="addBusinessProcessDialog"
        v-on:listenBusinessProcesClose="listenBusinessProcesClose"
        :id="detailId"
        :info="detail"
      ></newWorkflow>
      <detailsDialog
        v-if="businessProcessDetailDialog"
        :type="businessProcessType"
        :businessProcessDetailDialog="businessProcessDetailDialog"
        v-on:listenBusinessProcesDetailClose="listenBusinessProcesDetailClose"
        :projectId="projectId"
        :id="detailId"
        :queryParams="queryParams"
      ></detailsDialog>
    </el-main>
  </div>
</template>

<script>
import {
  projecWorkflowList,
  projecWorkflowStatusEdit,
  selectTypeProject,
  deleteWorkflow,
  projectTeam,
  coypProjectCode,
  followProject,
  unFollowProject,
} from "@/api/projectManage";

import { formatDate } from "@/common/date";
import newWorkflow from "@/components/myMission/businessProcess/newWorkflow";
import detailsDialog from "@/components/myMission/businessProcess/businessProcessDetail";
import { selectListWorkflow } from "@/api/minutesOfMeeting";
import { findProjectLibrary } from "../../api/processLibrary";
// import Vue from "vue";
// import VueClipboard from "vue-clipboard2";
// Vue.use(VueClipboard);
import { copyId } from "@/common/copyId.js";
import { findMyProjectWorkflowMembers } from "@/api/myMission";
export default {
  name: "businessProcess",
  data() {
    return {
      tableData: [],
      projectId: null,
      detailId: null,
      detail: null,
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      queryParams: {
        projectName: null,
        pageSize: 10,
        pageNum: 1,
        originalWorkflowId: null,
        nodeId: null,
        pmId: null,
        statusList: [2],
        orderField: null,
        orderDirection: null,
      },
      addBusinessProcessDialog: false,
      businessProcessDetailDialog: false,
      projectInfo: null,
      businessProcessType: null,
      technological: [],
      workflowListOptions: [],
      projectLibraryList: [],
      internalUseroptionsList: [],
      projectStatusOptions: [
        {
          value: 2,
          label: "进行中",
          color: "color:#F56C6C",
        },
        {
          value: 3,
          label: "已完成",
          color: "color:#67C23A",
        },
        {
          value: 5,
          label: "已暂停",
          color: "color:#E6A23C",
        },
        {
          value: 4,
          label: "已终止",
          color: "color:#909399",
        },
      ],
      myProjectWorkflowMembers: [],
      orderFields: null,
      defaultSort: { prop: "", order: "ascending" },
      loading: false,
    };
  },
  components: {
    newWorkflow,
    detailsDialog,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    businessStatus(value) {
      switch (value) {
        case 0:
          return "未知";
        case 1:
          return "待启动";
        case 2:
          return "进行中";
        case 3:
          return "已完成";
        case 4:
          return "已终止";
        case 5:
          return "已暂停";
      }
    },
  },
  props: {
    tabIndex: String,
  },
  watch: {
    tabIndex(newVal, old) {
      console.log("tabIndex" + newVal);
      if (newVal == "1") {
        this.projecWorkflowList(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
  },
  created() {},
  mounted() {
    this.projecWorkflowList(1, this.pageInfo.pageSize);
    this.queryParams.pmId = window.localStorage.getItem("userId");
    this.findMyProjectWorkflowMembers(true);
  },
  //keep-alive的生命周期 ——进入组件
  activated() {},
  //keep-alive的生命周期 ——离开组件
  deactivated() {
    this.projecWorkflowList(1, this.pageInfo.pageSize);
  },
  methods: {
    isFavoriteChange() {
      this.$forceUpdate();
    },
    followProject(row) {
      let that = this;
      let title = row.isFavorite == 1 ? "取消关注" : "关注";
      let info = {
        refType: "project_workflow",
        refId: row.id,
      };
      this.$confirm(
        "确认" +
          title +
          "业务流程 [" +
          row.projectName +
          "——" +
          row.name +
          "(版本" +
          row.workflowVersion +
          ")" +
          "] 吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (row.isFavorite == "0") {
            followProject(info).then((response) => {
              if (response.success) {
                that.$message({ type: "success", message: "关注成功!" });
                that.projecWorkflowList(
                  that.pageInfo.pageNum,
                  that.pageInfo.pageSize
                );
              } else {
                that.$message({
                  type: "info",
                  message: response.message,
                });
              }
            });
          } else if (row.isFavorite == "1") {
            unFollowProject(info).then((response) => {
              if (response.success) {
                that.$message({ type: "success", message: "取消关注成功!" });
                that.projecWorkflowList(
                  that.pageInfo.pageNum,
                  that.pageInfo.pageSize
                );
              } else {
                this.$message({
                  type: "info",
                  message: response.message,
                });
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    sortthiscolumn(column) {
      //点击排序后 排序的内容  // 排序 ，会获得当前排序的prop   ['ascending', 'descending']
      console.log(column);
      console.log(this.orderFields.hasOwnProperty(column.prop));

      let orderDirection;
      if (column.order) {
        if (
          this.orderFields.hasOwnProperty(column.prop) &&
          column.order == "ascending"
        ) {
          // asc
          orderDirection = "asc";
          console.log(column.prop + "---asc");
          console.log(this.orderFields[column.prop]);
        } else {
          //desc
          orderDirection = "desc";
          console.log(column.prop + "---desc");
        }
        this.queryParams.orderField = this.orderFields[column.prop];
        this.queryParams.orderDirection = orderDirection;
      } else {
        this.queryParams.orderField = null;
        this.queryParams.orderDirection = null;
      }
      this.tableData = [];
      this.projecWorkflowList(1, this.pageInfo.pageSize);
    },

    //复制id
    copyPwfId(id) {
      // var that = this;
      // this.$copyText(id).then(
      //   function (e) {
      //     // that.$message({ type: "success", message: "项目码:" + e.text + " 复制成功!" });
      //     console.log(e);
      //   },
      //   function (e) {
      //     // that.$message({ type: "success", message: "项目码复制失败!" });
      //     console.log(e);
      //   }
      // );
      copyId(id);
    },
    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    findProjectLibrary(cb) {
      let param = {};
      param.workflowId = this.queryParams.originalWorkflowId;
      if (cb) {
        findProjectLibrary(param).then((res) => {
          if (res.success) {
            this.projectLibraryList = res.data;
          }
        });
      }
    },
    technologicalList(cb) {
      var that = this;
      if (cb) {
        //流程列表
        let param = {};
        selectTypeProject(param).then((res) => {
          if (res.success) {
            let technological = res.data;
            that.technological = technological;
          }
        });
      }
    },
    searchWorkflowList(cb) {
      var that = this;
      let param = {};
      //   param.nodeName = null;
      param.workflowId = that.queryParams.originalWorkflowId;
      //   param.projectId = that.formData.projectId;
      if (cb) {
        selectListWorkflow(param).then((response) => {
          if (response.success) {
            that.workflowListOptions = response.data;
          }
        });
      }
    },
    listenBusinessProcesClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.addBusinessProcessDialog = !isClose;
      if (type == "sure") {
        this.projecWorkflowList(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },
    listenBusinessProcesDetailClose(params) {
      console.log(params);
      const { type, isClose, info } = params;
      if (type == "cancel") {
      } else if (type == "myMission") {
        console.log(info);
      } else if (type == "taskManagement") {
        console.log(info);
      }
      this.$emit("listenBusinessProcess", {
        type: type,
        info: info,
      });
      this.businessProcessDetailDialog = !isClose;
    },
    selectProjecStatus(cb) {
      if (cb) {
        selectProjecStatus({}).then((response) => {
          if (response.success) {
            this.porjectStatusListOptions = response.data;
          }
        });
      }
    },

    resetConditions() {
      this.queryParams.projectName = null;
      this.queryParams.originalWorkflowId = null;
      this.queryParams.nodeId = null;
      this.queryParams.pmId = null;
      this.queryParams.statusList = null;
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.queryParams.orderField = null;
      this.queryParams.orderDirection = null;
      this.queryParams.isFavorite = null;
      this.$refs.table.clearSort();
      this.$forceUpdate();
      this.projecWorkflowList(this.pageInfo.pageNum, this.pageInfo.pageSize);
    },
    projecWorkflowList(num, pageSize) {
      this.loading = true;
      //项目库列表
      this.queryParams.pageNum = num;
      this.queryParams.pageSize = pageSize;
      projecWorkflowList(this.queryParams)
        .then((response) => {
          let orderFields = response.orderFields;
          let tableData = response.data;
          if (tableData) {
            this.tableData = tableData;
          } else {
            this.loading = false;
          }
          if (orderFields) {
            this.orderFields = JSON.parse(JSON.stringify(orderFields));
          }
          if (response.total != null) {
            this.pageInfo.pageNum = response.pageNum;
            this.pageInfo.pageSize = response.pageSize;
            this.pageInfo.total = response.total;
            this.pageInfo.pageCount = response.pageCount;
          } else {
            this.pageInfo.total = 0;
          }
          this.loading = false;

          this.$nextTick(() => {
            this.$refs.table.doLayout();
          });
        })
        .catch(() => {});
    },

    handleSizeChange(val) {
      console.log(val);
      this.projecWorkflowList(1, val);
    },
    handleCurrentChange(val) {
      console.log(val);
      if (val > this.pageInfo.pageCount) {
        val = this.pageInfo.pageCount;
      }
      this.projecWorkflowList(val, this.pageInfo.pageSize);
    },
    openDialog(val, type) {
      console.log(val);
      this.businessProcessType = type;
      if (val) {
        this.projectId = val.projectId;
        this.detailId = val.id;
        this.detail = val;
      } else {
        this.projectId = null;
        this.detailId = null;
        this.detail = null;
      }
      if (type == "add") {
        this.addBusinessProcessDialog = true;
      } else if (type == "edit") {
        this.addBusinessProcessDialog = true;
      } else if (type == "detail") {
        // this.businessProcessDetailDialog = true;
        this.$router.push({
          name: "businessProcessDetailTab",
          query: { id: val.id,projectId:val.projectId, },
          params:{...this.queryParams}
        });
      }
    },
    businessProcessChange(type, val) {
      let msg = "";
      let status = "";
      if (type == 1) {
        status = 5;
        msg = "暂停";
      } else if (type == 2) {
        status = 2;
        msg = "恢复";
      } else if (type == 3) {
        status = 4;
        msg = "终止";
      }
      let title = "确认业务流程" + msg;
      this.$prompt("请输入原因", title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          let info = {
            status: status,
            id: val.id,
            statusChangeReason: value,
          };
          projecWorkflowStatusEdit(info).then((response) => {
            if (response.success) {
              this.$message({ type: "success", message: msg + "成功!" });
              this.projecWorkflowList(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    doCopy(data) {
      var that = this;
      this.$copyText(data).then(
        function (e) {
          that.$message({
            type: "success",
            message: "项目码:" + e.text + " 复制成功!",
          });
          console.log(e);
        },
        function (e) {
          that.$message({ type: "success", message: "项目码复制失败!" });
          console.log(e);
        }
      );
    },
    coypProjectCode(data) {
      if (!data.projectCode) {
        coypProjectCode(data.projectId).then((response) => {
          if (response.success) {
            this.projecWorkflowList(
              this.pageInfo.pageNum,
              this.pageInfo.pageSize
            );
            this.$forceUpdate();
            this.doCopy(response.data.code);
          } else {
            this.$message({
              type: "info",
              message: response.message,
            });
          }
        });
      } else {
        this.doCopy(data.projectCode);
      }
    },
    deleteBusinessProcess(data) {
      this.$confirm(
        "确认解绑业务流程 [" +
          data.projectName +
          "--" +
          data.name +
          data.workflowVersion +
          "] 吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          deleteWorkflow(data.id).then((response) => {
            if (response.success) {
              this.$message({ type: "success", message: "流程解绑成功!" });

              this.projecWorkflowList(
                this.pageInfo.pageNum,
                this.pageInfo.pageSize
              );
            } else {
              this.$message({
                type: "info",
                message: response.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    //查找我参与项目的成员，如果我是PM则可以看到所有成员，否则只能看到自己
    findMyProjectWorkflowMembers(show, row) {
      if (!show) return;

      let param = {};
      findMyProjectWorkflowMembers(param).then((res) => {
        if (res.success) {
          this.myProjectWorkflowMembers = res.data;
        }
      });
    },
    onProjectNamePressEnter() {
      this.projecWorkflowList(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.projectFrom {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }
  .el-form-item {
    text-align: left;

    // display: flex;
    width: 48% !important;
    // float: left;
    min-height: 1rem;
    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}
// .projectFrom {
//   display: flex;
//   width: 100%;
//   flex-wrap: wrap;
// }
// .projectFrom .el-form-item {
//   width: 50%;
//   flex-shrink: 0;
// }
.editDialogs >>> .el-dialog__body {
  text-align: left;
}
.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
.el-dialog__footer {
  /* margin-top: 100px; */
  text-align: center !important;
}
.workflowVersion {
  color: #ccc;
  font-size: 12px;
}

.status2 {
  color: #f56c6c;
}
.status3 {
  color: #67c23a;
}
.status4 {
  color: #909399;
}
.status5 {
  color: #e6a23c;
}
.el-table {
  // min-height: 45rem;
}
.el-table {
  th.gutter,
  colgroup.gutter {
    width: 5px !important; //此处的宽度值，对应你自定义滚动条的宽度即可
  }
}

// 关键css代码
.el-table__header colgroup col[name="gutter"] {
  display: table-cell !important;
}

.el-table th.gutter {
  display: table-cell !important;
}

.el-table colgroup.gutter {
  display: table-cell !important;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.noTableData {
  width: 100%;
  height: 500px;
}
</style>
