<template>
  <el-main>
    <el-tabs
      v-model="activeName"
      type="border-card"
      lazy
      @tab-click="handleClick"
    >
      <!-- 业务进度表 -->
      <el-tab-pane 
        name="second"
        label="业务进度表"
        v-permission="['my_mission:new_project_process']"
      >
        <div class="">
          <businessProcess
            :tabIndex="tabIndex"
            key=""
            v-on:listenBusinessProcess="listenBusinessProcess"
          ></businessProcess>
        </div>
      </el-tab-pane>

      <!-- 我的任务 -->
      <el-tab-pane
        name="first" 
        v-permission="['my_mission:my_task']"
      >
        <span slot="label"><i class="el-icon-date"></i> 我的任务</span>
        <myMission2
          v-on:listenMissioTableB003="listenMissioTableB003"
          :tabIndex="tabIndex"
          :nodeInfo="nodeInfo"
          :jumpType="jumpType"
        ></myMission2>
      </el-tab-pane>

      <!-- 任务管理 -->
      <el-tab-pane 
        name="eight"
        label="任务管理"
        v-permission="['my_mission:task_management']"
      >
        <div>
          <taskManagement :tabindex="tabIndex" :taskManagementInfo="taskManagementInfo"></taskManagement>
        </div>
      </el-tab-pane>

      <!-- <el-tab-pane
        name="second"
        label="项目任务分配"
        v-permission="['my_mission:task_allocation']"
      >
        <div class="show" v-if="tabIndexList[tabIndex].show">
          <addTask></addTask>
        </div>
      </el-tab-pane> -->

      <!-- 项目评估 -->
      <el-tab-pane 
        name="third"
        label="项目评估"
        v-permission="['my_mission:project_evaluation']"
      >
        <assessment
          :project="project"
          :projectTaskInfo="projectTaskInfo"
          :jumpBack.sync="jumpBack"
          v-on:listenJumpBack="listenJumpBack"
        ></assessment>
      </el-tab-pane>

      <!-- 评估列表 -->
      <el-tab-pane 
        name="fourth"
        label="评估列表"
        v-permission="['my_mission:project_evaluation']"
      >
        <evaluationList
          :tabIndex="tabIndex"
          :project="project"
          :projectTaskInfo="projectTaskInfo"
        ></evaluationList>
      </el-tab-pane>

      <!-- 会议纪要记录 -->
      <el-tab-pane 
        name="sixth"
        label="会议纪要记录"
        v-permission="['my_mission:minutes_of_meeting']"
      >
        <minutesOfMeeting
          v-if="tabIndexList[tabIndex].show"
          :noProjectTaskId="noProjectTaskId"
          :noProjectTaskId2="noProjectTaskId2"
          :tabIndex="tabIndex"
          :thread-id="meetingListThreadId"
          :options="meetingListOptions"
          :jumpBack.sync="jumpBack"
          v-on:listenJumpBack="listenJumpBack"
        ></minutesOfMeeting>
      </el-tab-pane>
      <!-- <el-tab-pane name="sixth" label="其他" v-permission="['my_mission:other']">
        <other v-if="tabIndexList[tabIndex].show"></other>
      </el-tab-pane> -->

      <!-- 项目文件 -->
      <el-tab-pane 
        v-permission="['my_mission:project_documents']"
        name="seven"
        label="项目文件"
      >
        <projectDocuments :tabIndex="tabIndex"></projectDocuments>
      </el-tab-pane>
    </el-tabs>
  </el-main>
</template>

<script>
import edit from "../components/myMission/edit";
import assessment from "../components/myMission/assessment";
import evaluationList from "../components/myMission/evaluationList";
import myMission2 from "../components/myMission/myMission";
import addTask from "../components/myMission/addTask";
import minutesOfMeeting from "@/views/minutesOfMeeting";
import other from "../components/myMission/other";
import businessProcess from "@/components/myMission/businessProcess";
import projectDocuments from "@/components/myMission/projectDocuments";
import { selectListMeetingProject } from "@/api/minutesOfMeeting";
import taskManagement from "@/components/myMission/taskManagement/taskManagement";
import eventBus from "@/common/eventBus";

export default {
  name: "myMission",
  data() {
    return {
      activeName: "second",
      editDialog: false, //打开编辑
      businessType: 3, //会议类型：0 默认，1 项目流程节点会议，2 投资人会议 3//任务
      meetingInfoId: null,
      tabIndex: "0",
      tabNameList: [
        "first",
        "second",
        "third",
        "fourth",
        "fifth", // 会议纪要新增，已被删除
        "sixth",
        "seven",
        "eight", // 任务管理
      ],
      tabIndexList: [
        {
          show: true,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
        {
          show: false,
        },
      ],
      projectTaskInfo: null,
      project: null,
      porjectListOptions: null,
      noProjectTaskId: null,
      noProjectTaskId2: 1,
      meetingThreadId: 1, // 会议纪要线程id，用于控制会议纪要组件重新初始化
      meetingOptions: { type: "add" }, // 会议纪要的传入参数

      meetingListThreadId: 1,
      meetingListOptions: {},
      jumpBack: null,
      nodeInfo: null,
      taskManagementInfo:null,
      jumpType:null,
    };
  },
  components: {
    edit,
    assessment,
    evaluationList,
    myMission2,
    addTask,
    minutesOfMeeting,
    other,
    businessProcess,
    projectDocuments,
    taskManagement,
  },
  created() {
    console.log(this.$route.query);
    if (this.$route.query.projectId) {
      this.searchPorjectList();
    }
  },
  activated() {},
  mounted() {
    if (this.$route.query.tab == "myMission") {
      console.log("this.$route.query");
      this.activeName = "first";
      this.tabIndex = "1";

      this.$forceUpdate();
    }
    const newMeeting = jstool.getParam("new_meeting") * 1;
    if (newMeeting === 1) {
      const scheduleId = jstool.getParam("schedule_id");
      const extId = jstool.getParam("ext_id");
      const extType = jstool.getParam("ext_type");
      this.listenNewMeeting({
        meetingType: "schedule",
        scheduleId: scheduleId,
        extId: extId,
        extType: extType,
      });
    }
  },
  watch: {
    $route: function (newVal, oldVal) {
      if (newVal) {
        console.log(this.$route.query);
        if (this.$route.query.tab == "myMission") {
          console.log("this.$route.query");

          this.activeName = "first";
          this.tabIndex = "1";
          this.$forceUpdate();
        }
      }
    },
  },
  methods: {
    listenBusinessProcess(params) {
      console.log(params);
      const { type, info } = params;
      if (type == "sure") {
      } else if (type == "myMission") {
        this.nodeInfo = info;
        this.activeName = "first"
        this.tabIndex = "1";
      } else if (type == "taskManagement") {
        this.taskManagementInfo = info;
        this.activeName = "eight"
        this.tabIndex = "2";
      }
    },
    searchPorjectList() {
      var that = this;
      selectListMeetingProject({}).then((response) => {
        if (response.success) {
          let porjectListOptions = response.data;
          porjectListOptions.forEach(function (item, index) {
            if (that.$route.query.projectId == item.id) {
              console.log(item);
              let paramsInfo = {
                id: item.id,
                projectName: item.projectName,
                projectWorkflowId: that.$route.query.projectWorkflowId,
                projectWorkflowDetailId:
                  that.$route.query.projectWorkflowDetailId,
              };
              that.project = paramsInfo;
              that.tabIndex = 3;
              that.activeName = "fourth";
            }
          });
        }
      });
    },
    listenJumpBack(params) {
      var that = this;
      console.log(params);
      if (
        params.jumpBack == "minutesOfMeeting" ||
        params.jumpBack == "assessment"
      ) {
        that.activeName = "first";
        that.jumpType = params.jumpBack;
      }
    },
    listenMissioTableB003(params) {
      console.log(params);
      const { type, isClose, info, project, noProjectTaskId, jumpBack } =
        params;
      if (type == "sure") {
        // console.log(project);
        // console.log(info);

        this.jumpBack = jumpBack;
        this.projectTaskInfo = params.info;
        let paramsInfo = {
          id: params.info.projectId,
          projectName: params.info.projectName,
          projectWorkflowId: params.info.workflowId,
          projectWorkflowDetailId: params.info.projectWorkflowDetailId,
          templateId: params.info.templateId,
          taskId: params.info.id,
        };
        this.project = paramsInfo;
        this.activeName = "third";
      } else if (type == "B006") {
        this.jumpBack = jumpBack;

        let meetingOptions = {
          type: "add",
          meetingType: "project",
          projectId: params.info.projectId,
          projectWorkflowDetailId: params.info.projectWorkflowDetailId,
          taskId: params.info.id,
        };
        // this.meetingThreadId++;
        // this.tabIndex = "4";
        // this.activeName = 'fifth';

        this.listenNewMeeting(meetingOptions);
      } else if (type == "B006-2") {
        this.jumpBack = jumpBack;
        let meetingOptions = {
          type: "add",
          meetingType: "invest",
          investorTaskId: params.noProjectTask,
        };
        // this.meetingThreadId++;
        // this.tabIndex = "4";
        // this.activeName = 'fifth';

        this.listenNewMeeting(meetingOptions);
      }
    },
    handleClick(tab, event) {
      console.log(tab);
      let index = this.tabNameList.indexOf(tab.name);
      this.tabIndexList[index].show = true;
      this.tabIndex = String(index);
    },

    listenNewMeeting(info) {
      let meetingOption = {
        type: "add",
        ...info,
      };

      if (!meetingOption.meetingType) {
        meetingOption.meetingType = "schedule";
      }

      this.tabIndex = "5";
      this.activeName = "sixth";
      this.tabIndexList[5].show = true;

      this.meetingListOptions = {
        type: "new_meeting",
        meetingOptions: meetingOption,
      };
      this.meetingListThreadId++;
    },
    //
    // listenEditMeeting(row) {
    //   console.info(row);
    //   this.meetingOptions = {
    //     type: 'edit',
    //     meetingId: row.id
    //   }
    //   this.meetingThreadId++;
    //   this.tabIndex = "4";
    //   this.activeName = 'fifth';
    // }

    //监听编辑弹窗关闭
    // listenEditClose(params) {
    //   console.log(params);
    //   const { type, isClose } = params;
    //   this.editDialog = !isClose;
    //   if (type == "sure") {
    //     this.queryMeetingList(this.pageNum, this.pageSize);
    //   }
    // },
  },
};
</script>

<style lang="sass" scoped>
</style>
