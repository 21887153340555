<template>
  <div>
    <el-main>
      <el-row type="flex" class="title">
        <el-col justify="start">跟踪视图: - 任务执行情况一览</el-col>
      </el-row>

      <!-- 表格 -->
      <el-table
        border
        row-key="id"
        style="width: 100%"
        :data="tableData"
        v-loading="tableLoading"
      >
        <el-table-column label="业务进度表" prop="projectName" width="200">
          <template slot-scope="scope">
            <span
              >{{ scope.row.projectName }} -
              {{ scope.row.projectWorkflowName }}</span
            >
          </template>
        </el-table-column>

        <el-table-column label="流程节点" prop="nodeName" width="150">
        </el-table-column>

        <el-table-column label="任务" prop="name" width="150">
          <template slot-scope="scope">
            <span @dblclick="copyId(scope.row.id)">{{ scope.row.name }}</span>
          </template>
        </el-table-column>

        <el-table-column label="任务描述" prop="description" width="200">
        </el-table-column>

        <el-table-column label="任务负责人" prop="ownerName" width="150">
        </el-table-column>

        <el-table-column label="任务状态" prop="status" width="150">
          <template slot-scope="scope">
            <span
              class="statusSelect"
              :class="[
                scope.row.status == 1
                  ? 'status1'
                  : scope.row.status == 2
                  ? 'status2'
                  : scope.row.status == 3
                  ? 'status3'
                  : scope.row.status == 4
                  ? 'status4'
                  : scope.row.status == 5
                  ? 'status5'
                  : '',
              ]"
              >{{ scope.row.status | businessStatus }}</span
            >
          </template>
        </el-table-column>

        <el-table-column
          prop="fileList"
          width="280"
          align="left"
          label="产出成果"
        >
          <template slot-scope="scope">
            <!-- 项目评估 -->
            <template v-if="scope.row.operationCode === 'B004'">
              <div
                v-for="(item, index) in scope.row.evaluateRecordList"
                :key="index"
                class="evaluateList"
              >
                <el-link
                  icon="el-icon-link"
                  @click="openEvaluateRecordTable(item.id)"
                >
                  <p>{{ item.workflowNodeName }}</p>
                </el-link>
              </div>
            </template>

            <!-- 会议纪要 -->
            <template v-else-if="scope.row.operationCode === 'B006'">
              <div
                v-for="(item, index) in scope.row.meetingList"
                :key="index"
                class="meetingList"
              >
                <el-link
                  icon="el-icon-link"
                  @click="openMeetingDetailsDialog(item.id)"
                >
                  <p>{{ item.subject }}</p>
                </el-link>
              </div>
            </template>

            <!-- 其他 -->
            <template v-else-if="scope.row.operationCode">
              <div
                v-for="(item, index) in scope.row.fileList"
                :key="index"
                class="fileList"
              >
                <el-link icon="el-icon-document" @click="handleExport(2, item)">
                  <p v-if="item.originalFileName">
                    {{ index + 1 }}.{{ item.originalFileName }}
                  </p>
                  <p v-else>{{ index + 1 }}.{{ item.name }}</p>
                </el-link>
              </div>
            </template>
          </template>
        </el-table-column>

        <el-table-column
          label="操作"
          fixed="right"
          width="130"
          style="text-align: left"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="addTask(scope.row, 'detail')"
              >查看
            </el-button>

            <el-popover trigger="hover" placement="top" :ref="scope.$index">
              <el-button
                v-permission="['admin:task:add_instruction']"
                type="primary"
                size="mini"
                @click="addTask(scope.row, 'add')"
                >添加批示任务
              </el-button>

              <el-button
                icon="el-icon-view"
                slot="reference"
                style="margin: 0 0 0 10px; font-size: 18px"
                size="small"
                type="text"
              >
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageInfo.pageNum"
          :page-sizes="[10, 20]"
          :page-size="this.pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.pageInfo.total"
        ></el-pagination>
      </div>
    </el-main>

    <addTask1
      v-if="add1TaskDalog"
      :add1TaskDalog="add1TaskDalog"
      :type="add1TaskType"
      :relationList="relationList"
      :info="add1TaskDetail"
      v-on:listenAdd1TaskClose="listenAdd1TaskClose"
    ></addTask1>

    <!-- B004 项目评估详情弹窗 -->
    <evaluateRecordTable
      v-if="evaluateRecordTableDialog"
      :evaluateRecordTableDialog="evaluateRecordTableDialog"
      v-on:listenEvaluateRecordTableClose="listenEvaluateRecordTableClose"
      :id="evaluateRecordId"
    >
    </evaluateRecordTable>

    <!-- B006 会议纪要详情弹窗 -->
    <meetingDetails
      v-if="meetingDetailsDialog"
      :detailsDialog="meetingDetailsDialog"
      v-on:listenDetailsClose="listenMeetingDetailsClose"
      :id="meetingInfoId"
    >
    </meetingDetails>

    <!-- 文件预览 -->
    <el-dialog
      title="文件预览"
      center
      :visible.sync="previewDialogVisible"
      @close="closePreviewDialog"
      :close-on-click-modal="false"
      style="margin-top: -5vh"
    >
      <div class="pdfUrlF scrollbar" v-if="previewPdfUrl"></div>
      <div class="imgUrl" v-if="previewImgUrl">
        <el-image ref="imgUrl" :src="previewImgUrl">
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closePreviewDialog">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  editTask,
  taskStatusEdit,
  getTaskManagementPagingList,
} from "@/api/myMission";
import { formatDate } from "@/common/date";
import addTask1 from "@/components/myMission/addTask/addTask1";
import { projectAssignUserList } from "@/api/projectManage";
import { copyId } from "@/common/copyId";
import meetingDetails from "@/components/minutesOfMeeting/meetingDetails";
import evaluateRecordTable from "@/components/myMission/evaluationList/evaluateRecordTable";
import eventBus from "@/common/eventBus";

export default {
  name: "taskPlanViewTable",
  data() {
    return {
      userId: null,
      tableData: [],
      spanArr: [],
      formData: {},
      projectListOptions: [],
      firstGet: true,
      total: 0,
      pageCount: 1,
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      addTaskEditDalogVisible: false, //更新任务
      transferTaskDalogVisible: false, //转派
      tableLoading: false,
      nodeName: null,
      fileTitle: null,
      fileTitles: null,
      detailsId: null,
      taskInfo: null, //任务信息
      updateTaskType: null,
      transferTaskType: null,
      nodeInfo: null,
      operationWidth: 390,
      commonOperations: false,
      taskId: null,
      add1TaskDalog: false,
      add1TaskType: null,
      relationList: null,
      add1TaskDetail: {},
      meetingDetailsDialog: false,
      meetingInfoId: null,
      evaluateRecordTableDialog: false,
      evaluateRecordId: null,
      taskDetailDialog: false,
      taskDetailType: null,
      taskDetailId: null,
      taskDetail: {},
      internalUseroptionsList: [],
      statusSelectShow: false,
      taskStatusOptions: [
        {
          value: 1,
          label: "待启动",
          color: "color:#409EFF",
        },
        {
          value: 2,
          label: "进行中",
          color: "color:#F56C6C",
        },
        {
          value: 3,
          label: "已完成",
          color: "color:#67C23A",
        },
        {
          value: 4,
          label: "终止",
          color: "color:#909399",
        },
        {
          value: 5,
          label: "暂停",
          color: "color:#E6A23C",
        },
      ],
      previewPdfUrl: null,
      previewImgUrl: null,
      previewDialogVisible: null,
    };
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    taskList: {
      type: Array,
    },
    pageInfo1: {
      type: Object,
    },
    pageCount1: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },

  components: {
    addTask1,
    meetingDetails,
    evaluateRecordTable,
  },
  watch: {
    taskList: {
      handler(newVal, old) {
        let that = this;
        if (newVal) {
          that.tableData = newVal;
        }
      },
      immediate: true,
      deep: true,
    },
    pageInfo1: {
      handler(newVal, old) {
        let that = this;
        if (newVal) {
          that.pageInfo = newVal;
        }
      },
      immediate: true,
      deep: true,
    },
    pageCount: {
      handler(newVal, old) {
        let that = this;
        if (newVal) {
          that.pageCount = newVal;
        }
      },
      immediate: true,
      deep: true,
    },
     loading(newVal,old){
      this.tableLoading = newVal
    }
    
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    businessStatus(value) {
      switch (value) {
        case 0:
          return "未知";
        case 1:
          return "待启动";
        case 2:
          return "进行中";
        case 3:
          return "已完成";
        case 4:
          return "终止";
        case 5:
          return "暂停";
      }
    },
  },
  mounted() {
    this.userId = window.localStorage.getItem("userId");
    this.projectTeam(true);

    eventBus.$on("onPlanViewTableChange", () => {
      this.getTaskManagementPagingList();
    });
  },
  beforeDestroy() {
    eventBus.$off("onPlanViewTableChange");
  },
  methods: {
    copyId(id) {
      copyId(id);
      this.$message({
        type: "success",
        message: "任务ID: " + id + " 复制成功!",
      });
    },
    getTaskManagementPagingList() {
      this.tableLoading = true;

      let queryParams = {
        ownerId: this.info.ownerId,
        projectWorkflowIdList: this.info.projectWorkflowIdList,
        taskCreateBySelf: this.info.taskCreateBySelf,
        statusList: this.info.statusList,
        displayStatusList: this.info.displayStatusList,
        pageSize: this.pageInfo.pageSize,
        pageNum: this.pageInfo.pageNum,
      };

      getTaskManagementPagingList(queryParams)
        .then((res) => {
          if (res.success) {
            this.tableData = res.data;
            this.pageCount = res.pageCount;
            this.pageInfo.total = res.total;
            this.$emit("listenTaskListChange",{ type: "sure", list: this.tableData, pageInfo: this.pageInfo, pageCount: this.pageCount })
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    addTask(row, type) {
      this.relationList = [
        {
          type: 1,
          targetId: row.projectWorkflowDetailId,
        },
        {
          type: 2,
          targetId: row.projectWorkflowId,
        },
        {
          type: 3,
          targetId: row.projectId,
        },
      ];
      if (type === "edit" || type === "detail") {
        this.add1TaskDetail = row;
      }
      this.add1TaskType = type;
      this.add1TaskDalog = true;
    },
    projectTeam(cb) {
      if (cb) {
        projectAssignUserList(this.id).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    chooseInvestor(ownerId, id) {
      console.log(ownerId);
      console.log(id);
      let info = {
        id: id,
        ownerId: ownerId,
      };
      editTask(info).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "修改任务负责人成功!" });
          this.getTaskManagementPagingList();
        }
      });
    },
    //转派任务
    transferTask(type, row) {
      console.log(row);
      this.taskInfo = row;
      this.transferTaskType = type;
      this.transferTaskDalogVisible = true;
    },
    listenTransferTaskClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.transferTaskDalogVisible = !isClose;
      if (type === "sure") {
        this.getTaskManagementPagingList();
      }
    },
    listenAdd1TaskClose(params) {
      console.log(params);
      const { type, isClose, info } = params;
      this.add1TaskDalog = !isClose;
      if (type === "sure") {
        this.getTaskManagementPagingList();
        eventBus.$emit("onTrackViewTableChange");
      }
    },
    //更新任务
    updateTask(type, row) {
      console.log(row);
      this.nodeInfo = { id: row.businessId };
      this.taskInfo = row;

      if (type === "update") {
        this.updateTaskType = type;
        this.addTaskEditDalogVisible = true;
      } else if (type === "other") {
        this.relationList = [
          {
            type: 1,
            targetId: row.projectWorkflowDetailId,
          },
          {
            type: 2,
            targetId: row.projectWorkflowId,
          },
          {
            type: 3,
            targetId: row.projectId,
          },
        ];
        this.add1TaskType = "add";
        this.add1TaskDalog = true;
      }
    },
    handleExport(type, item) {
      if (type === 1) {
        //下载文件
        var elemIF = document.createElement("iframe");
        elemIF.src = item.url;
        elemIF.href = "javascript:void(0)";
        elemIF.style.display = "none";
        document.body.appendChild(elemIF);
      } else if (type === 2) {
        let fileType = item.url.split(".")[item.url.split(".").length - 1];
        console.log("fileType: ", fileType);
        console.log("fileItem: ", item);
        this.$router.push({
          name: "officeOnLine",
          params: { file: item, url: item.url,fileType:fileType },
        });
        return false;
        if (
          fileType === "docx" ||
          fileType === "doc" ||
          fileType === "xlsx" ||
          fileType === "xls" ||
          fileType === "ppt" ||
          fileType === "pptx"
        ) {
          console.log(item.url);
          let url = encodeURIComponent(item.url);
          window.open(
            "https://view.officeapps.live.com/op/view.aspx?src=" + url
          );
        } else if (fileType === "pdf") {
          console.log(item.url);
          this.imgUrl = null;
          let origin = window.location.origin;
          let url = origin + "/pdf/web/viewer.html?file=" + item.url;
          window.open(url);
        } else if (
          fileType === "jpg" ||
          fileType === "jpeg" ||
          fileType === "png" ||
          fileType === "gif"
        ) {
          this.previewPdfUrl = null;
          this.previewImgUrl = item.url;
          this.previewDialogVisible = true;
        } else {
          this.$message({ type: "warning", message: "请下载后查看!" });
          window.open(item.url);
        }
      }
    },

    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getTaskManagementPagingList();
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      if (val > this.pageCount) {
        val = this.pageCount;
      }

      this.getTaskManagementPagingList();
    },

    statusSelectChange() {
      this.statusSelectShow = !this.statusSelectShow;
    },

    resetPageNum() {
      this.pageInfo.pageNum = 1;
    },

    displayStatusListChange(value, id) {
      console.log(value);
      let info = {
        id: id,
        status: value,
      };
      taskStatusEdit(info).then((res) => {
        if (res.success) {
          this.getTaskManagementPagingList();
          this.statusSelectShow = !this.statusSelectShow;
          this.$message({ type: "success", message: "任务状态编辑成功!" });
        }
      });
    },

    //打开项目评估详情弹窗
    openEvaluateRecordTable(id) {
      console.log("evaluate record id: ", id);
      this.evaluateRecordId = String(id);
      this.evaluateRecordTableDialog = true;
    },

    //监听项目评估详情弹窗关闭
    listenEvaluateRecordTableClose(params) {
      const { isClose } = params;
      this.evaluateRecordTableDialog = !isClose;
    },

    //打开会议纪要详情弹窗
    openMeetingDetailsDialog(id) {
      console.log("meeting detail id: ", id);
      this.meetingInfoId = String(id);
      this.meetingDetailsDialog = true;
    },

    //监听会议纪要详情弹窗关闭
    listenMeetingDetailsClose(params) {
      const { isClose } = params;
      this.meetingDetailsDialog = !isClose;
    },

    //监听关闭预览弹窗
    closePreviewDialog() {
      this.previewPdfUrl = null;
      this.previewImgUrl = null;
      this.numPages = 1;
      this.previewDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.projectFrom .el-form-item {
  /* width: 230px; */
  flex-shrink: 0;
}

.editDialogs >>> .el-dialog__body {
  text-align: left;
}

.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}

.title {
  text-align: left;
  font-size: 1.5rem;
}

.taskList {
  margin-top: 10px;

  .projectTitle {
    margin: 5px 0;
  }
}

.fileList,
.evaluateList,
.meetingList {
  display: flex;
  align-items: center;

  &:last-of-type {
    margin-bottom: 3px;
  }

  &:hover {
    > i {
      display: inline-block;
    }
  }

  > i {
    display: none;
    margin-left: 3px;

    &:hover {
      cursor: pointer;
    }
  }
}

.otherButton {
  margin-left: 10px;
}

.status1 {
  color: #409eff;
}

.status2 {
  color: #f56c6c;
}

.status3 {
  color: #67c23a;
}

.status4 {
}

.status5 {
}
</style>
