<template>
  <el-dialog
    :title="title"
    width="520px"
    :visible="downloadTemplateDialog"
    @close="dialogClose"
  >
    <el-main>
      <div class="bar">
        <li class="width100" v-for="(item, index) in fileList" :key="index">
          <span class="titleKey">{{ item.name }}:</span>
         
          <el-link :href="item.url" type="primary" >{{ item.name }}</el-link>
        </li>
      </div>
    </el-main>
  </el-dialog>
</template>

<script>
export default {
  name: "downloadTemplate",
  data() {
    return {
      title: "下载",
      fileList: [
        {
          name: "消费、教育领域",
          url:
            "https://sy-hermes.oss-cn-beijing.aliyuncs.com/static/template/evaluation/import/ET1000001.xlsx",
        },
        {
          name: "B2B",
          url:
            "https://sy-hermes.oss-cn-beijing.aliyuncs.com/static/template/evaluation/import/ET1000002.xlsx",
        },
        {
          name: "食品饮料、农产品领域",
          url:
            "https://sy-hermes.oss-cn-beijing.aliyuncs.com/static/template/evaluation/import/ET1000003.xlsx",
        },
      ],
    };
  },
  props: {
    type: String,
    downloadTemplateDialog: Boolean,
    id: String,
  },
  components: {},
  filters: { 
  },
  created() {},
  methods: { 

    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listendownloadTemplateClose", { type: "cancel", isClose: true });
    },

      
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    span {
      display: inline-block;
      width: 200px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
        font-size: 30px;

      }
    }
  }
}
.buttonGrop {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-right: 100px;
  }
}
</style>