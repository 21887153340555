<template>
  <el-dialog
    class="edit"
    :title="title"
    :visible="choosingInvestorsDalogVisible"
    center
    @close="dialogClose"
    width="900px"
    :close-on-click-modal="false"
    :destroy-on-close="false"
  >
    <div class="bar">
      <li class="width50">
        <span>投资人：</span>
        <el-select
          v-model="choosingInvestorsIdList"
          class="childWidth"
          filterable
          placeholder="请选择投资人"
          @visible-change="findInvestorArr($event)"
          clearable
          multiple
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in choosingInvestorsIdListOptions"
            :key="item.investorId"
            :label="item.name"
            :value="item.investorId"
          >
          </el-option>
        </el-select>
      </li>
    </div>

    <div class="dialog-footer">
      <el-button @click="dialogClose">取消</el-button>
      <el-button type="primary" @click="sure">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
// import Vue from "vue";
//1
import { findInvestorArr, handleListArr } from "@/api/processProgress";

export default {
  name: "choosingInvestors",
  data() {
    return {
      title: "选择投资人",
      choosingInvestorsIdList: [],
      choosingInvestorsIdListOptions: null,
    };
  },
  props: {
    choosingInvestorsDalogVisible: Boolean,
    info: Object,
  },
  components: {},
  created() {},
  methods: {
    //
    findInvestorArr(cb) {
      console.log(cb);
      let one = {};
      one.id = this.info.projectWorkflowDetailId;
      one.taskId = this.info.id;
      one.namae = null;
      if (cb) {
        findInvestorArr(one).then((response) => {
          if (response.success) {
            this.choosingInvestorsIdListOptions = response.data;
          }
        });
      }
    },

    //弹窗关闭
    dialogClose() {
      this.formData = {};
      this.$emit("listenChoosingInvestorsClose", {
        type: "cancel",
        isClose: true,
      });
    },

    //确定
    sure() {  
       //确定选择投资人 
      handleListArr({ id: this.info.projectWorkflowDetailId, taskId: this.info.id, uidList: this.choosingInvestorsIdList }).then(res => {
        console.log(res);
        if (res.success) {
          this.$message({ type: "success", message: "选择投资人成功!" });
          this.choosingInvestorsIdList = null;
          this.$emit("listenChoosingInvestorsClose", {
            type: "sure",
            isClose: true,
          });
        }
      });
       
    },
  },
};
</script>

<style lang="scss" scoped>
.bigTitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
}
.buttonGrop {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  button {
    margin-right: 100px;
  }
}
.el-icon-iconName {
  margin: 0 5px;
}
</style>