<!-- 任务管理 -->
<template>
  <div class="task-management">
    <div class="top flexAcJcfe">
      <el-button
        type="primary"
        @click="addWorkflowTask(null, 'add')"
        v-permission="['admin:task:add']"
        >添加流程任务
      </el-button>
      <el-button
        type="primary"
        @click="addNonWorkflowTask(null, 'add')"
        v-permission="['admin:task:add']"
        >添加非流程任务
      </el-button>
    </div>
    <el-form
      class="projectForm"
      ref="taskManagement"
      :model="formData"
      label-width="110px"
    >
      <el-form-item label="业务状态" prop="statusList">
        <el-select
          v-model="formData.statusList"
          class="childWidth"
          clearable
          multiple
          @change="onChooseStatusList"
        >
          <el-option
            v-for="item in projectStatusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span :style="item.color">{{ item.label }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="业务进度表" prop="projectWorkflowId">
        <el-select
          v-model="formData.projectWorkflowIdList"
          clearable
          multiple
          filterable
          placeholder="请选择业务进度表"
          @visible-change="findMyProjectWorkflowList($event)"
          @change="onMyProjectWorkflowListChange"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in myProjectWorkflowList"
            :key="item.id"
            :label="item.projectName + ' - ' + item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="任务负责人" prop="ownerId">
        <el-select
          class="childWidth"
          v-model="formData.ownerId"
          filterable
          @visible-change="findMyProjectWorkflowMembers($event)"
          @clear="onOwnerIdCleared($event)"
          clearable
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in myProjectWorkflowMembers"
            :key="item.uid"
            :label="item.real_name"
            :value="item.uid"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="任务状态" prop="displayStatusList">
        <el-select v-model="formData.displayStatusList" clearable multiple>
          <el-option
            v-for="item in taskStatusListOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span :style="item.color">{{ item.label }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="结果筛选" prop="taskCreateBySelf">
        <el-checkbox v-model="formData.taskCreateBySelf" @change="resetPageNum"
          >由我创建的任务</el-checkbox
        >
      </el-form-item>
    </el-form>

    <el-row type="flex" justify="center" align="middle">
      <el-col :span="16">
        <div class="text-align-center">
          <el-button type="default" @click="resetQuery">重 置</el-button>
          <el-button type="primary" @click="handleQuery">查 询</el-button>
        </div>
      </el-col>
      <el-col :span="8" style="position: relative">
        <div class="text-align-center">
          <span style="color: #606266">视图切换：&nbsp;&nbsp;</span>
          <el-radio-group
            v-model="activeViewType"
            @change="activeViewTypeChange"
          >
            <el-radio-button label="计划视图"></el-radio-button>
            <el-radio-button label="跟踪视图"></el-radio-button>
          </el-radio-group>
        </div>

        <!--        <div class="text-align-center" style="position: absolute;top: -60px;left: 0;right:0;">
          <span style="color:#606266;">视图偏好：&nbsp;</span>
          <el-select
            v-model="userViewType"
            @change="onChooseViewType"
            style="width: 195px;"
          >
            <el-option
              v-for="(item, index) in viewTypeOptions"
              :key="index"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </div>-->
      </el-col>
    </el-row>

    <br />
    <br />

    <div v-show="activeViewType === '计划视图'">
      <taskPlanViewTable
        ref="taskPlanViewTable"
        :info="this.formData"
        :taskList="taskList"
        :pageInfo1="pageInfo1"
        :pageCount1="pageCount1"
        :loading="tableLoading"
        v-on:listenTaskListChange="listenTaskListChange"
      ></taskPlanViewTable>
    </div>

    <div v-show="activeViewType === '跟踪视图'">
      <taskTrackViewTable
        ref="taskTrackViewTable"
        :info="this.formData"
        :taskList="taskList"
        :pageInfo1="pageInfo1"
        :pageCount1="pageCount1"
        :loading="tableLoading"
        v-on:listenTaskListChange="listenTaskListChange"
      ></taskTrackViewTable>
    </div>

    <div style="height: 30px"></div>

    <el-divider content-position="center">非流程任务</el-divider>

    <div class="" v-show="formData != null">
      <nonWorkflowTaskTable
        ref="nonWorkflowTaskTable"
        :info="this.formData"
        :activeViewType="this.activeViewType"
        v-on:listenMissioTableB003="listenMissioTableB003"
      ></nonWorkflowTaskTable>
    </div>

    <!-- 关联日程 -->
    <associatedSchedule
      v-if="associatedScheduleDialog"
      :associatedScheduleDialog="associatedScheduleDialog"
      :info="taskInfo"
      v-on:listenAssociatedScheduleClose="listenAssociatedScheduleClose"
    ></associatedSchedule>

    <addTask1
      ref="addTask1"
      v-if="add1TaskDalog"
      :add1TaskDalog="add1TaskDalog"
      :type="add1TaskType"
      :workflowType="add1TaskWorkflowType"
      :info="add1TaskDetail"
      :relationList="relationList"
      v-on:listenAdd1TaskClose="listenAdd1TaskClose"
    ></addTask1>
  </div>
</template>

<script>
import {
  getOptionUserInfo,
  getUseDeptList,
  setOptionUserInfo,
} from "@/api/calendar";
import taskPlanViewTable from "@/components/myMission/taskManagement/taskPlanViewTable";
import taskTrackViewTable from "@/components/myMission/taskManagement/taskTrackViewTable";
import {
  findMyProjectWorkflowList,
  findMyProjectWorkflowMembers,
  getTaskManagementPagingList,
} from "@/api/myMission";
import nonWorkflowTaskTable from "@/components/myMission/taskManagement/nonWorkflowTaskTable";
import associatedSchedule from "@/components/myMission/associatedSchedule";
import addTask1 from "@/components/myMission/addTask/addTask1";
import { debounce } from "throttle-debounce";

export default {
  name: "taskManagement",
  data() {
    return {
      myProjectWorkflowList: [],
      useDeptList: [],
      formData: {
        projectWorkflowIdList: [],
        ownerId: "",
        taskCreateBySelf: false,
        statusList: [2],
        displayStatusList: [],
      },
      taskList: [],
      pageInfo1: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      pageCount1: 1,
      tableLoading:false,
      activeViewType: "计划视图",
      myProjectWorkflowMembers: [],
      associatedScheduleDialog: false, //关联日程
      taskInfo: null,
      relationList: [],
      add1TaskDetail: {},
      add1TaskType: "",
      add1TaskWorkflowType: "",
      add1TaskDalog: false,
      findMyProjectWorkflowMembersDebounce: debounce(500, false, () => {
        this.findMyProjectWorkflowMembers(true);
      }),
      taskStatusListOptions: [
        {
          value: 5,
          label: "进行中",
          color: "color:#F56C6C",
        },
        {
          value: 4,
          label: "待启动",
          color: "color:#409EFF",
        },
        {
          value: 6,
          label: "已完成",
          color: "color:#67C23A",
        },
      ],
      projectStatusOptions: [
        {
          value: 2,
          label: "进行中",
          color: "color:#F56C6C",
        },
        {
          value: 3,
          label: "已完成",
          color: "color:#67C23A",
        },
        {
          value: 5,
          label: "已暂停",
          color: "color:#E6A23C",
        },
        {
          value: 4,
          label: "已终止",
          color: "color:#909399",
        },
      ],
      viewTypeOptions: [
        { value: "default", label: "系统默认" },
        { value: "plan", label: "计划视图" },
        { value: "track", label: "跟踪视图" },
      ],
      userViewType: "default",
    };
  },
  props: {
    taskManagementInfo: Object,
  },
  components: {
    taskPlanViewTable,
    taskTrackViewTable,
    addTask1,
    nonWorkflowTaskTable,
    associatedSchedule,
  },
  mounted() {
    this.findMyProjectWorkflowMembers(true);
    this.getUserViewType();
  },
  watch: {
    userViewType(newVal, oldVal) {
      if (newVal === "default" || newVal === "plan") {
        this.activeViewType = "计划视图";
      } else if (newVal === "track") {
        this.activeViewType = "跟踪视图";
      }
    },
    taskManagementInfo: {
      handler(newVal, old) {
        let that = this;
        if (newVal) {
          that.formData.projectWorkflowIdList = [];
          that.findMyProjectWorkflowList(true);
          // that.findMyProjectWorkflowMembers(true);
          that.formData.projectWorkflowIdList.push(newVal.projectWorkflowId);
          // that.formData.displayStatusList.push(newVal.status);
          that.formData.ownerId = newVal.ownerId;
          that.handleQuery();
          that.$forceUpdate();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    listenTaskListChange(params) {
      console.log(params);
      const { type, list, pageInfo, pageCount } = params;
      if (type == "sure") {
          this.taskList = list;
          this.pageInfo1 = pageInfo;
          this.pageCount1 = pageCount;
      }
    },
    activeViewTypeChange(event) {
      if (event == "计划视图") {
        // this.$refs.taskPlanViewTable.getTaskManagementPagingList();
      } else if (event == "跟踪视图") {
        // this.$refs.taskTrackViewTable.getTaskManagementPagingList();
      }
    },
    //查找我参与的项目
    findMyProjectWorkflowList(show) {
      if (!show) return;
      let param = {
        statusList: this.formData.statusList,
      };
      findMyProjectWorkflowList(param).then((res) => {
        if (res.success) {
          let data = res.data || [];
          data.forEach((x) => {
            x.key = x.projectId + "|" + x.id;
          });
          this.myProjectWorkflowList = data;
          this.formData.projectWorkflowIdList =
            this.formData.projectWorkflowIdList.filter((x) =>
              data.some((y) => y.id === x)
            );

          this.findMyProjectWorkflowMembers(true);
        }
      });
    },
    getUseDeptList(cb) {
      var that = this;
      console.log("that.hsUserId=" + that.hsUserId);

      if (cb) {
        getUseDeptList({})
          .then((response) => {
            if (response.success) {
              let useDeptList = response.data;
              useDeptList.forEach(function (item, index) {
                item.uid = item.deptId;
                item.realName = item.deptName;
              });
              that.useDeptList = useDeptList;

              if (!that.hsUserId) {
                let userId = null;
                if (this.$route.query.workProgressUserId) {
                  userId = this.$route.query.workProgressUserId;
                } else {
                  userId = window.localStorage.getItem("userId");
                }
                useDeptList.forEach(function (item, index) {
                  if (item.userList && item.userList.length > 0) {
                    let oneInfo = [];
                    item.userList.forEach(function (item2, index2) {
                      if (userId == item2.uid) {
                        console.log("123");
                        that.hsUserId = true;
                        oneInfo[0] = item.deptId;
                        oneInfo[1] = item2.uid;
                        that.formData.ownerId = oneInfo;
                        that.$forceUpdate();
                      }
                    });
                  }
                });
              }
              this.$forceUpdate();
            }
          })
          .catch((_) => {
            console.log(".catch((_) => {})");
          });
      }
    },
    resetQuery() {
      this.formData = {
        projectWorkflowIdList: [],
        ownerId: "",
        taskCreateBySelf: false,
        statusList: [2],
        displayStatusList: [],
      };
      this.resetPageNum();
      setTimeout(() => {
        this.handleQuery();
      }, 200);
    },
    handleQuery() {
      if (this.activeViewType == "计划视图") {
        // this.$refs.taskPlanViewTable.getTaskManagementPagingList();
      } else if (this.activeViewType == "跟踪视图") {
        // this.$refs.taskTrackViewTable.getTaskManagementPagingList();
      }
      this.getTaskManagementPagingList();
      this.$refs.nonWorkflowTaskTable.getTaskQueryList1();
    },
    getTaskManagementPagingList() {
      this.tableLoading = true;

      let queryParams = {
        ownerId: this.formData.ownerId,
        projectWorkflowIdList: this.formData.projectWorkflowIdList,
        taskCreateBySelf: this.formData.taskCreateBySelf,
        statusList: this.formData.statusList,
        displayStatusList: this.formData.displayStatusList,
        pageSize: this.pageInfo1.pageSize,
        pageNum: this.pageInfo1.pageNum,
      };

      getTaskManagementPagingList(queryParams)
        .then((res) => {
          if (res.success) {
            this.taskList = res.data;
            this.pageCount1 = res.pageCount;
            this.pageInfo1.total = res.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    resetPageNum() {
      if (this.activeViewType == "计划视图") {
        this.$refs.taskPlanViewTable.resetPageNum();
      } else if (this.activeViewType == "跟踪视图") {
        this.$refs.taskTrackViewTable.resetPageNum();
      }
    },

    listenMissioTableB003(params) {
      console.log(params);
    },

    //查找我参与项目的成员，如果我是PM则可以看到所有成员，否则只能看到自己
    findMyProjectWorkflowMembers(show) {
      if (!show) return;
      let param = {
        idList: this.formData.projectWorkflowIdList || [],
      };
      findMyProjectWorkflowMembers(param).then((res) => {
        if (res.success) {
          this.myProjectWorkflowMembers = res.data || [];
          //如果新members中不包含已选member，则清空已选member
          let currMember = this.myProjectWorkflowMembers.find(
            (x) => x.uid === this.formData.ownerId
          );
          if (!currMember) {
            this.formData.ownerId = null;
          }
        }
      });
    },

    // 关联日程
    associatedSchedule(row) {
      console.log(row);
      this.taskInfo = row;
      this.associatedScheduleDialog = true;
    },

    //监听日程弹窗关闭
    listenAssociatedScheduleClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.associatedScheduleDialog = !isClose;
      if (type === "sure") {
        this.getTaskManagementPagingList();
      }
    },

    //监听任务
    listenAddTaskEditClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.addTaskEditDalogVisible = !isClose;
      if (type === "sure") {
        this.getTaskQueryList1(this.pageInfo.pageNum, this.pageInfo.pageSize);
      }
    },

    addWorkflowTask(row, type) {
      if (type === "edit" || type === "detail") {
        this.add1TaskDetail = row || {};
      } else {
        this.add1TaskDetail = {};
      }

      this.add1TaskType = type;
      this.add1TaskWorkflowType = "workflow";
      this.add1TaskDalog = true;
    },

    addNonWorkflowTask(row, type) {
      if (type === "edit" || type === "detail") {
        this.add1TaskDetail = row || {};
      } else {
        this.add1TaskDetail = {};
      }
      this.add1TaskType = type;
      this.add1TaskWorkflowType = "non-workflow";
      this.add1TaskDalog = true;
    },
    listenAdd1TaskClose(params) {
      console.log(params);
      const { type, workflowType, isClose, info } = params;
      this.add1TaskDalog = !isClose;
      if (type === "sure") {
        if (workflowType === "workflow") {
          this.$refs.taskPlanViewTable.getTaskManagementPagingList();
          this.$refs.taskTrackViewTable.getTaskManagementPagingList();
        } else {
          this.$refs.nonWorkflowTaskTable.getTaskQueryList1();
        }
      }
    },

    onOwnerIdCleared() {
      this.$refs.nonWorkflowTaskTable.clear();
    },

    onMyProjectWorkflowListChange() {
      //重新请求责任人列表
      this.resetPageNum();
      this.findMyProjectWorkflowMembersDebounce();
    },

    onChooseStatusList() {
      this.findMyProjectWorkflowList(true);
    },

    onChooseViewType(viewType) {
      //save viewType
      this.userViewType = viewType;

      let optionName = "task:management:preference:view_type";
      window.localStorage.setItem(optionName, viewType);

      let param = {
        name: optionName,
        value: viewType,
      };
      setOptionUserInfo(param).then((res) => {
        if (res.success) {
          console.log("setOptionUserInfo", res);
        }
      });
    },

    getUserViewType() {
      let optionName = "task:management:preference:view_type";

      //先取本地配置
      let userViewType = window.localStorage.getItem(optionName);
      if (userViewType) {
        this.userViewType = userViewType;
      }

      //再取服务器配置
      let param = {
        name: optionName,
      };
      getOptionUserInfo(param).then((res) => {
        if (res.success) {
          this.userViewType = res.data || "default";
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.task-management {
  .projectForm {
    margin: 15px 0 0;
    display: flex;
    flex-wrap: wrap;

    .roleCodeList {
      width: 100% !important;
      height: auto;
      text-align: center !important;
    }

    .el-form-item {
      text-align: left;
      width: 50% !important;
      min-height: 1rem;

      .el-select,
      .el-cascader {
        width: 100% !important;
      }

      .el-input-number {
        width: 100% !important;
        text-align: left !important;

        .el-input__inner {
          text-align: left !important;
        }
      }
    }
  }

  .text-align-center {
    text-align: center;
  }
}
</style>
