<template>
  <el-dialog
    :title="title"
    :visible="addBusinessProcessDialog"
    @close="resetForm"
    width="70%"
    style="padding: 0 0 100px 0px"
    :show-overflow-tooltip="true"
    :close-on-click-modal="false"
    :destroy-on-close="false"
    lock-scroll
  >
    <el-form
      :model="params"
      ref="addProject"
      :rules="rules"
      class="addDailog"
      onsubmit="return false;"
      label-width="140px"
      label-position="right"
      v-loading="fullscreenLoading"
    >
      <el-form-item label="项目/投资机构" prop="projectId">
        <el-select
          v-model="params.projectId"
          class="childWidth"
          filterable
          placeholder="请选择项目/投资机构"
          :disabled="type === 'detail'"
          @visible-change="searchPorjectList($event)"
          @change="choosePorject($event)"
          no-data-text="加载中···"

        >
          <el-option
            v-show="item.id"
            v-for="item in porjectListOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="业务流程" prop="originalWorkflowId">
        <el-select
          v-model="params.originalWorkflowId"
          @visible-change="technologicalList($event)"
          @change="chooseOriginalWorkflow($event)"
          placeholder="请选择"
          filterable
          :disabled="type === 'edit' || type === 'detail'"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in technological"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="评估模版" prop="evaluationTplIdList">
        <el-select
          filterable
          v-model="params.evaluationTplIdList"
          placeholder="请选择"
          @visible-change="selectEvaluationTemplate($event)"
          multiple
          :disabled="type === 'detail'"
          no-data-text="加载中···"
        >
          <el-option
            v-for="item in evaluationTemplateList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="flexAcJcfe">
        <el-button round size="medium" @click="addProjectMember">增加项目组成员</el-button>
      </div>
      <el-form-item
        label="项目组成员"
        prop="roleCodeList"
        style="width: 100% !important"
        class="roleCodeList"
      >
        <span slot="label"><i>*</i>项目组成员</span>
        <div class="memberList">
          <el-table border style="width: 100%" :data="params.memberList">
            <el-table-column label="姓名" prop="realName">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.uid"
                  @visible-change="queryProjectMember($event)"
                  filterable
                  placeholder="请选择"
                  clearable
                  class="childWidth"
                  no-data-text="加载中···"
                  :disabled="type === 'detail'"
                  @change="chooseProjectMember($event, scope.$index)"
                >
                  <el-option
                    v-for="item in members"
                    :key="item.uid"
                    :label="item.realName"
                    :value="item.uid"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="项目内角色" prop="roleCodeList">
              <template slot-scope="scope">
                <el-select
                  filterable
                  :reserve-keyword="false"
                  v-model="scope.row.roleCodeList"
                  placeholder="请选择"
                  clearable
                  @visible-change="roleSelectList($event)"
                  multiple
                  :disabled="type === 'detail'"
                  no-data-text="加载中···"
                  @change="chooseroleSelectSelect($event, scope.$index)"
                >
                  <el-option
                    v-for="item in roleSelectListOptions"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <!-- <el-table-column label="备注" prop="roleNames"></el-table-column> -->
            <el-table-column label="操作" width="190">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="deleteProjectMember(scope.row, scope.$index)"
                  >删除</el-button
                >
                <el-button type="text" @click="addProjectMember"
                  >增加</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <el-collapse accordion @change="roleCodeListHandleChange">
        <el-collapse-item>
          <template slot="title"> </template>

        </el-collapse-item>
      </el-collapse> -->
      </el-form-item>
      <el-form-item label="投融资需求" prop="investDemand">
        <el-input
          type="textarea"
          v-model="params.investDemand"
          :autosize="{ minRows: 3 }"
          :disabled="type === 'detail'"
          show-word-limit
          class="w100"
        ></el-input>
      </el-form-item>
      <el-form-item label="投融资进展" prop="investProgress">
        <el-input
          type="textarea"
          v-model="params.investProgress"
          :autosize="{ minRows: 3 }"
          :disabled="type === 'detail'"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
      v-if="type === 'add' || type === 'edit'"
    >
      <el-button @click="resetForm">取 消</el-button>
      <el-button :disabled="againStatus" type="primary" @click="addProject()"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
// 1
import { selectListMeetingProject } from "@/api/minutesOfMeeting";
//0
import { ROUNDS } from "@/common/constants";
import {
  projectTeam,
  selectTypeProject,
  selectEvaluationTemplate,
  projecWorkflowAdd,
  projecWorkflowEdit,
  projecTrackDetail,
} from "@/api/projectManage";

import { queryAccountList } from "@/api/accountManage";
import { roleSelectList } from "@/api/jurisdiction";
import { findMyProjectWorkflowMembers } from "@/api/myMission";
export default {
  name: "add",
  props: {
    type: String,
    addBusinessProcessDialog: Boolean,
    id: String,
    info: Object,
  },
  data() {
    return {
      //1
      title: "",
      porjectListOptions: [],
      loading: false,
      msPd: null,
      params: {
        projectId: null,
        originalWorkflowId: null,
        evaluationTplIdList: [],
        memberList: [],
        investDemand: null,
        investProgress: null,
      },
      technological: [],
      evaluationTemplateList: [],

      rules: {
        // projectName: [{ validator: checkProjectName, trigger: "blur" }],
        projectId: [
          { required: true, message: "请输入项目/投资机构", trigger: "change" },
        ],
        originalWorkflowId: [
          { required: true, message: "请选择流程", trigger: "change" },
        ],
        // evaluationTplIdList: [
        //   { message: "请选择模版", trigger: "change" },
        // ],
        roleCodeList: [
          { required: false, message: "请选择项目组成员", trigger: "change" },
        ],
      },
      tableData: [],
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 5,
        pageCount: 1,
      },
      roleSelectListOptions: [],
      addTrue: null,
      fullscreenLoading: false,
      evaluationTemplate: false,
      againStatus: false,
      userInfo: null,
      members: null,
    };
  },
  computed: {},
  watch: {
    info: {
      handler(newVal, oldVal) {
        console.log(newVal);
        if (newVal) {
          this.params.projectId = newVal.projectId;
          this.params.originalWorkflowId = newVal.originalWorkflowId;
          this.projecTrackDetail();
        }
      },
      deep: true,
    },
  },
  destroyed() {
    this.params = {};
    this.info = {};
  },
  created() {},
  mounted() {
    // this.queryAccounts();
    this.queryProjectMember(true);
    if (this.type === "add") {
      this.title = "新建业务流程";
      this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
      let memberOne = {
        realName: this.userInfo.realName,
        uid: this.userInfo.uid,
        roleCodeList: [],
      };
      this.params.memberList.push(memberOne);
    } else if (this.type === "edit") {
      this.title = "编辑业务流程";
      this.params = {};
      this.params.projectId = this.info.projectId;
      this.params.originalWorkflowId = this.info.originalWorkflowId;
      this.projecTrackDetail();
    } else if (this.type === "detail") {
      this.title = "查看业务流程";
      this.params = {};
      this.params.projectId = this.info.projectId;
      this.params.originalWorkflowId = this.info.originalWorkflowId;
      this.projecTrackDetail();
    }
  },
  methods: {
    chooseProjectMember(data, index) {
      var that = this;
      console.log(data);
      that.params.memberList.forEach(function (item) {
        that.members.forEach(function (item2) {
          if (data == item2.uid) {
            that.params.memberList[index].uid = item2.uid;
            that.params.memberList[index].realName = item2.realName;
          }
        });
      });
      this.$forceUpdate();
    },
    chooseroleSelectSelect(data, index) {
      console.log(data);
      console.log(index);
      // this.$set(this.params.memberList[index],'roleCodeList',data)
      // this.params.memberList[index].roleCodeList = data;
      this.$forceUpdate();
    },
    queryProjectMember(cb) {
      var that = this;
      if (cb) {
        let info = {
          uid: null,
          username: null,
          realName: null,
        };
        projectTeam(info).then((response) => {
          if (response.success) {
            let memberList = response.data;
            that.members = memberList;
          }
        });
      }
    },
    deleteProjectMember(row, index) {
      console.log(row, index);
      this.params.memberList.splice(index, 1);
      // if (index == 0) {
      //   return false;
      // } else {
      // }
    },
    addProjectMember() {
      let memberOne = {
        realName: null,
        uid: null,
        roleCodeList: [],
      };
      this.params.memberList.push(memberOne);
    },
    searchPorjectList(cb) {
      console.log(cb);
      if (cb) {
        selectListMeetingProject({}).then((response) => {
          if (response.success) {
            this.porjectListOptions = response.data;
            if (response.data.length == 0) {
              this.$message({ type: "warning", message: "暂无项目!" });
            }
          }
        });
      }
    },
    chooseOriginalWorkflow(id, name) {
      var that = this;
      console.log(id);
      that.technological.forEach(function (item, index) {
        if (item.id == that.params.originalWorkflowId) {
          that.params.name = item.name;
          if (item.evaluate == 1) {
            that.evaluationTemplate = true;
          } else {
            that.evaluationTemplate = false;
          }
        }
      });
      if (this.params.projectId) {
        this.projecTrackDetail();
      } else {
        this.$message({ type: "warning", message: "请选择项目!" });
      }
    },
    choosePorject(id) {
      var that = this;
      console.log(id);
      this.params.originalWorkflowId = null;
      // if ((this.type = "edit")) {
      // if (this.params.originalWorkflowId) {
      //   this.projecTrackDetail();
      // } else {
      //   this.$message({ type: "warning", message: "请选择流程!" });
      // }
      // }
    },
    projecTrackDetail() {
      var that = this;
      projecTrackDetail({
        projectId: this.params.projectId,
        originalWorkflowId: this.params.originalWorkflowId,
      })
        .then(
          (res) => {
            if (res.success && res.data != null) {
              if (this.type === "edit" || this.type === "detail") {
                let info = res.data;
                that.params = info;
                that.addTrue = false;
                // that.queryAccounts(true);
                that.selectEvaluationTemplate(true);
                that.technologicalList(true);
                that.roleSelectList(true);
                that.searchPorjectList(true);
              } else if (this.type === "add") {
                that.againStatus = true;
                that.$message({
                  type: "warning",
                  message: "此业务流程已被创建!",
                });
              }
            } else {
              that.againStatus = false;
              that.addTrue = true;
              // that.params.memberList = [];
              that.params.evaluationTplIdList = [];
              that.params.investDemand = null;
              that.params.investProgress = null;
              // that.queryAccounts(true);
              that.selectEvaluationTemplate(true);
              that.technologicalList(true);
              that.roleSelectList(true);
              that.searchPorjectList(true);
            }
          },
          (error) => {
            console.log("接口报错");
            console.log(error);
          }
        )
        .catch((err) => {
          console.log("32");
          that.addTrue = true;
          that.params.memberList = [];
          that.params.evaluationTplIdList = [];
          that.params.investDemand = null;
          that.params.investProgress = null;
          // that.queryAccounts(true);
          that.selectEvaluationTemplate(true);
          that.technologicalList(true);
          that.roleSelectList(true);
          that.searchPorjectList(true);
        });
    },
    addProject() {
      //添加|修改 请求数据
      var that = this;

      var form = JSON.parse(JSON.stringify(that.params));
      that.$refs["addProject"].validate((valid) => {
        console.log(valid);
        if (valid) {
          console.log(form);
          if (
            that.evaluationTemplate &&
            (form.evaluationTplIdList == null ||
              form.evaluationTplIdList.length == 0)
          ) {
            that.$message({ type: "warning", message: "请选择模版!" });
            return false;
          }
          that.fullscreenLoading = true;
          let alist = [];
          form.memberList.forEach(function (item, index) {
            if (
              item.hasOwnProperty("roleCodeList") &&
              item.roleCodeList instanceof Array &&
              item.roleCodeList.length > 0
            ) {
              console.log("alist");
              let one = {};
              one.roleCodeList = item.roleCodeList;
              one.uid = item.uid;
              one.realName = item.realName;
              alist.push(one);
            } else {
            }
          });
          if (alist.length == 0) {
            that.$message({ type: "warning", message: "请选择项目组成员!" });
            that.fullscreenLoading = false;

            return false;
          } else {
            form.memberList = alist;
          }
          form.status = null;
          form.statusChangeReason = null;
          console.log(form);
          if (that.type == "add") {
            projecWorkflowAdd(form)
              .then((response) => {
                if (response.success) {
                  that.$message({
                    type: "success",
                    message: "新增业务流程成功!",
                  });
                  that.$refs["addProject"].resetFields();
                  // that.queryAccounts();
                  that.fullscreenLoading = false;
                  that.$emit("listenBusinessProcesClose", {
                    type: "sure",
                    isClose: true,
                  });
                } else {
                  that.fullscreenLoading = false;
                }
              })
              .catch(() => {
                that.fullscreenLoading = false;
              });
          } else {
            projecWorkflowEdit(form)
              .then((response) => {
                if (response.success) {
                  that.$message({
                    type: "success",
                    message: "编辑业务流程成功!",
                  });
                  that.$refs["addProject"].resetFields();
                  // that.queryAccounts();
                  that.fullscreenLoading = false;
                  that.$emit("listenBusinessProcesClose", {
                    type: "sure",
                    isClose: true,
                  });
                } else {
                  that.fullscreenLoading = false;
                }
              })
              .catch(() => {
                that.fullscreenLoading = false;
              });
          }
        } else {
          console.log(valid);
        }
      });
    },
    resetForm() {
      this.$emit("listenBusinessProcesClose", {
        type: "cancel",
        isClose: true,
      });
    },

    roleSelectList(cb) {
      if (cb && this.roleSelectListOptions.length == 0) {
        roleSelectList().then((response) => {
          this.roleSelectListOptions = response.data;
        });
      }
    },
    queryAccounts() {
      var that = this;
      let info = {
        uid: null,
        username: null,
        realName: null,
      };
      projectTeam(info).then((response) => {
        let memberList = response.data;
        if (
          that.params.projectId &&
          that.params.memberList &&
          that.params.memberList.length > 0
        ) {
          memberList.forEach(function (item, index) {
            that.params.memberList.forEach(function (item2, index2) {
              if (item.uid == item2.uid) {
                item.roleCodeList = item2.roleCodeList;
              } else {
                // item2.roleCodeList = [];
              }
            });
          });
          this.params.memberList = memberList;
        } else {
          this.params.memberList = memberList;
        }
      });
    },

    selectEvaluationTemplate(cb) {
      if (cb) {
        selectEvaluationTemplate({}).then((response) => {
          if (response.success) {
            this.evaluationTemplateList = response.data;
          }
        });
      }
    },
    technologicalList(cb) {
      if (cb) {
        //流程列表
        selectTypeProject({}).then((res) => {
          if (res.success) {
            // console.log(res.data);
            let technological = res.data;
            this.technological = technological;
          }
        });
      }
    },
  },

  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
  components: {},
};
</script>

<style lang="scss" scoped>
.addDailog {
  // padding: 0 0 20px 0;
  //   display: flex;
  //   flex-wrap: wrap;
  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
    margin-top: 10px;
  }
  .el-form-item {
    text-align: left;

    // display: flex;
    width: 100% !important;
    // float: left;
    min-height: 1rem;
    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
  }
}

// .addDailog >>> .el-form-item {
//   display: flex;
//   width: 100% !important;
//   // float: left;
// }
// .addDailog >>> .el-form-item__content {
//   margin: 0 !important;
//   width: 70% !important;
// }
// .addDailog >>> .el-select {
//   width: 100% !important;
// }
// .addDailog >>> .el-autocomplete {
//   width: 100% !important;
// }
// .addDailog >>> .el-input-number {
//   width: 100% !important;
// }
.mkName {
  list-style: none;
  position: absolute;
  width: 92%;
  top: 40px;
  left: 0;
  margin: 0;
  background: #fff;
  z-index: 99;
  border: 1px solid #ccc;
}
.mkName li {
  line-height: 35px;
}
.memberList {
  min-height: 20rem;
  overflow-y: scroll;
}
.memberList i {
  margin-left: 3px;
}
.memberList p {
  flex-shrink: 0;
}
.dialog-footer {
  /* margin-top: 100px; */
  text-align: center !important;
  .el-button {
    margin: 0 50px;
  }
}
i {
  color: #f56c6c;
  margin-right: 4px;
}
</style>
