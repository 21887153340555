<template>
  <div>
    <div class="bar">
      <el-form
        :model="formData"
        ref="assessment"
        :rules="rules"
        class="addDailog"
        onsubmit="return false;"
        label-width="120px"
        label-position="right"
      >
        <el-form-item label="项目/投资机构" prop="projectId">
          <el-select
            v-model="formData.projectId"
            class="childWidth"
            filterable
            placeholder="请选择项目/投资机构"
            @visible-change="searchPorjectList($event)"
            @change="choosePorject($event)"
            clearable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in porjectListOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="流程" class="roleCodeList" prop="workflowId">
          <el-select
            v-model="formData.workflowId"
            @visible-change="technologicalList($event)"
            @change="chooseTechnologica($event)"
            clearable
            placeholder="请选择"
            filterable
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in technological"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="项目流程节点" prop="projectWorkflowDetailId">
          <el-select
            v-model="formData.projectWorkflowDetailId"
            filterable
            placeholder="请输入关键词从节点中搜索"
            @visible-change="searchWorkflowList($event)"
            @change="chooseWorkflow($event)"
            clearable
            class="childWidth"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in workflowListOptions"
              :key="item.id"
              :label="item.nodeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="评估模版" prop="templateId">
          <el-select
            v-model="formData.templateId"
            class="childWidth"
            placeholder="请选择"
            clearable
            @visible-change="selectEvaluationTemplate($event)"
            @change="chooseTemplateId($event)"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in evaluationTemplateList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="任务" prop="taskId">
          <div class="flexJsbAc">
            <el-select
              v-model="formData.taskId"
              @visible-change="getTaskSelectList($event)"
              @change="chooseTask($event)"
              filterable
              placeholder="请选择"
              clearable
              no-data-text="加载中···"
            >
              <el-option
                v-for="item in taskSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item> -->
        <el-form-item label="记录人" prop="recorderId">
          <el-select
            v-model="formData.recorderId"
            filterable
            :reserve-keyword="false"
            placeholder="请输入关键词从中搜索"
            @visible-change="projectTeam($event)"
            @change="chooseInvestor($event)"
            clearable
            class="childWidth"
            no-data-text="加载中···"
          >
            <el-option
              v-for="item in internalUseroptionsList"
              :key="item.uid"
              :label="item.realName"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="评估人" prop="evaluatorName">
          <el-input
            v-model="formData.evaluatorName"
            class="childWidth"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="评估日期" prop="beginTime">
          <el-date-picker
            v-model="formData.beginTime"
            type="datetime"
            placeholder="选择日期时间"
            align="right"
            :picker-options="pickerOptions"
            style="width: 100% !important"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item> -->
      </el-form>
    </div>

    <p style="text-align: center">
      <el-button @click="resetConditions">重置</el-button>
      <el-button
        @click="getEvaluationRecordList(pageInfo.pageNum, pageInfo.pageSize)"
        >查询记录</el-button
      >

      <!-- <el-button
        style="width: 40%"
        @click="addAssessment()"
        v-permission="['admin:evaluation:record_start_time']"
        >开始评估</el-button
      > -->
    </p>
    <!-- 评估历史 -->
    <div>
      <h4 style="text-align: left">评估历史：</h4>
      <!-- 表格 -->
      <el-table
        border
        row-key="id"
        style="width: 100%; margin-top: 10px"
        :data="assessmentHistoryList"
        highlight-current-row
      >
        <el-table-column label="项目名称" prop="projectName"></el-table-column>
        <el-table-column
          label="公司全称"
          prop="principalName"
        ></el-table-column>
        <el-table-column label="业务流程" prop="workflowNodeName">
          <template slot-scope="scope">
            <span
              >{{ scope.row.projectWorkflowName }}
              <span class="workflowVersion"
                >版本{{ scope.row.workflowVersion }}</span
              >
            </span>
          </template>
        </el-table-column>

        <el-table-column label="流程节点" prop="workflowNodeName">
          <template slot-scope="scope">
            <span>{{ scope.row.workflowNodeName }} </span>
          </template>
        </el-table-column>
        <el-table-column label="任务" prop="taskName"></el-table-column>

        <el-table-column label="评估模板" prop="templateName"></el-table-column>
        <el-table-column label="评估人" prop="evaluatorName"></el-table-column>
        <el-table-column label="业务负责人" prop="pmName"></el-table-column>

        <el-table-column label="评估总分" prop="totalScore"></el-table-column>

        <el-table-column label="评估时间" prop="createDate">
          <template slot-scope="scope">
            <span>{{ scope.row.beginTime | formatDateFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="getEvaluationRecordDetail(scope.row)"
              >评估记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageInfo.pageNum"
          :page-sizes="[10, 20]"
          :page-size="this.pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.pageInfo.total"
        ></el-pagination>
      </div>
    </div>


    <!-- 下载 -->
    <downloadTemplate
      v-if="downloadTemplateDialog"
      v-on:listendownloadTemplateClose="listendownloadTemplateClose"
      :downloadTemplateDialog="downloadTemplateDialog"
    >
    </downloadTemplate>

     <evaluateRecordTable
      v-if="evaluateRecordTableDialog"
      v-on:listenEvaluateRecordTableClose="listenEvaluateRecordTableClose"
      :evaluateRecordTableDialog="evaluateRecordTableDialog"
      :id="evaluateRecordId"
    >
    </evaluateRecordTable>
  </div>
</template>

<script>
// import dynamicTable from "@/components/myMission/evaluationList/dynamicTable";
import downloadTemplate from "@/components/myMission/downloadTemplate";
import evaluateRecordTable from "@/components/myMission/evaluationList/evaluateRecordTable";

import {
  evaluationRecord,
  projectTeam,
  selectEvaluationTemplate,
  selectTypeProject,
  assessmentHistory,
  getEvaluationRecordList,
  getEvaluationRecordTable,
  getEvaluationRecordDetail,
} from "@/api/projectManage";

import {
  selectListMeetingProject,
  selectListWorkflow,
  selectListInvestor,
} from "@/api/minutesOfMeeting";
import { importRecord } from "@/api/home";
import { formatDate } from "@/common/date";
import { getTaskSelectList } from "@/api/myMission";

//
import XLSX from "xlsx";
export default {
  name: "evaluationList",
  data() {
    return {
      outputs: [],
      formData: {
        workflowId: null,
        projectId: null,
        templateId: null,
        evaluatorName: null,
        recorderId: null,
      },
      searchPorjectListLoading: false,
      searchWorkflowListLoading: false,
      tableLoading: false,
      porjectListOptions: null,
      workflowListOptions: null,
      internalUseroptionsList: null,
      evaluationTemplateList: null,
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      limit: 1, // 上传excell时，同时允许上传的最大数
      fileList: [], // excel文件列表
      isShowFile: false, //文件列表是否显示 默认不显示
      submitImportShow: false,
      formData1: new FormData(),
      downloadTemplateDialog: false,
      evaluateRecordTableDialog:false,
       evaluateRecordTableData: null,
      needMergeArr: ["moduleName", "subModuleName"], // 有合并项的列
      rowMergeArrs: {}, // 包含需要一个或多个合并项信息的对象
      tableLoading2: false,
      moduleScoreHeaderName: null,
      evaluateRecordId:null,
      //before
      isAbleSubmit: true,
      isAbleNext: false,
      dialogVisible: false,
      options: [],
      timu: [],
      tableData: null,
      titleTi: 0,
      value: "",
      evaluationTimes: 0,
      investorwListOptions: [],
      technological: [],
      rules: {
        workflowId: [
          { required: false, message: "请选择流程", trigger: "change" },
        ],
        templateId: [
          { required: false, message: "请选择模版", trigger: "change" },
        ],
        projectId: [
          { required: false, message: "请选择项目/投资机构", trigger: "change" },
        ],
        recorderId: [
          { required: false, message: "请选择记录人", trigger: "change" },
        ],
        evaluatorName: [
          { required: false, message: "请输入评估人", trigger: "blur" },
        ],
        // beginTime: [
        //   { required: false, message: "请选择评估时间", trigger: "change" },
        // ],
        // taskId: [{ required: true, message: "请选择任务", trigger: "change" }],
      },
      assessmentHistoryList: [],
      taskSelectList: [],
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        pageCount: 1,
      },
      recordList: null,
      headerList: null,
    };
  },
  components: {
    downloadTemplate,
    // dynamicTable,
    evaluateRecordTable
  },
  computed: {},
  watch: {
     tabIndex(newVal, old) {
      if (newVal == "3") {
          this.getEvaluationRecordList(1, this.pageInfo.pageSize);

      }
    },
    project: {
      handler(newName, oldName) {
        console.log("obj.a changed1");
        if (this.project) {
          // this.formData = {};
          this.formData.projectId = this.project.id;
          this.formData.projectName = this.project.projectName;
          this.formData.workflowId = this.project.projectWorkflowId;

          if (this.formData.projectId) {
            console.log("this.formData.projectId");
            this.searchPorjectList(true);
            this.technologicalList(true);
          }
          this.getEvaluationRecordList(1, this.pageInfo.pageSize);
          this.$forceUpdate();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  props: {
    projectTaskInfo: Object,
    project: Object,
    tabIndex: String,
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  created() {},
  activated(){
    this.getEvaluationRecordList(1, this.pageInfo.pageSize);
  },
  mounted() {
  },
  methods: {
    getEvaluationRecordDetail(row) {
      console.log(111)
      this.evaluateRecordId = String(row.id);
      this.evaluateRecordTableDialog = true;
    },


    /**
     * @description 实现合并行或列
     * @param row:Object 需要合并的列name 如:'name' 'id'
     * @param column:Object 当前行的行数，由合并函数传入
     * @param rowIndex:Number 当前列的数据，由合并函数传入
     * @param columnIndex:Number 当前列的数据，由合并函数传入
     *
     * @return 函数可以返回一个包含两个元素的数组，第一个元素代表rowspan，第二个元素代表colspan。 也可以返回一个键名为rowspan和colspan的对象
     * 参考地址：https://element.eleme.cn/#/zh-CN/component/table#table-biao-ge
     */
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // console.log(column)
      // 没办法循环判断具体是那一列 所以就只好写了多个if
      if (column.label === "模块")
        return this.mergeAction("moduleName", rowIndex, column);
      if (column.label === "细分模块")
        return this.mergeAction("subModuleName", rowIndex, column);
    },
    /**
     * @description 根据数组来确定单元格是否需要合并
     * @param val:String 需要合并的列name 如:'name' 'id'
     * @param rowIndex:Number 当前行的行数，由合并函数传入
     * @param colData:Object 当前列的数据，由合并函数传入
     *
     * @return 返回值为一个数组表示该单元格是否需要合并; 说明: [0,0]表示改行被合并了 [n+,1]n为1时表示该单元格不动,n大于1时表示合并了N-1个单元格
     */
    mergeAction(val, rowIndex, colData) {
      let _row = this.rowMergeArrs[val].rowArr[rowIndex];
      let _col = _row > 0 ? 1 : 0;
      return [_row, _col];
    },
    /**
     * @description 根据数组将指定对象转化为相应的数组
     * @param arr:Array[String] 必填 必须是字符串形式的数组
     * @param data:Array 必填 需要转化的对象
     *
     * @return 返回一个对象
     * 例：rowMerge(['name','value'], [{value:'1', name:'小明'}, {value:'2', name:'小明'}, {value:'3', name:'小明'}, {value:'1', name:'小明'}, {value:'1', name:'小明'}])
     * 返回值: {
     *          name:{
     *            rowArr: [5, 0, 0, 0, 0]
     *            rowMergeNum: 0,
     *          },
     *          value: {
     *            rowArr: [1, 1, 1, 2, 0],
     *            rowMergeNum: 3
     *          }
     *        }
     */
    rowMergeHandle(arr, data) {
      if (!Array.isArray(arr) && !arr.length) return false;
      if (!Array.isArray(data) && !data.length) return false;
      let needMerge = {};
      arr.forEach((i) => {
        needMerge[i] = {
          rowArr: [],
          rowMergeNum: 0,
        };
        data.forEach((item, index) => {
          if (index === 0) {
            needMerge[i].rowArr.push(1);
            needMerge[i].rowMergeNum = 0;
          } else {
            if (item[i] === data[index - 1][i]) {
              needMerge[i].rowArr[needMerge[i].rowMergeNum] += 1;
              needMerge[i].rowArr.push(0);
            } else {
              needMerge[i].rowArr.push(1);
              needMerge[i].rowMergeNum = index;
            }
          }
        });
      });
      return needMerge;
    },
    handleSizeChange(val) {
      console.log(val);
      this.getEvaluationRecordList(1, val);
    },
    handleCurrentChange(val) {
      console.log(val);
      if (val > this.pageInfo.pageCount) {
        val = this.pageInfo.pageCount;
      }
      this.getEvaluationRecordList(val, this.pageInfo.pageSize);
    },
    queryEvaluationList() {},
    resetConditions() {
      // this.formData = {};
      // this.formData.projectId = null;
      // this.formData.originalWorkflowId = null;
      // this.formData.nodeId = null;
      this.$refs["assessment"].resetFields(); //重置form
      this.getEvaluationRecordList(1, this.pageInfo.pageSize);
    },
    getEvaluationRecordList(num, pageSize) {
      this.tableLoading = false;
      let info = {
        pageNum: num,
        pageSize: pageSize,
        ...this.formData,
      };

      getEvaluationRecordList(info).then((response) => {
        if (response.success) {
          this.assessmentHistoryList = response.data;
          if (response.total != null) {
            this.pageInfo.pageNum = response.pageNum;
            this.pageInfo.pageSize = response.pageSize;
            this.pageInfo.total = response.total;
            this.pageInfo.pageCount = response.pageCount;
          } else {
            this.pageInfo.total = 0;
          }
        }
      });
    },
    getTaskSelectList(cb) {
      if (cb) {
        this.taskSelectList = [];
        if (!this.formData.projectWorkflowDetailId) {
          this.$message({ type: "warning", message: "请先选择业务流程节点!" });
          return false;
        }
        let info = {
          businessType: 1,
          businessId: this.formData.projectWorkflowDetailId,
        };
        getTaskSelectList(info).then((response) => {
          if (response.success) {
            this.taskSelectList = response.data;
          }
        });
      }
    },
    chooseTask(id) {
      var that = this;
      console.log(id);
      that.taskSelectList.forEach(function (item, index) {
        if (id == item.id) {
          that.formData.taskId = item.id;
          that.formData.taskName = item.name;
          that.$forceUpdate();
        }
      });
    },
    chooseTemplateId(id) {
      console.log(id);
      this.formData.templateId = id;
      this.$forceUpdate();
    },
    openAssessmentHistory(row) {
      console.log(row);
      let info = {};
      info.projectWorkflowId = row.projectWorkflowId;
      info.templateId = row.templateId;
      this.getEvaluationRecordTable(info);
    },
    getEvaluationRecordTable(info) {
      this.tableLoading = false;
      getEvaluationRecordTable(info).then((response) => {
        if (response.success) {
          let tableData = response.data;
          this.tableData = response.data;
          this.recordList = tableData.recordList;
          this.headerList = tableData.headerList;
          this.tableLoading = true;
        }
      });
    },
    assessmentHistory() {
      assessmentHistory({ projectId: this.formData.projectId }).then(
        (response) => {
          if (response.success) {
            this.assessmentHistoryList = response.data;
          }
        }
      );
    },
    listendownloadTemplateClose(params) {
      const { type, isClose } = params;
      this.downloadTemplateDialog = !isClose;
      if (type == "sure") {
      }
    },
    listenEvaluateRecordTableClose(params) {
      const { type, isClose } = params;
      this.evaluateRecordTableDialog = !isClose;
      if (type == "sure") {
      }
    },
    //下载模版
    downloadTemplate() {
      this.downloadTemplateDialog = true;
    },
    handleExport(item) {
      //下载文件
      var elemIF = document.createElement("iframe");
      elemIF.src = item.url;
      elemIF.style.display = "none";
      document.body.appendChild(elemIF);
    },
    //判断对象中属性是否全部有值
    paramsValidate(params) {
      let flag = true;
      for (var key in params) {
        if (params[key] != "0" && !params[key]) {
          this.$message({ type: "warning", message: "请完善数据!" });
          return false; // 终止程序
        }
      }
      return flag;
    },
    warninMessage() {
      this.$message({ type: "warning", message: "请完善数据!" });
    },
    //导入模版
    importTemplate() {
      console.log(this.formData);
      if (
        !this.formData.beginTime ||
        !this.formData.projectId ||
        !this.formData.projectWorkflowDetailId ||
        !String(this.formData.templateId) ||
        !this.formData.evaluatorName ||
        !this.formData.evaluatorName
      ) {
        // this.submitImportShow = false;
        this.$message({
          type: "warning",
          message: "请选择需要导入的表格信息!",
        });
      } else {
        // this.submitImportShow = true
        let formData = new FormData();
        formData.append("beginTime", this.formData.beginTime);
        formData.append("projectId", this.formData.projectId);
        formData.append(
          "projectWorkflowDetailId",
          this.formData.projectWorkflowDetailId
        );
        formData.append("evaluatorName", this.formData.evaluatorName);

        formData.append("workflowNodeName", this.formData.workflowNodeName);
        formData.append("templateId", this.formData.templateId);
        formData.append("projectWorkflowId", this.formData.projectWorkflowId);
        //projectWorkflowId
        this.formData1 = formData;
      }
    },

    submitImport(e) {
      var that = this;

      //声明一个FormDate对象
      // let formData = new FormData();
      this.formData1.append("file", e.target.files[0]);
      // formData.append("file", e.target.files[0]);
      console.log(e.target);

      let file = e.target.files[0];
      let name = e.target.files[0].name;

      importRecord(this.formData1).then((response) => {
        if (response.success) {
          console.log(response);
          this.$message({ type: "success", message: "导入成功!" });

          this.formData1 = new FormData();
        }
      });
    },
    //
    selectEvaluationTemplate(cb) {
      var that = this;
      if (cb) {
        selectEvaluationTemplate({
          projectId: this.formData.projectId,
          workflowId: this.formData.beforeProjectWorkflowId,
        }).then((response) => {
          if (response.success) {
            that.evaluationTemplateList = response.data;
            if (that.evaluationTemplateList.length == 1) {
              console.log("03");
              console.log(that.evaluationTemplateList[0].id);

              that.formData.templateId = that.evaluationTemplateList[0].id;
              that.$forceUpdate();
            } else {
              that.formData.templateId = null;
            }
          }
        });
      }
    },
    projectTeam(cb) {
      if (cb) {
        projectTeam({}).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
            // if (this.formData.recorderId == null) {
            //   this.formData.recorderId = window.localStorage.getItem("userId");
            //   this.formData.recorderName =
            //     window.localStorage.getItem("realName");

            //   this.$forceUpdate();
            // }
          }
        });
      }
    },
    chooseInvestor(id) {
      var that = this;
      console.log(id);
      that.internalUseroptionsList.forEach(function (item, index) {
        if (id == item.uid) {
          that.formData.recorderId = item.uid;
          that.formData.recorderName = item.realName;
          this.$forceUpdate();
        }
      });
    },
    //
    choosePorject(id) {
      var that = this;
      console.log(id);
      that.formData.templateId = null;
      that.formData.workflowId = null;
      // that.formData.projectWorkflowDetailId = null;
      // that.formData.taskId = null;
      that.tableData = null;
      that.porjectListOptions.forEach(function (item, index) {
        if (id == item.id) {
          that.formData.projectId = item.id;
          that.formData.projectName = item.projectName;
          // that.formData.projectWorkflowId = item.projectWorkflowId;
          // that.selectEvaluationTemplate(true);
          // that.formData.templateId = item.evaluationTemplateId;
          // that.formData.beginTime = new Date().getTime();
          //   that.technologicalList(true);
          //   that.assessmentHistory();
        }
      });
    },
    chooseWorkflow(id) {
      var that = this;
      that.formData.taskId = null;
      that.workflowListOptions.forEach(function (item, index) {
        if (id == item.id) {
          that.formData.projectWorkflowDetailId = item.id;
          that.formData.workflowNodeName = item.nodeName;
          that.formData.projectWorkflowId = item.projectWorkflowId;
          // that.formData.beginTime = new Date().getTime();
        }
      });
    },
    chooseTechnologica(id) {
      this.$forceUpdate();
    },
    //
    technologicalList(cb) {
      var that = this;
      that.technological = [];
      if (cb) {
        //流程列表
        let param = {};
        param.projectId = that.formData.projectId;
        selectTypeProject(param).then((res) => {
          if (res.success) {
            let technological = res.data;
            that.technological = technological;
            // if (technological.length == 0 || !technological) {
            //   that.$message({ type: "warning", message: "请先创建业务流程!" });
            // } else if (technological.length == 1) {
            //   that.formData.beforeProjectWorkflowId = technological[0].id;
            //   that.selectEvaluationTemplate(true);
            //   that.$forceUpdate();
            // } else {
            //   that.formData.beforeProjectWorkflowId = null;
            // }
          }
        });
      }
    },
    //
    searchPorjectList(cb) {
      var that = this;
      if (cb) {
        selectListMeetingProject({}).then((response) => {
          if (response.success) {
            this.porjectListOptions = response.data;
          }
        });
      }
    },
    selectListInvestor(cb) {
      let name = null;
      if (cb) {
        selectListInvestor(name).then((response) => {
          if (response.success) {
            this.investorwListOptions = response.data;
          }
        });
      }
    },

    //
    searchWorkflowList(cb) {
      this.workflowListOptions = [];
      var that = this;
      let param = {};
      param.nodeName = null;
      // param.projectWorkflowId = this.formData.projectWorkflowId;
      param.workflowId = that.formData.beforeProjectWorkflowId;
      param.projectId = that.formData.projectId;
      // param.workflowId = this.formData.projectName;
      if (cb) {
        selectListWorkflow(param).then((response) => {
          if (response.success) {
            that.workflowListOptions = response.data;
            that.workflowListOptions.forEach(function (item, index) {
              if (that.formData.projectWorkflowDetailId == item.id) {
                that.formData.projectWorkflowDetailId = item.id;
                that.formData.workflowNodeName = item.nodeName;
                that.formData.projectWorkflowId = item.projectWorkflowId;
                // that.formData.beginTime = new Date().getTime();
              }
            });
          }
        });
      }
    },
  },

  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}, 
};
</script>

<style lang="scss" scoped>
.addDailog {
  // padding: 0 0 20px 0;
  display: flex;
  flex-wrap: wrap;
  .roleCodeList {
    width: 100% !important;
    height: auto;
    text-align: center !important;
  }
  .el-form-item {
    text-align: left;

    // display: flex;
    width: 50% !important;
    // float: left;
    min-height: 1rem;
    .el-select {
      width: 100% !important;
    }
    .el-input-number {
      width: 100% !important;
      text-align: left !important;
      .el-input__inner {
        text-align: left !important;
      }
    }
    .el-date-picker {
      width: 100% !important;
    }
  }
}
.importTemplateInput {
  position: relative;
  margin-right: 20px;

  // display: none;
  // width: 50px;
}
.outputlist_upload {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  opacity: 0;
}
.bar {
  overflow: hidden;
  padding: 10px;
  li {
    min-height: 50px;
    line-height: 50px;
    font-size: 15px;
    list-style: none;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    span {
      display: inline-block;
      width: 120px;
      text-align: right;
    }
    .childWidth {
      width: calc(100% - 150px);
    }
  }
  .width50 {
    width: 50%;
  }
  .width100 {
    width: 100%;
    p {
      width: calc(100% - 120px);
      float: right;
      span {
        display: inline;
        padding: 0 10px;
      }
    }
  }
  i {
    color: red;
  }
}
.workflowVersion {
  color: #ccc;
  font-size: 12px;
}
.el-table td,
.el-table th.is-leaf,
.el-table--border,
.el-table--group {
  // border-color: #ccc!important;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #ccc !important;
}
// .el-table thead,.el-table{ color: #ccc; }
.el-table thead.is-group th {
  background: #fff !important;
}
</style>
