<template>
  <div>
    <el-main>
      <el-row type="flex" class="title">
        <el-col justify="start">计划视图: - 计划任务一览</el-col>
      </el-row>

      <!-- 表格 -->
      <el-table
        border
        row-key="id"
        style="width: 100%"
        :data="tableData"
        v-loading="tableLoading"
      >
        <el-table-column label="业务进度表" prop="projectName" width="200">
          <template slot-scope="scope">
            <span
              >{{ scope.row.projectName }} -
              {{ scope.row.projectWorkflowName }}</span
            >
          </template>
        </el-table-column>

        <el-table-column label="流程节点" prop="nodeName" width="150">
        </el-table-column>

        <el-table-column label="任务" prop="name" width="150">
          <template slot-scope="scope">
            <span @dblclick="copyId(scope.row.id)">{{ scope.row.name }}</span>
          </template>
        </el-table-column>

        <el-table-column label="任务描述" prop="description" width="200">
        </el-table-column>

        <el-table-column label="任务负责人" prop="ownerId" width="150">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.ownerId"
              filterable
              placeholder="请选择任务负责人"
              @change="chooseInvestor($event, scope.row.id)"
              class="childWidth"
              :disabled="
                scope.row.pmId !== userId &&
                scope.row.createUser !== userId &&
                scope.row.dataAuthLimit * 1 !== 0
              "
              no-data-text="加载中···"
            >
              <el-option
                v-for="item in scope.row.projectWorkflowMembers"
                :key="item.uid"
                :label="item.real_name"
                :value="item.uid"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="计划开始日期" prop="createDate" width="155">
          <template slot-scope="scope">
            <span>{{ scope.row.planBeginTime | formatDateFilter }}</span>
          </template>
        </el-table-column>

        <el-table-column label="计划结束日期" prop="createDate" width="155">
          <template slot-scope="scope">
            <span>{{ scope.row.planEndTime | formatDateFilter }}</span>
          </template>
        </el-table-column>

        <el-table-column label="状态" prop="" width="120">
          <template slot-scope="scope">
            <span :class="'display-status-' + scope.row.displayStatus">{{
              scope.row.displayStatus | taskStatusLabelFilter
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="130">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              v-permission="['admin:task:edit']"
              @click="addTask(scope.row, 'edit')"
              >编辑
            </el-button>

            <el-popover trigger="hover" placement="top" :ref="scope.$index">
              <el-button
                type="primary"
                size="mini"
                v-permission="['admin:task:delete']"
                @click="deleteTask(scope.row)"
                >删除
              </el-button>

              <el-button
                type="primary"
                size="mini"
                @click="addTask(scope.row, 'detail')"
                >查看
              </el-button>

              <el-button
                type="primary"
                size="mini"
                v-permission="['admin:task:add']"
                @click="addTask(scope.row, 'add')"
                >添加任务
              </el-button>

              <el-button
                icon="el-icon-view"
                slot="reference"
                style="margin: 0 0 0 10px; font-size: 18px"
                size="small"
                type="text"
              >
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-info-class">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="this.pageInfo.pageNum"
          :page-sizes="[10, 20]"
          :page-size="this.pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="this.pageInfo.total"
        ></el-pagination>
      </div>
    </el-main>

    <addTask1
      v-if="add1TaskDalog"
      :add1TaskDalog="add1TaskDalog"
      :type="add1TaskType"
      :info="add1TaskDetail"
      :relationList="relationList"
      :workflowType="add1TaskWorkflowType"
      v-on:listenAdd1TaskClose="listenAdd1TaskClose"
    ></addTask1>
  </div>
</template>

<script>
import {
  editTask,
  deleteTask,
  getTaskManagementPagingList,
  findMyProjectWorkflowMembers,
} from "@/api/myMission";
import { formatDate } from "@/common/date";
import addTask1 from "@/components/myMission/addTask/addTask1";
import { projectAssignUserList } from "@/api/projectManage";
import { copyId } from "@/common/copyId";
import eventBus from "@/common/eventBus";

let that;
export default {
  name: "taskPlanViewTable",
  data() {
    return {
      userId: null,
      tableData: [],
      spanArr: [],
      formData: {},
      projectListOptions: [],
      firstGet: true,
      total: 0,
      pageCount: 1,
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      transferTaskDalogVisible: false, //转派
      tableLoading: false,
      nodeName: null,
      fileTitle: null,
      fileTitles: null,
      detailsId: null,
      taskInfo: null, //任务信息
      transferTaskType: null,
      operationWidth: 390,
      commonOperations: false,
      add1TaskDalog: false,
      add1TaskType: null,
      add1TaskDetail: {},
      add1TaskWorkflowType: null,
      relationList: null,
      meetingDetailsDialog: false,
      meetingInfoId: null,
      evaluateRecordTableDialog: false,
      evaluateRecordId: null,
      internalUseroptionsList: [],
      myProjectWorkflowMembers: [],
      taskStatusListOptions: [
        {
          value: 5,
          label: "进行中",
          color: "color:#F56C6C",
        },
        {
          value: 4,
          label: "待启动",
          color: "color:#409EFF",
        },
        {
          value: 6,
          label: "已完成",
          color: "color:#67C23A",
        },
      ],
    };
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    taskList: {
      type: Array,
    },
    pageInfo1: {
      type: Object,
    },
    pageCount1: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },
  components: {
    addTask1,
  },
  watch: {
    taskList: {
      handler(newVal, old) {
        let that = this;
        if (newVal) {
          that.tableData = newVal;
        }
      },
      immediate: true,
      deep: true,
    },
     pageInfo1: {
      handler(newVal, old) {
        let that = this;
        if (newVal) {
          that.pageInfo = newVal;
        }
      },
      immediate: true,
      deep: true,
    },
     pageCount: {
      handler(newVal, old) {
        let that = this;
        if (newVal) {
          that.pageCount = newVal;
        }
      },
      immediate: true,
      deep: true,
    },
    loading(newVal,old){
      this.tableLoading = newVal
    }
  },
  beforeCreate() {
    that = this;
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
    taskStatusLabelFilter(value) {
      let item = that.taskStatusListOptions.find((x) => x.value === value);
      return (item && item.label) || "未知";
    },
  },
  mounted() {
    this.userId = window.localStorage.getItem("userId");
    this.projectTeam(true);

    eventBus.$on("onTrackViewTableChange", () => {
      this.getTaskManagementPagingList();
    });
  },
  beforeDestroy() {
    eventBus.$off("onTrackViewTableChange");
  },
  methods: {
    copyId(id) {
      copyId(id);
      this.$message({
        type: "success",
        message: "任务ID: " + id + " 复制成功!",
      });
    },
    getTaskManagementPagingList() {
      this.tableLoading = true;

      let queryParams = {
        ownerId: this.info.ownerId,
        projectWorkflowIdList: this.info.projectWorkflowIdList,
        taskCreateBySelf: this.info.taskCreateBySelf,
        statusList: this.info.statusList,
        displayStatusList: this.info.displayStatusList,
        pageSize: this.pageInfo.pageSize,
        pageNum: this.pageInfo.pageNum,
      };

      getTaskManagementPagingList(queryParams)
        .then((res) => {
          if (res.success) {
            this.tableData = res.data;
            this.pageCount = res.pageCount;
            this.pageInfo.total = res.total;
            this.$emit("listenTaskListChange",{ type: "sure", list: this.tableData, pageInfo: this.pageInfo, pageCount: this.pageCount })
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },

    addTask(row, type) {
      console.log(row);
      this.relationList = [
        {
          type: 1,
          targetId: row.projectWorkflowDetailId,
        },
        {
          type: 2,
          targetId: row.projectWorkflowId,
        },
        {
          type: 3,
          targetId: row.projectId,
        },
      ];
      if (type === "edit" || type === "detail") {
        this.add1TaskDetail = row;
      }
      this.add1TaskWorkflowType = "workflow";
      this.add1TaskType = type;
      this.add1TaskDalog = true;
    },
    deleteTask(row) {
      let that = this;
      let taskInfo = { id: row.id };

      that
        .$confirm("是否删除任务[" + row.name + "]?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          console.log(taskInfo);
          deleteTask(taskInfo).then((res) => {
            if (res.success) {
              this.getTaskManagementPagingList();
              that.$message({ type: "success", message: "删除成功!" });
              eventBus.$emit("onPlanViewTableChange");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    projectTeam(cb) {
      if (cb) {
        projectAssignUserList(this.id).then((response) => {
          if (response.success) {
            this.internalUseroptionsList = response.data;
          }
        });
      }
    },
    chooseInvestor(ownerId, id) {
      console.log(ownerId);
      console.log(id);
      let info = {
        id: id,
        ownerId: ownerId,
      };
      editTask(info).then((response) => {
        if (response.success) {
          this.$message({ type: "success", message: "修改任务负责人成功!" });
          this.getTaskManagementPagingList();
        }
      });
    },
    //转派任务
    transferTask(type, row) {
      console.log(row);
      this.taskInfo = row;
      this.transferTaskType = type;
      this.transferTaskDalogVisible = true;
    },
    listenTransferTaskClose(params) {
      console.log(params);
      const { type, isClose } = params;
      this.transferTaskDalogVisible = !isClose;
      if (type === "sure") {
        this.getTaskManagementPagingList();
      }
    },
    listenAdd1TaskClose(params) {
      console.log(params);
      const { type, isClose, info } = params;
      this.add1TaskDalog = !isClose;
      if (type === "sure") {
        this.getTaskManagementPagingList();
        eventBus.$emit("onPlanViewTableChange");
      }
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getTaskManagementPagingList();
    },
    handleCurrentChange(val) {
      this.pageInfo.pageNum = val;
      if (val > this.pageCount) {
        val = this.pageCount;
      }
      this.getTaskManagementPagingList();
    },
    resetPageNum() {
      this.pageInfo.pageNum = 1;
    },
    //查找我参与项目的成员，如果我是PM则可以看到所有成员，否则只能看到自己
    findMyProjectWorkflowMembers(show, row) {
      if (!show) return;

      let param = {
        id: row.projectWorkflowId,
      };
      findMyProjectWorkflowMembers(param).then((res) => {
        if (res.success) {
          this.myProjectWorkflowMembers = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.projectFrom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.projectFrom .el-form-item {
  /* width: 230px; */
  flex-shrink: 0;
}

.editDialogs >>> .el-dialog__body {
  text-align: left;
}

.editDialogs ul {
  list-style: none;
  text-align: left;
  line-height: 30px;
}

.title {
  text-align: left;
  font-size: 1.5rem;
}

.taskList {
  margin-top: 10px;

  .projectTitle {
    margin: 5px 0;
  }
}

.fileList,
.evaluateList,
.meetingList {
  display: flex;
  align-items: center;

  &:last-of-type {
    margin-bottom: 3px;
  }

  &:hover {
    > i {
      display: inline-block;
    }
  }

  > i {
    display: none;
    margin-left: 3px;

    &:hover {
      cursor: pointer;
    }
  }
}

.otherButton {
  margin-left: 10px;
}

.display-status-4 {
  color: #409eff;
}
.display-status-5 {
  color: #f56c6c;
}
.display-status-6 {
  color: #67c23a;
}
</style>
